import { HttpClient } from "../../../../rest/utils/http";

export const UpdateCompanyMutation = async (data: any) => {
  return await HttpClient.patch(
    `/api/atab/member-company/member/company`,
    data
  );
};

export const UpdateCompanyDocumentMutation = async (data: any) => {
  return await HttpClient.patch(
    `/api/atab/member-company/member/documents`,
    data
  );
};
export const UpdateCompanyTradeLicenseMutation = async (data: any) => {
  return await HttpClient.patch(
    `/api/atab/member-company/member/license`,
    data
  );
};
export const UpdateCompanyBusinessLicenseMutation = async (data: any) => {
  return await HttpClient.patch(
    `/api/atab/member-company/member/business-license`,
    data
  );
};
export const UpdateCompanyOtherBusinessLicenseMutation = async (data: any) => {
  return await HttpClient.patch(
    `/api/atab/member-company/member/association`,
    data
  );
};
export const UpdateCompanyCivilAviationMutation = async (data: any) => {
  return await HttpClient.patch(
    `/api/atab/member-company/civil/aviation`,
    data
  );
};
export const UpdateCompanyOwnerMutation = async (data: any, id: number) => {
  return await HttpClient.patch(
    `/api/atab/member-company/member/owner/${id}`,
    data
  );
};
export const UpdateCompanyProposerMutation = async (data: any) => {
  return await HttpClient.put(`/api/atab/member-company/proposer`, data);
};
