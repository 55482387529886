import CardCompanyOProposer from "../card-components/CardCompanyProposer";
import AddCompanyProposerInfo from "../components/AddCompanyProposerInfo";
import { useCompanyProposer } from "../../../rest/useCompanyInfo";
import { useAuthContext } from "../../../Context/AuthContext/AuthContext";
import { Typography, Divider, Card } from "@mui/material";

const ProposerIndex = () => {
  const { user } = useAuthContext();
  const { companyProposer, isLoading: proposerLoading } = useCompanyProposer(
    user.user_member_id.toString()
  );

  return (
    <>
      <Card>
        <Typography variant="h3" sx={{ p: 2 }}>
          Company proposer
        </Typography>{" "}
        {/* <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h3" sx={{ p: 2 }}>
            Company proposer
          </Typography>

          <>
            {!user?.is_applied && companyProposer?.length ? (
              <Tooltip title="Update" sx={{ mr: 2 }}>
                <IconButton onClick={() => setToggle(!toggle)}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            ) : (
              ""
            )}
          </>
        </Box> */}
        <Divider light />
        {companyProposer.length > 0 ? (
          <CardCompanyOProposer
            data={companyProposer}
            loading={proposerLoading}
          />
        ) : (
          <AddCompanyProposerInfo />
        )}
      </Card>
    </>
  );
};

export default ProposerIndex;
