import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CreditScoreRoundedIcon from '@mui/icons-material/CreditScoreRounded';
import Grid from '@mui/material/Grid';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

const PaymentSuccess = () => {
  return (
    <Box
      sx={{
        pt: { xl: 15, md: 5, xs: 20 },
        backgroundColor: '#fafafa',
        height: { xl: '87vh', xs: '100vh' },
      }}
    >
      <Grid
        container
        spacing={2}
        alignItems='center'
        justifyContent='center'
        textAlign='center'
      >
        <Grid item>
          <Box>
            <CreditScoreRoundedIcon
              sx={{ fontSize: '250px', color: '#46d09d' }}
            />
            <Typography
              variant='h5'
              component='div'
              color={'#46d09d'}
              fontWeight={'bold'}
              fontSize={30}
            >
              Thank You!
            </Typography>
            <Typography variant='subtitle2' component='div'>
              Payment done successfully
            </Typography>
          </Box>
          <Box sx={{ pt: 3 }}>
            <Link to='/'>
              <Button variant='text'>Go back home</Button>
            </Link>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PaymentSuccess;
