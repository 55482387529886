import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Typography, IconButton, Button } from "@mui/material";
// import TextareaAutosize from "@mui/base/TextareaAutosize";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import CloseIcon from "@mui/icons-material/Close";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";

const style = {
  position: "absolute" as "absolute",
  top: { xl: "70%", md: "61%" },
  left: { xl: "91.3%", md: "88%" },
  right: "0",
  transform: "translate(-50%, -50%)",
  width: 320,
  height: 450,
  overflow: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 3,
};

const FilesUploadModal = ({
  open,
  handleClose,
  files,
  setFiles,
  handelSendMessage,
  setMessage,
  message,
}: any) => {
  const handelImageRemove = (id: number) => {
    const filterImage = files.filter(
      (image: string | Blob, index: number) => index !== id
    );
    setFiles(filterImage);
  };
  return (
    <div>
      {" "}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography variant="h5" gutterBottom pb={2}>
            Upload files
          </Typography>

          <Box>
            {/* <TextareaAutosize
              aria-label="minimum height"
              minRows={3}
              placeholder="Enter your message..."
              onChange={(e: any) => setMessage(e.target.value)}
              style={{
                width: "100%",
                paddingLeft: "5px",
                outline: "none",
                borderRadius: 5,
              }}
            /> */}
          </Box>
          <Box pt={1}>
            {files.length ? (
              <Box display={"flex"} flexWrap={"wrap"} gap={2}>
                {files.map((img: string | Blob, index: number) => {
                  return (
                    <Box position={"relative"} width={150}>
                      <img
                        src={URL.createObjectURL(img as Blob)}
                        alt=""
                        width={"100%"}
                        height={100}
                        style={{ objectFit: "contain" }}
                      />
                      <CloseIcon
                        onClick={() => handelImageRemove(index)}
                        sx={{
                          cursor: "pointer",
                          position: "absolute",
                          right: -8,
                          top: -6,
                          fontSize: 20,
                          color: "#5E60FF",
                          backgroundColor: "white",
                          borderRadius: "50%",
                        }}
                      />
                    </Box>
                  );
                })}
                <Box>
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="label"
                  >
                    <input
                      hidden
                      accept="image/*"
                      type="file"
                      onChange={(e: any) =>
                        setFiles([...files, e.target.files[0]])
                      }
                    />

                    <AddAPhotoIcon
                      sx={{ fontSize: "50px", color: "#5e60ff" }}
                    />
                  </IconButton>
                </Box>
              </Box>
            ) : (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="label"
                >
                  <input
                    hidden
                    accept="image/*"
                    type="file"
                    onChange={(e: any) =>
                      e.target.files[0] &&
                      setFiles([...files, e.target.files[0]])
                    }
                  />
                  <DriveFolderUploadIcon
                    sx={{ fontSize: "50px", color: "#5e60ff" }}
                  />
                </IconButton>
                <p>Add</p>
              </Box>
            )}
          </Box>

          {message || files.length ? (
            <Box textAlign={"center"} pt={4}>
              <Button
                variant="contained"
                size="large"
                onClick={() => {
                  handelSendMessage();
                  handleClose();
                }}
              >
                Send
              </Button>
            </Box>
          ) : (
            ""
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default FilesUploadModal;
