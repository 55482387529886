import { useState, useCallback } from "react";
import {
  IOwnershipChange,
  IOwnershipChangeResponse,
  ISingleOwnershipChange,
} from "./types";
import { showToast } from "../Toaster/Toaster";
import {
  fetchOwnershipChange,
  fetchSingleOwnershipChange,
  postOwnershipChange,
} from ".";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { API_ENDPOINTS } from "./utils/api-endpoints";

export type IChangeOwnerParams = {
  ownership_change_application_member_id: number;
  ownership_change_application_status?: string;
  ownership_change_application_from_date?: string;
  ownership_change_application_to_date?: string;
};

export function useChangeOwnershipQuery(params: IChangeOwnerParams) {
  const { data, isLoading, error } = useQuery<IOwnershipChangeResponse, Error>(
    [API_ENDPOINTS.OWNERSHIP_APPLICATION],
    () => fetchOwnershipChange(params),
    {
      refetchOnWindowFocus: false,
    }
  );
  return {
    companyOwnership: data?.data ?? ([] as IOwnershipChange[]),
    isLoading,
    error,
  };
}

export const useSingleOwnershipChangeQuery = (id: string) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<any>({});
  const [data, setData] = useState<ISingleOwnershipChange>(
    {} as ISingleOwnershipChange
  );

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await fetchSingleOwnershipChange(id);
      if (response.success) {
        setData(response.data!);
      }
    } catch (error: any) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [id]);

  return {
    loading,
    data,
    error,
    fetchData,
  };
};

export function useChangeOwnershipMutation() {
  const [formError, setFormError] = useState(null);
  const queryClient = useQueryClient();
  const { mutate, isLoading, isSuccess } = useMutation(postOwnershipChange, {
    onSuccess: (data) => {
      if (data?.success) {
        showToast(`Successfully changed ownership`, "success");
      }
    },
    onError: (error) => {
      const {
        response: { data },
      }: any = error ?? {};
      showToast(`${data.message}`, "error");
      setFormError(data?.message);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [API_ENDPOINTS.OWNERSHIP_APPLICATION],
      });
    },
  });

  return { mutate, isLoading, formError, setFormError, isSuccess };
}
