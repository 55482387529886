import React, { useState } from "react";
import {
  Typography,
  Divider,
  Card,
  Box,
  Tooltip,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useAuthContext } from "../../../Context/AuthContext/AuthContext";
import CardBusinessLicense from "../card-components/CardBusinessLicense";
import AddBusinessLicenseInfo from "../components/AddBusinessLicenseInfo";
import { useCompanyBusinessLicense } from "../../../rest/useCompanyInfo";
import CompanyBusinessLicense from "../../CompanyUpdateInfo/UpdateCompanyInformation/CompanyBusinessLicense";
const BusinessLicenseInfoIndex = () => {
  const { user } = useAuthContext();
  const { companyBusinessLicense } = useCompanyBusinessLicense();
  const [toggle, setToggle] = useState(false);
  return (
    <>
      <Card>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h3" sx={{ p: 2 }}>
            Business license information
          </Typography>
          {!user?.is_applied &&
          Object.keys(companyBusinessLicense).length > 0 ? (
            <Tooltip title="Update" sx={{ mr: 2 }}>
              <IconButton onClick={() => setToggle(!toggle)}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          ) : (
            ""
          )}
        </Box>
        <Divider light />

        {toggle ? (
          <CompanyBusinessLicense
            memberId={user.user_member_id}
            companyBusinessLicense={companyBusinessLicense}
          />
        ) : (
          <>
            {Object.keys(companyBusinessLicense).length > 0 ? (
              <CardBusinessLicense
                key={6}
                companyBusinessLicense={companyBusinessLicense}
              />
            ) : (
              <AddBusinessLicenseInfo />
            )}
          </>
        )}
      </Card>
    </>
  );
};

export default BusinessLicenseInfoIndex;
