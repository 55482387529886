import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

export const CustomTextFiled = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '&.fieldset': {
      backgroundColor: 'white',
      color: 'black',
    },
  },

  '.css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
    borderRadius: '7px',
  },
});
