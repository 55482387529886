import { useState } from "react";
import { Button, CssBaseline, TextField, Typography } from "@mui/material";
import { Container } from "@mui/system";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Link, useNavigate } from "react-router-dom";
import { muiStyles } from "../../Utils/Styles";
import WestIcon from "@mui/icons-material/West";
import axios from "axios";
import { url } from "../../Helpers/utils/Constant";
import { showToast } from "../../Toaster/Toaster";

const useStyles = muiStyles;

const ForgetPassword = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    if (email) {
      axios
        .post(`${url}/api/atab/common/send-email-otp`, {
          email,
          type: "forget_member",
        })
        .then(function (res) {
          if (res.data.success) {
            showToast(`We sent verification code to ${email}`, "success");
            setIsLoading(false);
            navigate(`/match-otp?email=${email}`);
          }
        })
        .catch(function (err) {
          showToast(`${err.response.data.message}`, "error");
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="login-page">
        <div className="login-wrapper">
          <div className="login-left-col">
            <video autoPlay muted loop height={"100%"}>
              <source
                src={
                  "https://m360ict.s3.ap-south-1.amazonaws.com/biha-files/logo/login-video.mp4"
                }
                type="video/mp4"
              />
            </video>
          </div>

          <div>
            <div className="login-right-col">
              <Container maxWidth="sm" component="main">
                <CssBaseline />
                <div className="right-top-div">
                  <img src="/logo.png" alt="" />
                </div>

                <div className="pt-3">
                  <Typography
                    variant="h4"
                    gutterBottom
                    sx={{
                      textAlign: "center",
                      fontWeight: "600",
                      color: "#0c4da2",
                      pb: 3,
                    }}
                  >
                    Forget password
                  </Typography>
                </div>
                <Box>
                  <Box component="form" onSubmit={handleSubmit}>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      type="email"
                      id="email"
                      label="Enter your email"
                      onChange={(e: any) => setEmail(e.target.value)}
                      variant="filled"
                      className={classes.inputText}
                      InputProps={{ classes }}
                    />

                    <Box>
                      {isLoading ? (
                        <Button
                          disabled
                          fullWidth
                          variant="contained"
                          disableElevation
                          sx={{
                            mt: 3,
                            mb: 2,

                            background: "#1113a2",
                          }}
                          className={classes.signInButton}
                        >
                          Loading...
                        </Button>
                      ) : (
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          disableElevation
                          sx={{
                            mt: 3,
                            mb: 2,

                            background: "#1113a2",
                          }}
                          className={classes.signInButton}
                        >
                          Forget password
                        </Button>
                      )}
                    </Box>

                    <Grid container>
                      <Grid item xs={12} sm={6} md={12}>
                        <Link to="/login">
                          <Typography
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              marginTop: "20px",
                            }}
                            className="text-main"
                          >
                            <WestIcon
                              sx={{ marginRight: "5px" }}
                              fontSize="small"
                            />{" "}
                            {"Go Back"}
                          </Typography>
                        </Link>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Container>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgetPassword;
