import { Box, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

const CommonCheckTable = ({
  serial,
  title,
  value,
}: {
  serial: number;
  title: string;
  value: any;
}) => {
  return (
    <>
      <tr>
        <td>{serial}</td>
        <td colSpan={7}>{title}</td>
        <td>
          <Box display={"flex"} alignItems={"center"} gap={0.5}>
            <Typography sx={{ border: "1px solid gray", my: 0.5 }}>
              NO
            </Typography>
            <Typography
              sx={{
                border: "1px solid gray",
                width: "25px",
                height: "24px",
              }}
            >
              {!value && <CheckIcon />}
            </Typography>
          </Box>
        </td>
        <td>
          <Box display={"flex"} alignItems={"center"} gap={0.5}>
            <Typography sx={{ border: "1px solid gray", my: 0.5 }}>
              YES
            </Typography>
            <Typography
              sx={{
                border: "1px solid gray",
                width: "25px",
                height: "24px",
              }}
            >
              {value ? <CheckIcon /> : ""}
            </Typography>
          </Box>
        </td>
      </tr>
    </>
  );
};

export default CommonCheckTable;
