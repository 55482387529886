import { toast } from "react-hot-toast";

export const showToast = (
  message: string,
  type: "success" | "error" | "loading" = "success",
  duration: number = 3000
): void => {
  switch (type) {
    case "success":
      toast.success(message, { duration });
      break;
    case "error":
      toast.error(message, { duration });
      break;
    case "loading":
      toast.loading(message, { duration });
      break;
    default:
      toast(message, { duration });
      break;
  }
};
