import {
  Box,
  Grid,
  FormControl,
  Select,
  MenuItem,
  Button,
  TextField,
  InputLabel,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { showToast } from "../../../Toaster/Toaster";
import { ICompanyDocument } from "../../../rest/types";
import { useMutation, useQueryClient } from "react-query";
import { API_ENDPOINTS } from "../../../rest/utils/api-endpoints";
import { UpdateCompanyDocumentMutation } from "../../new-member-applications/IndexFile/UpdateMutation/CompanyUpdateMutation";

interface IProps {
  memberId: number;
  companyDocument: ICompanyDocument;
}

const UpdateCompanyDocument = ({ companyDocument }: IProps) => {
  const { register, handleSubmit } = useForm();
  const [officeAgreement, setOfficeAgreement] = useState<string>(
    companyDocument.company_office_agreement
  );
  const [ownershipStatus, setOwnershipStatus] = useState(
    companyDocument.company_ownership_status
  );
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (data: any) => {
      return UpdateCompanyDocumentMutation(data);
    },
    onSuccess: () => {
      showToast("successfully updated", "success");
    },
    onError: (error: any) => {
      showToast(`${error.response.data.message}`, "error");
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [API_ENDPOINTS.COMPANY_COMPANY_DOCUMENT],
      });
    },
  });

  const onSubmit = async (data: any) => {
    const formData = new FormData();
    formData.append("company_ownership_status", ownershipStatus);
    formData.append("company_office_agreement", officeAgreement);
    Object.keys(data).forEach((key) => {
      if (data[key][0]) {
        formData.append(key, data[key][0]);
      }
    });
    mutation.mutate(formData);
  };

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} p={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Box>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel id="demo-simple-select-label">
                Ownership status
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                label="Ownership status"
                value={ownershipStatus}
                onChange={(e: any) => setOwnershipStatus(e.target.value)}
              >
                <MenuItem value={"Proprietorship"}>Proprietorship</MenuItem>
                <MenuItem value={"Partnership"}>Partnership</MenuItem>
                <MenuItem value={"Incorporator"}>Incorporator</MenuItem>
              </Select>
            </FormControl>
          </Box>
          {ownershipStatus === "Partnership" && (
            <Box mt={2}>
              <Box>
                <TextField
                  label="Photocopy of deed of partnership ( max size 5mb )"
                  id="outlined-basic"
                  variant="outlined"
                  type="file"
                  fullWidth
                  {...register("company_partnership_deed_file")}
                />
              </Box>
            </Box>
          )}
          {ownershipStatus === "Incorporator" && (
            <Box mt={2}>
              <TextField
                label="Upload incorporation certificate ( max size 5mb )"
                id="outlined-basic"
                variant="outlined"
                type="file"
                fullWidth
                {...register("company_incorporation_certificate")}
              />
            </Box>
          )}
          {ownershipStatus === "Incorporator" && (
            <Box mt={2}>
              <TextField
                id="outlined-basic"
                label="Memorandum document ( max size 5mb )"
                variant="outlined"
                type="file"
                fullWidth
                {...register("company_memorandum_file")}
              />
            </Box>
          )}
          {/* office agreement */}
          <Box mt={2}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel id="demo-simple-select-label">
                Office agreement
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                label=" Office agreement"
                value={officeAgreement}
                onChange={(e: any) => setOfficeAgreement(e.target.value)}
              >
                <MenuItem value={"Self premise"}>Self premise</MenuItem>
                <MenuItem value={"Rented premise"}>Rented premise</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box mt={2}>
            <label htmlFor="">
              03 ( Three months) latest bank statement ( max size 5mb )
            </label>
            <TextField
              id="outlined-basic"
              variant="outlined"
              type="file"
              fullWidth
              {...register("company_bank_statement")}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box>
            <TextField
              label="Staff list on office pad with seal and signature of owner ( max size 5mb )"
              id="outlined-basic"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              type="file"
              fullWidth
              {...register("company_staff_list_file")}
            />
          </Box>
          {officeAgreement && (
            <Box mt={2}>
              <TextField
                id="outlined-basic"
                label={
                  officeAgreement === "self premise"
                    ? "Deed of agreement of self premise office space ( max size 5mb )"
                    : "Deed of agreement of rented premise office space ( max size 5mb )"
                }
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                type="file"
                fullWidth
                {...register("company_agreement_file")}
              />
            </Box>
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          <Box>
            <TextField
              id="outlined-basic"
              label="One staff's GDS (Sabre/Galileo?Amadeus) certificate ( max size 5mb )"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              type="file"
              fullWidth
              {...register("company_one_staff_certificate")}
            />
          </Box>
          <Box mt={2}>
            <label htmlFor="">
              03 ( Three months) latest sales statement ( max size 5mb )
            </label>
            <TextField
              id="outlined-basic"
              variant="outlined"
              type="file"
              fullWidth
              {...register("company_sales_statement")}
            />
          </Box>
        </Grid>
      </Grid>
      {/* <Box mt={2}>
        <Typography
          variant="body1"
          fontWeight="bold"
          color="text.primary"
          display="inline"
          sx={{ pr: 1 }}
          gutterBottom
        >
          <a
            href={`${imgUrl}/${companyDocument.company_agreement_file}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Download company agreement file
          </a>
        </Typography>
        <Typography
          variant="body1"
          fontWeight="bold"
          color="text.primary"
          display="inline"
          sx={{ pr: 1 }}
          gutterBottom
        >
          <a
            href={`${imgUrl}/${companyDocument.company_staff_list_file}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Download company staff list file
          </a>
        </Typography>
        {companyDocument.company_partnership_deed_file && (
          <Typography
            variant="body1"
            fontWeight="bold"
            color="text.primary"
            display="inline"
            sx={{ pr: 1 }}
            gutterBottom
          >
            <a
              href={`${imgUrl}/${companyDocument.company_partnership_deed_file}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Download company partnership deed file
            </a>
          </Typography>
        )}
        {companyDocument.company_incorporation_certificate && (
          <Typography
            variant="body1"
            fontWeight="bold"
            color="text.primary"
            display="inline"
            sx={{ pr: 1 }}
            gutterBottom
          >
            <a
              href={`${imgUrl}/${companyDocument.company_incorporation_certificate}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Download company incorporation certificate
            </a>
          </Typography>
        )}
        {companyDocument.company_memorandum_file && (
          <Typography
            variant="body1"
            fontWeight="bold"
            color="text.primary"
            display="inline"
            sx={{ pr: 1 }}
            gutterBottom
          >
            <a
              href={`${imgUrl}/${companyDocument.company_memorandum_file}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Download company memorandum file
            </a>
          </Typography>
        )}
        {companyDocument.company_memorandum_file && (
          <Typography
            variant="body1"
            fontWeight="bold"
            color="text.primary"
            display="inline"
            sx={{ pr: 1 }}
            gutterBottom
          >
            <a
              href={`${imgUrl}/${companyDocument.company_one_staff_certificate}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Download company staff certificate
            </a>
          </Typography>
        )}
      </Box> */}
      <Box sx={{ pt: 5, textAlign: "end", mb: 2 }}>
        {mutation.isLoading ? (
          <Button
            disabled
            variant="contained"
            disableElevation
            className="add-remove-button"
          >
            Loading..
          </Button>
        ) : (
          <Button
            type="submit"
            variant="contained"
            disableElevation
            className="add-remove-button"
          >
            Save
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default UpdateCompanyDocument;
