import { CardContent, Grid, Typography } from "@mui/material";
import PageLoader from "../../../Components/Spinner/PageLoader";
import moment from "moment";
import { ICompanyLicense } from "../../../rest/types";
import { useState } from "react";
import ModalsWrapper from "../../../Components/Modals/ModalsWrapper";
import PdfAndImageView from "../../../Components/pdfAndImageView/PdfAndImageView";
import { CommonTypoGraphy } from "../../../Components/pdfAndImageView/CommonTypoGraphy";

type IProps = {
  data: ICompanyLicense;
  loading: boolean;
};

const CardCompanyTradeLicense = ({ data, loading }: IProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [imageFile, setImageFile] = useState<string | null>("");
  return (
    <>
      {!loading ? (
        <CardContent>
          <Grid
            container
            pt={2}
            spacing={{ xs: 2, md: 2 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            <Grid item xs={2} sm={4} md={4}>
              <Typography
                variant="body1"
                fontWeight="bold"
                color="text.primary"
                display="inline"
                sx={{ pr: 1 }}
                gutterBottom
              >
                Trade license:
              </Typography>
              {data.company_trade_license}
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <CommonTypoGraphy
                name="View trade license"
                onClick={() => {
                  setOpen(true);
                  setImageFile(data.company_trade_license_file);
                }}
              />
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <Typography
                variant="body1"
                fontWeight="bold"
                color="text.primary"
                display="inline"
                sx={{ pr: 1 }}
                gutterBottom
              >
                Trade license expiry date:
              </Typography>
              {moment(data.company_trade_license_expiry_date).format(
                "Do-MM-YYYY"
              )}
            </Grid>
            {data.company_tin_number && (
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  display="inline"
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  TIN number:
                </Typography>
                {data.company_tin_number}
              </Grid>
            )}
            {data.company_tin_file && (
              <Grid item xs={2} sm={4} md={4}>
                <CommonTypoGraphy
                  name="View TIN file"
                  onClick={() => {
                    setOpen(true);
                    setImageFile(data.company_tin_file);
                  }}
                />
              </Grid>
            )}
            {data.company_bin_number && (
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  display="inline"
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  BIN number:
                </Typography>
                {data.company_bin_number}
              </Grid>
            )}
            {data.company_bin_file && (
              <Grid item xs={2} sm={4} md={4}>
                {" "}
                <CommonTypoGraphy
                  name="View BIN file"
                  onClick={() => {
                    setOpen(true);
                    setImageFile(data.company_bin_file);
                  }}
                />
              </Grid>
            )}
          </Grid>
        </CardContent>
      ) : (
        <PageLoader />
      )}
      <>
        <ModalsWrapper
          modalData={{ title: "View document" }}
          setShowModal={setOpen}
          showModal={open}
        >
          <PdfAndImageView file={imageFile} />
        </ModalsWrapper>
      </>
    </>
  );
};

export default CardCompanyTradeLicense;
