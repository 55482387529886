import { useQuery } from "react-query";
import { API_ENDPOINTS } from "../../../rest/utils/api-endpoints";
import { IDashboardDataResponse, IMemberInfo } from "../info/CompanyInfoTypes";
import {
  IEvent,
  IEventResponse,
  IMeeting,
  IMeetingResponse,
  INotice,
  INoticeResponse,
  IService,
  IServiceResponse,
} from "../../../Types/DashvoardHomeType";
import {
  fetchDashboardData,
  fetchDashboardEvents,
  fetchDashboardMeeting,
  fetchDashboardNotice,
  fetchDashboardService,
} from "./dashboardApiFunction";

// get data function

// query
export function useDashboardQuery(id: number) {
  const { data, isLoading, error } = useQuery<IDashboardDataResponse, Error>(
    [API_ENDPOINTS.DASHBOARD_DATA, { id }],
    () => fetchDashboardData(id),
    {
      refetchOnWindowFocus: false,
    }
  );
  return {
    dashboardData: data?.data ?? ({} as IMemberInfo),
    isLoading,
    error,
  };
}
export function useDashboardServiceQuery(id: number) {
  const { data, isLoading, error } = useQuery<IServiceResponse, Error>(
    [API_ENDPOINTS.DASHBOARD_SERVICE, { id }],
    () => fetchDashboardService(),
    {
      refetchOnWindowFocus: false,
    }
  );
  return {
    allService: data?.data ?? ([] as IService[]),
    isLoading,
    error,
  };
}
export function useDashboardMeetingQuery() {
  const { data, isLoading, error } = useQuery<IMeetingResponse, Error>(
    [API_ENDPOINTS.DASHBOARD_MEETING],
    () => fetchDashboardMeeting(),
    {
      refetchOnWindowFocus: false,
    }
  );
  return {
    allMeeting: data?.data ?? ([] as IMeeting[]),
    isLoading,
    error,
  };
}
export function useDashboardEventQuery() {
  const { data, isLoading, error } = useQuery<IEventResponse, Error>(
    [API_ENDPOINTS.DASHBOARD_EVENTS],
    () => fetchDashboardEvents(),
    {
      refetchOnWindowFocus: false,
    }
  );
  return {
    allEvent: data?.data ?? ([] as IEvent[]),
    isLoading,
    error,
  };
}
export function useDashboardNoticeQuery() {
  const { data, isLoading, error } = useQuery<INoticeResponse, Error>(
    [API_ENDPOINTS.DASHBOARD_NOTICE],
    () => fetchDashboardNotice(),
    {
      refetchOnWindowFocus: false,
    }
  );
  return {
    allNotice: data?.data ?? ([] as INotice[]),
    isLoading,
    error,
  };
}
