import { Box, Typography, Grid, FormControlLabel } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { ICompanyBusinessLicense } from "../../../rest/types";
import ModalsWrapper from "../../../Components/Modals/ModalsWrapper";
import PdfAndImageView from "../../../Components/pdfAndImageView/PdfAndImageView";
import { useState } from "react";
import { CommonTypoGraphy } from "../../../Components/pdfAndImageView/CommonTypoGraphy";

type IProps = {
  companyBusinessLicense: ICompanyBusinessLicense;
};

const CardBusinessLicense = ({ companyBusinessLicense }: IProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [imageFile, setImageFile] = useState<string | null>("");
  return (
    <>
      {" "}
      <Box p={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom>
              Other business license information
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
                textAlign: "center",
              }}
            >
              <Box>
                <FormControlLabel
                  checked={
                    companyBusinessLicense.hajj_license !== 0 ? true : false
                  }
                  disabled
                  control={<Checkbox />}
                  label="Hajj"
                />
              </Box>
              <Box>
                <FormControlLabel
                  checked={
                    companyBusinessLicense.umrah_license !== 0 ? true : false
                  }
                  disabled
                  control={<Checkbox />}
                  label="Umrah"
                />
              </Box>
              <Box>
                <FormControlLabel
                  checked={
                    companyBusinessLicense.recruting_license !== 0
                      ? true
                      : false
                  }
                  disabled
                  control={<Checkbox />}
                  label="Recruiting"
                />
              </Box>
            </Box>

            <Box>
              {companyBusinessLicense.hajj_license ? (
                <Grid item xs={6} sm={6} md={12}>
                  <CommonTypoGraphy
                    name="View hajj license"
                    onClick={() => {
                      setOpen(true);
                      setImageFile(companyBusinessLicense.hajj_license_file);
                    }}
                  />
                </Grid>
              ) : null}
              {companyBusinessLicense.umrah_license ? (
                <Grid item xs={6} sm={6} md={12}>
                  <CommonTypoGraphy
                    name="View Umrah license"
                    onClick={() => {
                      setOpen(true);
                      setImageFile(companyBusinessLicense.umrah_license_file);
                    }}
                  />
                </Grid>
              ) : null}
              {companyBusinessLicense.recruting_license ? (
                <Grid item xs={6} sm={6} md={12}>
                  {" "}
                  <CommonTypoGraphy
                    name="View Recruiting license"
                    onClick={() => {
                      setOpen(true);
                      setImageFile(
                        companyBusinessLicense.recruting_license_file
                      );
                    }}
                  />
                </Grid>
              ) : null}
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom>
              Involved in tourism activities information
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: 15,
                pl: { xs: 7, md: 0 },
                // justifyContent: 'space-evenly',
                textAlign: "center",
                alignItems: "center",
              }}
            >
              <Box>
                <FormControlLabel
                  checked={companyBusinessLicense.inbound !== 0 ? true : false}
                  disabled
                  control={<Checkbox />}
                  label=" Inbound"
                />
              </Box>
              <Box>
                <FormControlLabel
                  checked={companyBusinessLicense.outbound !== 0 ? true : false}
                  disabled
                  control={<Checkbox />}
                  label="Outbound"
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <>
        <ModalsWrapper
          modalData={{ title: "View document" }}
          setShowModal={setOpen}
          showModal={open}
        >
          <PdfAndImageView file={imageFile} />
        </ModalsWrapper>
      </>
    </>
  );
};

export default CardBusinessLicense;
