import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Box, Grid, TextField, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { useAuthContext } from "../../Context/AuthContext/AuthContext";
import { useAddressChangeMutation } from "../../rest/useAddressChange";
import AddressSelect from "./AddressSelect";
import { showToast } from "../../Toaster/Toaster";

type IProps = {
  handelClose: Dispatch<SetStateAction<boolean>>;
  setReload: Dispatch<SetStateAction<boolean>>;
};

const CreateAddressChangeApplication = ({ handelClose, setReload }: IProps) => {
  const { user } = useAuthContext();
  const { register, handleSubmit } = useForm();
  const [areaID, setAreaID] = useState<string>("");
  const { createData, data, loading } = useAddressChangeMutation();

  useEffect(() => {
    if (data.success) {
      handelClose(false);
      setReload((prev) => !prev);
    }
  }, [data.success, handelClose, setReload]);

  const onSubmit = async (data: any) => {
    let total: number = 0;
    Object.keys(data).forEach((item) => {
      if (typeof data[item] === "object") {
        total += data[item][0].size / (1024 * 1024);
      }
    });

    if (Math.ceil(total) > 5) {
      showToast(`File size more than 5 mb`, "error");
      return;
    }

    const formData = new FormData();
    Object.keys(data).forEach((item) => {
      if (typeof data[item] === "object") {
        formData.append(item, data[item][0]);
      } else {
        formData.append(item, data[item]);
      }
    });
    formData.append(
      "address_change_application_member_id",
      user.user_member_id?.toString()
    );
    formData.append("address_change_application_area_id", areaID);
    createData(formData);
  };

  return (
    <>
      <Box component="form" onSubmit={handleSubmit(onSubmit)} p={2}>
        <Grid container spacing={2} mb={2}>
          <Grid xs={12} md={4} lg={4} item>
            <TextField
              fullWidth
              label="Email"
              required
              id="outlined-required"
              type="email"
              {...register("address_change_application_email")}
            />
          </Grid>
          <Grid xs={12} md={4} lg={4} item>
            <TextField
              fullWidth
              label="Phone number"
              id="outlined-required"
              type="number"
              {...register("address_change_application_phone")}
            />
          </Grid>
          <Grid xs={12} md={4} lg={4} item>
            <TextField
              fullWidth
              label="Cellphone"
              id="outlined-required"
              type="number"
              {...register("address_change_application_cellphone")}
            />
          </Grid>
          <Grid xs={12} md={4} lg={4} item>
            <TextField
              fullWidth
              label="Address "
              id="outlined-required"
              type="text"
              {...register("address_change_application_addressline")}
            />
          </Grid>

          <Grid xs={12} md={4} lg={4} item>
            <TextField
              fullWidth
              label="Postal code"
              required
              id="outlined-required"
              type="number"
              {...register("address_change_application_postal_code")}
            />
          </Grid>
          <Grid xs={12} md={4} lg={4} item>
            <TextField
              fullWidth
              label="Police station"
              required
              id="outlined-required"
              type="text"
              {...register("address_change_application_police_station")}
            />
          </Grid>

          <Grid xs={12} md={4} lg={4} item>
            <TextField
              fullWidth
              label="Civil aviation file ( max size 5mb )"
              required
              id="outlined-required"
              type="file"
              InputLabelProps={{
                shrink: true,
              }}
              {...register("address_change_application_civil_aviation")}
            />
          </Grid>

          <Grid xs={12} md={4} lg={4} item>
            <TextField
              fullWidth
              label="Trade license file ( max size 5mb )"
              required
              id="outlined-required"
              type="file"
              InputLabelProps={{
                shrink: true,
              }}
              {...register("address_change_application_trade_license")}
            />
          </Grid>
          <Grid xs={12} md={4} lg={4} item>
            <TextField
              fullWidth
              label="Forwarding letter ( max size 5mb )"
              required
              id="outlined-required"
              type="file"
              InputLabelProps={{
                shrink: true,
              }}
              {...register("address_change_application_form")}
            />
          </Grid>
          <AddressSelect setAreaID={setAreaID} />
        </Grid>
        <Box textAlign={"end"} mt={5}>
          {!loading ? (
            <Button
              type="submit"
              // fullWidth
              variant="contained"
              disableElevation
            >
              Submit
            </Button>
          ) : (
            <LoadingButton
              loading
              loadingIndicator="Loading..."
              variant="contained"
              // fullWidth
              disabled
            >
              <span>Loading...</span>
            </LoadingButton>
          )}
        </Box>
      </Box>
    </>
  );
};

export default CreateAddressChangeApplication;
