import { useState } from "react";
import { Box, Grid, FormControlLabel, Button, TextField } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { useForm } from "react-hook-form";
import { IAssociationMember } from "../../../Types/UpdateInfotypes/UpdateCompanyInfoStateTypes";
import { showToast } from "../../../Toaster/Toaster";
import { ICompanyAssociation } from "../../../rest/types";
import { useMutation, useQueryClient } from "react-query";
import { UpdateCompanyOtherBusinessLicenseMutation } from "../../new-member-applications/IndexFile/UpdateMutation/CompanyUpdateMutation";
import { API_ENDPOINTS } from "../../../rest/utils/api-endpoints";

interface IProps {
  companyAssociation: ICompanyAssociation;
}

const UpdateCompanyOtherBusinessLicense = ({ companyAssociation }: IProps) => {
  const { register, handleSubmit } = useForm();
  const queryClient = useQueryClient();
  const [associationMember, setAssociationMember] =
    useState<IAssociationMember>({
      baira_membership: companyAssociation.baira_membership,
      haab_membership: companyAssociation.haab_membership,
      iata_membership: companyAssociation.iata_membership,
      toab_membership: companyAssociation.toab_membership,
      tdab_membership: companyAssociation.tdab_membership,
    });

  const mutation = useMutation({
    mutationFn: (data: any) => {
      return UpdateCompanyOtherBusinessLicenseMutation(data);
    },
    onSuccess: () => {
      showToast("successfully updated", "success");
    },
    onError: (error: any) => {
      showToast(`${error.response.data.message}`, "error");
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [API_ENDPOINTS.COMPANY_ASSOCIATION],
      });
    },
  });

  const onSubmit = async (inputsData: any) => {
    inputsData = { ...inputsData, ...associationMember };
    const formData = new FormData();
    Object.keys(inputsData).forEach((key) => {
      if (typeof inputsData[key] === "object") {
        if (inputsData[key][0]) {
          formData.append(key, inputsData[key][0]);
        }
      } else {
        formData.append(key, inputsData[key]);
      }
    });
    mutation.mutate(formData);
  };

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} p={2}>
      <Grid container spacing={2} p={4}>
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
              textAlign: "center",
            }}
          >
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={
                      associationMember.baira_membership === 1 ? true : false
                    }
                  />
                }
                label="BAIRA"
                onClick={(e: any) => {
                  if (e.target.checked) {
                    setAssociationMember({
                      ...associationMember,
                      baira_membership: 1,
                    });
                  } else {
                    setAssociationMember({
                      ...associationMember,
                      baira_membership: 0,
                    });
                  }
                }}
              />
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={
                      associationMember.haab_membership === 1 ? true : false
                    }
                  />
                }
                label="HAAB"
                onClick={(e: any) => {
                  if (e.target.checked) {
                    setAssociationMember({
                      ...associationMember,
                      haab_membership: 1,
                    });
                  } else {
                    setAssociationMember({
                      ...associationMember,
                      haab_membership: 0,
                    });
                  }
                }}
              />
            </Box>
          </Box>
          <Box>
            {associationMember.baira_membership ? (
              <Box>
                <label htmlFor="">
                  Upload association BAIRA document ( max size 5mb )
                  <span style={{ color: "red" }}>*</span>
                </label>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  type="file"
                  fullWidth
                  sx={{ pb: 2 }}
                  {...register("baira_membership_file")}
                />
              </Box>
            ) : (
              ""
            )}
            {associationMember.haab_membership ? (
              <Box>
                <label htmlFor="">
                  Upload association HAAB document ( max size 5mb )
                  <span style={{ color: "red" }}>*</span>
                </label>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  type="file"
                  fullWidth
                  sx={{ pb: 2 }}
                  {...register("haab_membership_file")}
                />
              </Box>
            ) : (
              ""
            )}
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
              textAlign: "center",
            }}
          >
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={
                      associationMember.iata_membership === 1 ? true : false
                    }
                  />
                }
                label="IATA"
                onClick={(e: any) => {
                  if (e.target.checked) {
                    setAssociationMember({
                      ...associationMember,
                      iata_membership: 1,
                    });
                  } else {
                    setAssociationMember({
                      ...associationMember,
                      iata_membership: 0,
                    });
                  }
                }}
              />
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={
                      associationMember.toab_membership === 1 ? true : false
                    }
                  />
                }
                label="TOAB"
                onClick={(e: any) => {
                  if (e.target.checked) {
                    setAssociationMember({
                      ...associationMember,
                      toab_membership: 1,
                    });
                  } else {
                    setAssociationMember({
                      ...associationMember,
                      toab_membership: 0,
                    });
                  }
                }}
              />
            </Box>
          </Box>
          <Box>
            {associationMember.iata_membership ? (
              <Box>
                <label htmlFor="">
                  Upload association IATA document ( max size 5mb )
                  <span style={{ color: "red" }}>*</span>
                </label>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  type="file"
                  fullWidth
                  sx={{ pb: 2 }}
                  {...register("iata_membership_file")}
                />
              </Box>
            ) : (
              ""
            )}
            {associationMember.toab_membership ? (
              <Box>
                <label htmlFor="">
                  Upload association TOAB document ( max size 5mb )
                  <span style={{ color: "red" }}>*</span>
                </label>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  type="file"
                  fullWidth
                  sx={{ pb: 2 }}
                  {...register("toab_membership_file")}
                />
              </Box>
            ) : (
              ""
            )}
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box sx={{ pl: { xs: 12, md: 0 } }}>
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={
                    associationMember.tdab_membership === 1 ? true : false
                  }
                />
              }
              label="TDAB"
              onClick={(e: any) => {
                if (e.target.checked) {
                  setAssociationMember({
                    ...associationMember,
                    tdab_membership: 1,
                  });
                } else {
                  setAssociationMember({
                    ...associationMember,
                    tdab_membership: 0,
                  });
                }
              }}
            />
          </Box>
          <Box>
            {associationMember.tdab_membership ? (
              <Box>
                <label htmlFor="">
                  Upload association TDAB document ( max size 5mb )
                  <span style={{ color: "red" }}>*</span>
                </label>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  type="file"
                  fullWidth
                  sx={{ pb: 2 }}
                  {...register("tdab_membership_file")}
                />
              </Box>
            ) : (
              ""
            )}
          </Box>
        </Grid>
      </Grid>

      {/* <Box>
        {companyAssociation.baira_membership ? (
          <Grid item xs={6} sm={6} md={12}>
            <Typography
              variant="body1"
              fontWeight="bold"
              color="text.primary"
              display="inline"
              sx={{ pr: 1 }}
              gutterBottom
            >
              <a
                href={`${imgUrl}/${companyAssociation.baira_membership_file}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Download baira membership file
              </a>
            </Typography>
          </Grid>
        ) : null}
        {companyAssociation.haab_membership ? (
          <Grid item xs={6} sm={6} md={12}>
            <Typography
              variant="body1"
              fontWeight="bold"
              color="text.primary"
              display="inline"
              sx={{ pr: 1 }}
              gutterBottom
            >
              <a
                href={`${imgUrl}/${companyAssociation.haab_membership_file}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Download haab membership file
              </a>
            </Typography>
          </Grid>
        ) : null}
        {companyAssociation.iata_membership ? (
          <Grid item xs={6} sm={6} md={12}>
            <Typography
              variant="body1"
              fontWeight="bold"
              color="text.primary"
              display="inline"
              sx={{ pr: 1 }}
              gutterBottom
            >
              <a
                href={`${imgUrl}/${companyAssociation.iata_membership_file}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Download iata membership file
              </a>
            </Typography>
          </Grid>
        ) : null}
        {companyAssociation.toab_membership ? (
          <Grid item xs={6} sm={6} md={12}>
            <Typography
              variant="body1"
              fontWeight="bold"
              color="text.primary"
              display="inline"
              sx={{ pr: 1 }}
              gutterBottom
            >
              <a
                href={`${imgUrl}/${companyAssociation.toab_membership_file}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Download toab membership file
              </a>
            </Typography>
          </Grid>
        ) : null}
        {companyAssociation.tdab_membership ? (
          <Grid item xs={6} sm={6} md={12}>
            <Typography
              variant="body1"
              fontWeight="bold"
              color="text.primary"
              display="inline"
              sx={{ pr: 1 }}
              gutterBottom
            >
              <a
                href={`${imgUrl}/${companyAssociation.tdab_membership_file}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Download tdab membership file
              </a>
            </Typography>
          </Grid>
        ) : null}
      </Box> */}
      <Box sx={{ pt: 5, textAlign: "end", mb: 2 }}>
        {mutation.isLoading ? (
          <Button
            disabled
            variant="contained"
            disableElevation
            className="add-remove-button"
          >
            loading..
          </Button>
        ) : (
          <Button
            type="submit"
            variant="contained"
            disableElevation
            className="add-remove-button"
          >
            Save
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default UpdateCompanyOtherBusinessLicense;
