import { Box, Grid, FormControlLabel } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { ICompanyAssociation } from "../../../rest/types";
import { useState } from "react";
import ModalsWrapper from "../../../Components/Modals/ModalsWrapper";
import PdfAndImageView from "../../../Components/pdfAndImageView/PdfAndImageView";
import { CommonTypoGraphy } from "../../../Components/pdfAndImageView/CommonTypoGraphy";

type IProps = {
  companyAssociation: ICompanyAssociation;
};

const CardCompanyAssociation = ({ companyAssociation }: IProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [imageFile, setImageFile] = useState<string | null>("");
  return (
    <>
      <Box p={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
                textAlign: "center",
              }}
            >
              <Box>
                <FormControlLabel
                  checked={
                    companyAssociation.baira_membership !== 0 ? true : false
                  }
                  disabled
                  control={<Checkbox />}
                  label="BAIRA"
                />
              </Box>

              <Box>
                <FormControlLabel
                  checked={
                    companyAssociation.haab_membership !== 0 ? true : false
                  }
                  disabled
                  control={<Checkbox />}
                  label="HAAB"
                />
              </Box>
              <Box>
                <FormControlLabel
                  checked={
                    companyAssociation.iata_membership !== 0 ? true : false
                  }
                  disabled
                  control={<Checkbox />}
                  label="IATA"
                />
              </Box>
              <Box>
                <FormControlLabel
                  checked={
                    companyAssociation.toab_membership !== 0 ? true : false
                  }
                  disabled
                  control={<Checkbox />}
                  label="TOAB"
                />
              </Box>
              <Box>
                <FormControlLabel
                  checked={
                    companyAssociation.tdab_membership !== 0 ? true : false
                  }
                  disabled
                  control={<Checkbox />}
                  label="TDAB"
                />
              </Box>
            </Box>

            <Box>
              {companyAssociation.baira_membership ? (
                <Grid item xs={6} sm={6} md={12}>
                  {" "}
                  <CommonTypoGraphy
                    name="View baira membership"
                    onClick={() => {
                      setOpen(true);
                      setImageFile(companyAssociation?.baira_membership_file);
                    }}
                  />
                </Grid>
              ) : null}
              {companyAssociation.haab_membership ? (
                <Grid item xs={6} sm={6} md={12}>
                  {" "}
                  <CommonTypoGraphy
                    name="View haab membership"
                    onClick={() => {
                      setOpen(true);
                      setImageFile(companyAssociation?.haab_membership_file);
                    }}
                  />
                </Grid>
              ) : null}
              {companyAssociation.iata_membership ? (
                <Grid item xs={6} sm={6} md={12}>
                  <CommonTypoGraphy
                    name="View iata membership"
                    onClick={() => {
                      setOpen(true);
                      setImageFile(companyAssociation?.iata_membership_file);
                    }}
                  />
                </Grid>
              ) : null}
              {companyAssociation.toab_membership ? (
                <Grid item xs={6} sm={6} md={12}>
                  {" "}
                  <CommonTypoGraphy
                    name="View toab membership"
                    onClick={() => {
                      setOpen(true);
                      setImageFile(companyAssociation?.toab_membership_file);
                    }}
                  />
                </Grid>
              ) : null}
              {companyAssociation.tdab_membership ? (
                <Grid item xs={6} sm={6} md={12}>
                  <CommonTypoGraphy
                    name="View tdab membership"
                    onClick={() => {
                      setOpen(true);
                      setImageFile(companyAssociation?.tdab_membership_file);
                    }}
                  />
                </Grid>
              ) : null}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <>
        <ModalsWrapper
          modalData={{ title: "View document" }}
          setShowModal={setOpen}
          showModal={open}
        >
          <PdfAndImageView file={imageFile} />
        </ModalsWrapper>
      </>
    </>
  );
};

export default CardCompanyAssociation;
