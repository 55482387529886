import { IauthContextState } from "../../Context/AuthContext/AuthTyps";
import {
  AUTH_USER_FAILED,
  AUTH_USER_SUCCESS,
  CLEAR_NOTIFICATION,
  FETCH_NOTIFICATION,
  NEW_NOTIFICATION,
  SET_MESSAGE,
  SET_NEW_MESSAGE,
  UPDATE_AUTH_USER_INFO,
} from "../../Helpers/utils/Constant";

const reducer = (state: IauthContextState, action: any) => {
  switch (action.type) {
    case AUTH_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
      };

    case UPDATE_AUTH_USER_INFO:
      const updateInfo = { ...state.user, ...action.payload };
      return { ...state, user: updateInfo };
    case AUTH_USER_FAILED:
      return { ...state, user: {} };
    case FETCH_NOTIFICATION:
      return {
        ...state,
        notification: {
          notification: action.payload.notification,
          count: action.payload.count,
        },
      };
    case NEW_NOTIFICATION:
      return {
        ...state,
        notification: {
          notification: [action.payload, ...state.notification.notification],
          count: state.notification.count + 1,
        },
      };
    case CLEAR_NOTIFICATION:
      return {
        ...state,
        notification: {
          notification: [],
          count: 0,
        },
      };
    case SET_MESSAGE:
      return {
        ...state,
        messages: action.payload,
      };

    case SET_NEW_MESSAGE:
      return {
        ...state,
        messages: [...state.messages, action.payload],
      };

    default:
      throw new Error(`No matching action type - ${action.type}`);
  }
};

export default reducer;
