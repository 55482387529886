import { Grid, Button, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import { useForm } from "react-hook-form";
import { useAuthContext } from "../../../Context/AuthContext/AuthContext";
import { useCompanyCivilAviationUpdate } from "../../../rest/useCompanyInfo";

const AddCivilAviationInfo = () => {
  const { user } = useAuthContext();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { isLoading, mutate } = useCompanyCivilAviationUpdate();

  const onSubmit = async (inputsData: any) => {
    const formData = new FormData();
    Object.keys(inputsData).forEach((key) => {
      if (typeof inputsData[key] === "object") {
        formData.append(key, inputsData[key][0]);
      } else {
        formData.append(key, inputsData[key]);
      }
    });
    mutate({ input: formData });
  };

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} p={2} noValidate>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={4}>
          <Box>
            <label htmlFor="">
              Civil aviation tourism certificate number{" "}
              <span style={{ color: "red" }}>*</span>
            </label>
            <TextField
              id="outlined-basic"
              required
              variant="outlined"
              placeholder="Enter civil aviation tourism certificate number "
              fullWidth
              {...register("certificate_number", {
                required: "Tourism certificate number is required",
              })}
              error={
                errors.certificate_number && Boolean(errors.certificate_number)
              }
              helperText={
                Boolean(errors?.certificate_number?.message)
                  ? `${errors?.certificate_number?.message}`
                  : ""
              }
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <label htmlFor="">
              Upload civil aviation tourism certificate ( max size 5mb )
              <span style={{ color: "red" }}>*</span>
            </label>
            <TextField
              id="outlined-basic"
              required
              variant="outlined"
              type="file"
              fullWidth
              {...register("certificate_file", {
                required: "Tourism certificate document is required",
              })}
              error={
                errors.certificate_file && Boolean(errors.certificate_file)
              }
              helperText={
                Boolean(errors?.certificate_file?.message)
                  ? `${errors?.certificate_file?.message}`
                  : ""
              }
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Box>
            <label htmlFor="">
              Civil aviation tourism issue date
              <span style={{ color: "red" }}>*</span>
            </label>
            <TextField
              id="date"
              required
              type="date"
              {...register("certificate_issue_date", {
                required: "Civil aviation tourism issue date is required",
              })}
              error={
                errors.certificate_issue_date &&
                Boolean(errors.certificate_issue_date)
              }
              helperText={
                Boolean(errors?.certificate_issue_date?.message)
                  ? `${errors?.certificate_issue_date?.message}`
                  : ""
              }
              // onChange={(e) =>
              //   setDate({
              //     ...date,
              //     certificate_issue_date: e.target.value,
              //   })
              // }
              sx={{ width: "100%" }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          {" "}
          <Box>
            <label htmlFor="">
              Civil aviation tourism certificate expiry date{" "}
              <span style={{ color: "red" }}>*</span>
            </label>
            <TextField
              id="date"
              required
              type="date"
              {...register("certificate_expiry_date", {
                required: "Civil aviation tourism expiry date is required",
              })}
              error={
                errors.certificate_expiry_date &&
                Boolean(errors.certificate_expiry_date)
              }
              helperText={
                Boolean(errors?.certificate_expiry_date?.message)
                  ? `${errors?.certificate_expiry_date?.message}`
                  : ""
              }
              // onChange={(e) =>
              //   setDate({
              //     ...date,
              //     certificate_expiry_date: e.target.value,
              //   })
              // }
              sx={{ width: "100%" }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
        </Grid>
      </Grid>

      {!user.is_applied ? (
        <Box sx={{ mt: 5, textAlign: "end", mb: 1 }}>
          {isLoading ? (
            <Button disabled variant="contained" disableElevation>
              Loading...
            </Button>
          ) : (
            <Button type="submit" variant="contained" disableElevation>
              Save
            </Button>
          )}
        </Box>
      ) : (
        ""
      )}
    </Box>
  );
};

export default AddCivilAviationInfo;
