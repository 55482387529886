import { Dispatch, SetStateAction, useState } from "react";
import { Box, Grid, TextField, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { useAuthContext } from "../../Context/AuthContext/AuthContext";
import { url } from "../../Helpers/utils/Constant";
import { getToken } from "../../rest/utils/get-token";
import { showToast } from "../../Toaster/Toaster";
import { useDashboardQuery } from "../../Components/Dashboard/api/dashboardEndPoint";

type IProps = {
  handelClose: Dispatch<SetStateAction<boolean>>;
  setReload: Function;
};

const CreateRenewalApplication = ({ handelClose, setReload }: IProps) => {
  const { user } = useAuthContext();
  const { register, handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);
  const token = getToken();

  const { dashboardData } = useDashboardQuery(user.user_member_id);

  const onSubmit = async (data: any) => {
    setLoading(true);
    const formData = new FormData();
    Object.keys(data).forEach((item) => {
      if (typeof data[item] === "object") {
        formData.append(item, data[item][0]);
      } else {
        formData.append(item, data[item]);
      }
    });
    formData.append(
      "renewal_application_member_id",
      user?.user_member_id?.toString()
    );

    try {
      const response = await fetch(`${url}/api/member/renewal-application`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token || ""}`,
        },
        body: formData,
      });

      const responseData = await response.json();

      if (responseData.success) {
        setLoading(false);
        handelClose(false);
        setReload();
        showToast("Application submitted successfully", "success");
      } else {
        setLoading(false);
        showToast(
          responseData.message || "Failed to submit application",
          "error"
        );
      }
    } catch (error: any) {
      showToast(error?.message || "An error occurred", "error");
      setLoading(false);
    }
  };

  return (
    <>
      <Box component="form" onSubmit={handleSubmit(onSubmit)} p={2}>
        <Grid container spacing={2} mb={2} maxWidth={"sm"}>
          <Grid xs={12} lg={6} item>
            <TextField
              fullWidth
              label="Updated civil aviation file ( max size 5mb )"
              required
              id="outlined-required"
              type="file"
              InputLabelProps={{
                shrink: true,
              }}
              {...register("renewal_application_updated_civil_aviation_file")}
            />
          </Grid>
          <Grid xs={12} lg={6} item>
            <TextField
              fullWidth
              label="Updated trade license file ( max size 5mb )"
              required
              id="outlined-required"
              type="file"
              InputLabelProps={{
                shrink: true,
              }}
              {...register("renewal_application_updated_trade_license_file")}
            />
          </Grid>
          <Grid xs={12} lg={6} item>
            <TextField
              fullWidth
              label="TAX Return certificate file ( max size 5mb )"
              required
              id="outlined-required"
              type="file"
              InputLabelProps={{
                shrink: true,
              }}
              {...register("renewal_application_tax_return_certificate_file")}
            />
          </Grid>
          <Grid xs={12} lg={6} item>
            <TextField
              fullWidth
              label="Previous Certificate file from ATAB ( max size 5mb )"
              required
              id="outlined-required"
              type="file"
              InputLabelProps={{
                shrink: true,
              }}
              {...register("renewal_application_prev_atab_certificate_file")}
            />
          </Grid>
          <Grid xs={12} lg={6} item>
            <TextField
              fullWidth
              label="Undertaking File (Max size 5mb)"
              required
              id="outlined-required"
              type="file"
              InputLabelProps={{
                shrink: true,
              }}
              {...register("under_taking_file")}
            />
          </Grid>
          <Grid xs={12} lg={6} item>
            <TextField
              fullWidth
              label="Office Deed (Max size 5mb)"
              required
              id="outlined-required"
              type="file"
              InputLabelProps={{
                shrink: true,
              }}
              {...register("office_deed_file")}
            />
          </Grid>
          {dashboardData?.company_ownership_status === "Incorporated" && (
            <>
              {" "}
              <Grid xs={12} lg={6} item>
                <TextField
                  fullWidth
                  label="Ltd. Co. all Docs (latest)"
                  required
                  id="outlined-required"
                  type="file"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...register("ltd_docs")}
                />
              </Grid>
              <Grid xs={12} lg={6} item>
                <TextField
                  fullWidth
                  label="Schedule 10/12"
                  required
                  id="outlined-required"
                  type="file"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...register("schedule_10_12")}
                />
              </Grid>
            </>
          )}
        </Grid>
        <Box mt={5}>
          {!loading ? (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disableElevation
            >
              Submit
            </Button>
          ) : (
            <LoadingButton
              loading
              loadingIndicator="Loading..."
              variant="contained"
              fullWidth
              disabled
            >
              <span>Loading...</span>
            </LoadingButton>
          )}
        </Box>
      </Box>
    </>
  );
};

export default CreateRenewalApplication;
