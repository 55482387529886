import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { useAuthContext } from "../../../Context/AuthContext/AuthContext";
import TableLoader from "../../Spinner/TableLoader";
import TableNoDataMessage from "../../TableMessage/TableNoDataMessage";
import { makeStyles } from "@mui/styles";
import { useDashboardServiceQuery } from "../api/dashboardEndPoint";
const useStyles = makeStyles({
  table: {
    borderCollapse: "collapse",
    "& td, & th": {
      padding: "4px",
      fontFamily: "Poppins, sans-serif",
    },
  },
  tableCell: {
    padding: "6px",
    fontFamily: "Poppins, sans-serif",
  },
  container: {
    maxHeight: 400,
    "&::-webkit-scrollbar": {
      width: 4,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0, 0, 0, 0.2)",
    },
  },
});
const Services = () => {
  const { user } = useAuthContext();

  const { allService, isLoading } = useDashboardServiceQuery(
    user.user_member_id
  );

  const classes = useStyles();

  return (
    <>
      <TableContainer sx={{ height: "212px" }} className={classes.container}>
        <Table aria-label="simple table" className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCell}>Name</TableCell>
              <TableCell className={classes.tableCell}>Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableLoader />
            ) : (
              <>
                {allService?.length ? (
                  <>
                    {allService.map((sService) => (
                      <TableRow key={sService.atab_paymentable_item_id}>
                        <TableCell
                          sx={{
                            width: { xs: "150px", md: "250px", xl: "450px" },
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                          className={classes.tableCell}
                        >
                          {sService.atab_paymentable_item_name}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {sService.atab_paymentable_item_bdt_amount}
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                ) : (
                  <TableNoDataMessage />
                )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default Services;
