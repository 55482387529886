import { Dispatch, SetStateAction, useEffect } from "react";
import { Box, Grid, TextField, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { useAuthContext } from "../../Context/AuthContext/AuthContext";
import { useDuplicateCertificateMutation } from "../../rest/useDuplicateCertificate";

type IProps = {
  handelClose: Dispatch<SetStateAction<boolean>>;
  setReload: () => void;
};

const CreateDuplicateApplication = ({ handelClose, setReload }: IProps) => {
  const { user } = useAuthContext();
  const { register, handleSubmit } = useForm();
  const { createData, data, loading } = useDuplicateCertificateMutation();
  useEffect(() => {
    if (data.success) {
      handelClose(false);
      setReload();
    }
  }, [data.success, setReload, handelClose]);
  const onSubmit = async (data: any) => {
    const formData = new FormData();
    Object.keys(data).forEach((item) => {
      if (typeof data[item] === "object") {
        formData.append(item, data[item][0]);
      }
    });
    formData.append(
      "duplicate_certificate_application_member_id",
      user.user_member_id?.toString()
    );
    createData(formData);
  };

  return (
    <>
      <Box component="form" onSubmit={handleSubmit(onSubmit)} p={2}>
        <Grid container spacing={2} mb={2} maxWidth={"sm"}>
          <Grid xs={12} md={6} item>
            <TextField
              fullWidth
              label="Duplicate Certificate Copy ( max size 5mb )"
              required
              id="outlined-required"
              type="file"
              InputLabelProps={{
                shrink: true,
              }}
              {...register("duplicate_certificate_application_gd_copy")}
            />
          </Grid>
          <Grid xs={12} md={6} item>
            <TextField
              fullWidth
              label="Duplicate Certificate Application file ( max size 5mb )"
              required
              id="outlined-required"
              type="file"
              InputLabelProps={{
                shrink: true,
              }}
              {...register(
                "duplicate_certificate_application_application_file"
              )}
            />
          </Grid>
          <Grid xs={12} md={6} item>
            <TextField
              fullWidth
              label="Duplicate Certificate Tax return file ( max size 5mb )"
              required
              id="outlined-required"
              type="file"
              InputLabelProps={{
                shrink: true,
              }}
              {...register("duplicate_certificate_application_tax_return")}
            />
          </Grid>
          <Grid xs={12} md={6} item>
            <TextField
              fullWidth
              label="Duplicate Certificate trade license file ( max size 5mb )"
              required
              id="outlined-required"
              type="file"
              InputLabelProps={{
                shrink: true,
              }}
              {...register("duplicate_certificate_application_trade_license")}
            />
          </Grid>
          <Grid xs={12} md={6} item>
            <TextField
              fullWidth
              label="Duplicate Certificate civil aviation file ( max size 5mb )"
              required
              id="outlined-required"
              type="file"
              InputLabelProps={{
                shrink: true,
              }}
              {...register("duplicate_certificate_application_civil_aviation")}
            />
          </Grid>
        </Grid>
        <Box mt={5}>
          {!loading ? (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disableElevation
            >
              Submit
            </Button>
          ) : (
            <LoadingButton
              loading
              loadingIndicator="Loading..."
              variant="contained"
              fullWidth
              disabled
            >
              <span>Loading...</span>
            </LoadingButton>
          )}
        </Box>
      </Box>
    </>
  );
};

export default CreateDuplicateApplication;
