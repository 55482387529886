import { useAuthContext } from "../../../Context/AuthContext/AuthContext";
import { imgUrl, url } from "../../../Helpers/utils/Constant";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Card,
  CardHeader,
} from "@mui/material";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import moment from "moment";
import TableLoader from "../../../Components/Spinner/TableLoader";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import TableNoDataMessage from "../../../Components/TableMessage/TableNoDataMessage";
import { useAllCertificateQuery } from "./api/certificateEndPoint";
import { useState } from "react";
import axios from "axios";

const DownloadCertificate = () => {
  const { user } = useAuthContext();
  const [refresh, setRefresh] = useState(false);
  const { certificate, isLoading } = useAllCertificateQuery(
    user.user_member_id,
    refresh
  );

  const handelClick = async (cID: number) => {
    const { data } = await axios.delete(
      `${url}/api/atab/user-member/certificate/${cID}?user=member`
    );
    if (data.success) {
      setRefresh((prv) => !prv);
    }
  };
  return (
    <Card sx={{ mt: 5 }}>
      <CardHeader
        title={
          <Typography sx={{ fontWeight: 700, lineHeight: 1.34, fontSize: 17 }}>
            Certificate list
          </Typography>
        }
      />
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 700, fontSize: 16 }}>ID</TableCell>
              <TableCell align="center" sx={{ fontWeight: 700, fontSize: 16 }}>
                Type
              </TableCell>
              <TableCell align="center" sx={{ fontWeight: 700, fontSize: 16 }}>
                Issue Date
              </TableCell>
              <TableCell align="center" sx={{ fontWeight: 700, fontSize: 16 }}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableLoader />
            ) : (
              <>
                {certificate?.length ? (
                  <>
                    {certificate.map((sCertificate) => (
                      <TableRow
                        key={sCertificate.c_id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {sCertificate.c_id}
                        </TableCell>
                        <TableCell align="center">
                          {sCertificate.c_type === "member_certificate" ? (
                            <>Member Certificate</>
                          ) : (
                            <>
                              {sCertificate.c_type ===
                              "duplicate_certificate" ? (
                                <> Duplicate Certificate</>
                              ) : (
                                <> Renewal Certificate</>
                              )}
                            </>
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {moment(sCertificate.c_issue_date).format(
                            "MMM Do YY"
                          )}
                        </TableCell>
                        <TableCell align="center">
                          <Tooltip title="Download">
                            <IconButton>
                              <a
                                style={{ color: "black" }}
                                href={`${imgUrl}/${sCertificate.c_photo}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <ArrowCircleDownIcon
                                  onClick={() => handelClick(sCertificate.c_id)}
                                />
                              </a>
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                ) : (
                  <>
                    <TableNoDataMessage />
                  </>
                )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default DownloadCertificate;
