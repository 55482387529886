import { Typography } from "@mui/material";

export const CommonTypoGraphy = ({
  onClick,
  name,
}: {
  onClick: any;
  name: string;
}) => {
  return (
    <>
      <Typography
        variant="body1"
        fontWeight="bold"
        display="inline"
        sx={{ pr: 1, cursor: "pointer", color: "#0000ef" }}
        gutterBottom
        onClick={onClick}
      >
        {name}
      </Typography>
    </>
  );
};
