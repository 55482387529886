import { useState } from "react";
import {
  Checkbox,
  Button,
  CssBaseline,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import { Container } from "@mui/system";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { muiStyles } from "../../Utils/Styles";
import WestIcon from "@mui/icons-material/West";
import axios from "axios";
import { url } from "../../Helpers/utils/Constant";
import { showToast } from "../../Toaster/Toaster";

const useStyles = muiStyles;
const NewPassword = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const [password1, setPassword1] = useState<string>("");
  const [password2, setPassword2] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const email = searchParams.get("email");

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    if (password1 !== password2) {
      showToast(`Password does not match`, "error");

      setIsLoading(false);
      return;
    }
    if (password1.length < 8) {
      showToast(`Password must be at least 8 characters`, "error");
      setIsLoading(false);
      return;
    }
    try {
      axios
        .post(`${url}/api/auth/member/forget/password`, {
          token: localStorage.getItem("memberForgetToken"),
          email,
          password: password1,
        })
        .then(function (res) {
          if (res.data.success) {
            showToast(`${res.data.message}`, "success");
            localStorage.removeItem("memberForgetToken");
            setIsLoading(false);
            navigate("/login");
          } else {
            setIsLoading(false);
          }
        })
        .catch(function (err) {
          showToast(`${err.response.data.message}`, "error");
          setIsLoading(false);
        });
    } catch (error) {
      setIsLoading(false);
    }
  };
  return (
    <>
      <div className="login-page">
        <div className="login-wrapper">
          <div className="login-left-col">
            <video autoPlay muted loop height={"100%"}>
              <source
                src={
                  "https://m360ict.s3.ap-south-1.amazonaws.com/biha-files/logo/login-video.mp4"
                }
                type="video/mp4"
              />
            </video>
          </div>

          <div>
            <div className="login-right-col">
              <Container maxWidth="sm" component="main">
                <CssBaseline />
                <div className="right-top-div">
                  <img src="/logo.png" alt="" />
                </div>

                <div className="pt-3">
                  <Typography
                    variant="h4"
                    gutterBottom
                    sx={{
                      textAlign: "center",
                      fontWeight: "600",
                      color: "#0c4da2",
                      pb: 3,
                    }}
                  >
                    Set new password
                  </Typography>
                </div>
                <Box>
                  <Box component="form" onSubmit={handleSubmit}>
                    <Box>
                      <TextField
                        required
                        fullWidth
                        type="text"
                        label="Enter new password"
                        onChange={(e: any) => setPassword1(e.target.value)}
                        variant="filled"
                        className={classes.inputText}
                        InputProps={{ classes }}
                      />
                    </Box>
                    <Box pt={2}>
                      <TextField
                        required
                        fullWidth
                        type={`${showPassword ? "text" : "password"}`}
                        label="Retype password"
                        onChange={(e: any) => setPassword2(e.target.value)}
                        variant="filled"
                        className={classes.inputText}
                        InputProps={{ classes }}
                      />
                    </Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onClick={() => setShowPassword(!showPassword)}
                          value={showPassword}
                          color="primary"
                        />
                      }
                      label="Show Password"
                    />
                    <Box>
                      {isLoading ? (
                        <Button
                          disabled
                          fullWidth
                          variant="contained"
                          disableElevation
                          sx={{
                            mt: 3,
                            mb: 2,

                            background: "#1113a2",
                          }}
                          className={classes.signInButton}
                        >
                          Loading...
                        </Button>
                      ) : (
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          disableElevation
                          sx={{
                            mt: 3,
                            mb: 2,

                            background: "#1113a2",
                          }}
                          className={classes.signInButton}
                        >
                          Submit
                        </Button>
                      )}
                    </Box>

                    <Grid container>
                      <Grid item xs={12} sm={6} md={12}>
                        <Link to="/login">
                          <Typography
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              marginTop: "20px",
                            }}
                            className="text-main"
                          >
                            <WestIcon
                              sx={{ marginRight: "5px" }}
                              fontSize="small"
                            />{" "}
                            {"Go Back"}
                          </Typography>
                        </Link>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Container>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewPassword;
