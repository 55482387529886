import React, { useState, useEffect } from 'react';
import { TextField, Autocomplete, Button, Box } from '@mui/material';
import { url } from '../../../Helpers/utils/Constant';
import axios from 'axios';
import { useAuthContext } from '../../../Context/AuthContext/AuthContext';
import { showToast } from '../../../Toaster/Toaster';

interface IProps {
	handleClose: Function;
}
const ServiceModal = ({ handleClose }: IProps) => {
	const { user } = useAuthContext();
	const [paymentAbleItem, setPaymentAbleItem] = useState([]);
	const [paymentAbleId, setPaymentAbleId] = useState([]);
	const [loading, setLoading] = useState(false);
	useEffect(() => {
		(async () => {
			const { data } = await axios.get(
				`${url}/api/atab/payment/get/all/paymentable/item?status=1`
			);
			if (data.success) {
				setPaymentAbleItem(data.data);
			}
		})();
	}, []);

	const handelSubmit = async () => {
		setLoading(true);
		if (!paymentAbleId?.length) {
			showToast(`Select service item`, 'error');
			setLoading(false);
		}
		const selectedMember = paymentAbleId.map((member: any) => {
			return member.atab_paymentable_item_id;
		});
		let body = {
			atab_payment_invoice_user_member_id: user.user_member_id,
			atab_payment_invoice_paymentable_items: selectedMember,
		};

		try {
			const { data } = await axios.post(
				`${url}/api/atab/invoice/create/for-member`,
				body
			);
			if (data.success) {
				showToast(`Successfully send request`, 'success');
				handleClose();
			} else {
				showToast(`${data.message}`, 'success');
			}
		} catch (error) {
		} finally {
			setLoading(false);
		}
	};
	return (
		<>
			<Box p={3}>
				<Autocomplete
					multiple
					sx={{ width: '300px' }}
					id="tags-standard"
					onChange={(e, mainValue: any) => setPaymentAbleId(mainValue)}
					options={paymentAbleItem}
					getOptionLabel={(option) =>
						option.atab_paymentable_item_name +
						' (' +
						option.atab_paymentable_item_bdt_amount +
						'tk)'
					}
					renderInput={(params) => (
						<TextField
							{...params}
							variant="outlined"
							label="Select service"
							placeholder="Select service"
						/>
					)}
				/>

				<Box sx={{ textAlign: 'center', mt: 2 }}>
					{loading ? (
						<Button disabled variant="contained" fullWidth disableElevation>
							Loading..
						</Button>
					) : (
						<Button
							variant="contained"
							fullWidth
							disableElevation
							onClick={handelSubmit}
						>
							Submit
						</Button>
					)}
				</Box>

				{/* <Tabs
          onChange={handleChange}
          value={statusTabValue}
          aria-label='Tabs where selection follows focus'
          selectionFollowsFocus
        >
          <Tab label='Paid Service' />
          <Tab label='Free Service' />
        </Tabs>
        <>
          {!statusTabValue ? (
            <div className='existing-Service'>
              <PaidService />
            </div>
          ) : (
            <div>
              <FreeService />
            </div>
          )}
        </> */}
			</Box>
		</>
	);
};

export default ServiceModal;
