import { Drawer, Typography } from "@mui/material";
import ListItems from "./ListItems";

const Copyright = (props: any) => {
  return (
    <Typography variant="body2" align="center" {...props}>
      {"Copyright © "}
      <a target="_blank" rel="noreferrer" href="https://m360ict.com/">
        M360ICT
      </a>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
};

export const DashboardSidebarDrawer = ({
  container,
  mobileOpen,
  handleDrawerToggle,
  drawerWidth,
}: any) => {
  return (
    <>
      {/* ====== drawer for small device ====== */}
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            "&::-webkit-scrollbar": {
              width: 4,
            },
            "&::-webkit-scrollbar-track": {
              boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.3)`,
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#F36167",
            },
          },
        }}
      >
        <ListItems />
        {/* <Copyright
          sx={{
            position: 'absolute',
            bottom: '0',
            left: '50%',
            transform: 'translateX(-50%)',
            display: { xs: 'block', sm: 'none' },
          }}
        /> */}
      </Drawer>

      {/* ======= drawer for big device ======== */}

      <Drawer
        className="drawer-lg-device"
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            "&::-webkit-scrollbar": {
              width: 6,
            },
            "&::-webkit-scrollbar-track": {
              boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.3)`,
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#F36167",
            },
          },
        }}
        open
      >
        <ListItems />
        {/* <Copyright
          sx={{
            position: 'absolute',
            bottom: '0',
            left: '50%',
            transform: 'translateX(-50%)',
            display: { xs: 'none', sm: 'block', md: 'none', xl: 'block' },
          }}
        /> */}
      </Drawer>
    </>
  );
};

export { Copyright };
