import { CardContent, Grid, Typography } from "@mui/material";
import PageLoader from "../../../Components/Spinner/PageLoader";
import { ICompanyDocument } from "../../../rest/types";
import ModalsWrapper from "../../../Components/Modals/ModalsWrapper";
import PdfAndImageView from "../../../Components/pdfAndImageView/PdfAndImageView";
import { useState } from "react";
import { CommonTypoGraphy } from "../../../Components/pdfAndImageView/CommonTypoGraphy";

type IProps = {
  data: ICompanyDocument;
  loading: boolean;
};

const CardCompanyDocument = ({ data: item, loading }: IProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [imageFile, setImageFile] = useState<string | null>("");
  return (
    <>
      {!loading ? (
        <CardContent>
          <Grid
            container
            pt={2}
            spacing={{ xs: 2, md: 2 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            {" "}
            <Grid item xs={2} sm={4} md={4}>
              <Typography
                variant="body1"
                fontWeight="bold"
                color="text.primary"
                display="inline"
                sx={{ pr: 1 }}
                gutterBottom
              >
                Ownership status:
              </Typography>
              {item.company_ownership_status}
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <Typography
                variant="body1"
                fontWeight="bold"
                color="text.primary"
                display="inline"
                sx={{ pr: 1 }}
                gutterBottom
              >
                Office agreement:
              </Typography>
              {item.company_office_agreement}
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <CommonTypoGraphy
                name="View agreement file"
                onClick={() => {
                  setOpen(true);
                  setImageFile(item?.company_agreement_file);
                }}
              />
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <CommonTypoGraphy
                name="View staff list"
                onClick={() => {
                  setOpen(true);
                  setImageFile(item?.company_staff_list_file);
                }}
              />
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <CommonTypoGraphy
                name="View sales statement"
                onClick={() => {
                  setOpen(true);
                  setImageFile(item?.company_sales_statement);
                }}
              />
            </Grid>{" "}
            <Grid item xs={2} sm={4} md={4}>
              <CommonTypoGraphy
                name="View bank statement"
                onClick={() => {
                  setOpen(true);
                  setImageFile(item?.company_bank_statement);
                }}
              />
            </Grid>
            {item.company_partnership_deed_file && (
              <Grid item xs={2} sm={4} md={4}>
                <CommonTypoGraphy
                  name="View partnership deed"
                  onClick={() => {
                    setOpen(true);
                    setImageFile(item?.company_partnership_deed_file);
                  }}
                />
              </Grid>
            )}
            {item.company_incorporation_certificate && (
              <Grid item xs={2} sm={4} md={4}>
                {" "}
                <CommonTypoGraphy
                  name="View incorporation certificate"
                  onClick={() => {
                    setOpen(true);
                    setImageFile(item?.company_incorporation_certificate);
                  }}
                />
              </Grid>
            )}
            {item.company_memorandum_file && (
              <Grid item xs={2} sm={4} md={4}>
                {" "}
                <CommonTypoGraphy
                  name="View memorandum"
                  onClick={() => {
                    setOpen(true);
                    setImageFile(item?.company_memorandum_file);
                  }}
                />
              </Grid>
            )}
            {item.company_one_staff_certificate && (
              <Grid item xs={2} sm={4} md={4}>
                <CommonTypoGraphy
                  name="View staff GDS certificate"
                  onClick={() => {
                    setOpen(true);
                    setImageFile(item?.company_one_staff_certificate);
                  }}
                />
              </Grid>
            )}
            {item.company_office_photographs.length > 0 &&
              item.company_office_photographs.map((file, i) => (
                <Grid item xs={2} sm={4} md={4} key={file}>
                  <CommonTypoGraphy
                    name={`${i + 1}. Office photographs`}
                    onClick={() => {
                      setOpen(true);
                      setImageFile(file);
                    }}
                  />
                </Grid>
              ))}
          </Grid>
        </CardContent>
      ) : (
        <PageLoader />
      )}{" "}
      <>
        <ModalsWrapper
          modalData={{ title: "View document" }}
          setShowModal={setOpen}
          showModal={open}
        >
          <PdfAndImageView file={imageFile} />
        </ModalsWrapper>
      </>
    </>
  );
};

export default CardCompanyDocument;
