import { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

export const getStatusLabel = (invoiceStatus: string): JSX.Element => {
	const map: any = {
		failed: {
			text: 'Failed',
			color: 'error',
		},
		completed: {
			text: 'Completed',
			color: 'success',
		},
		running: {
			text: 'Running',
			color: 'success',
		},
		finished: {
			text: 'Finished',
			color: 'success',
		},
		accepted: {
			text: 'Accepted',
			color: 'success',
		},
		pending: {
			text: 'Pending',
			color: 'warning',
		},
		attend: {
			text: 'Attend',
			color: 'success',
		},
		absent: {
			text: 'Absent',
			color: 'warning',
		},
		rejected: {
			text: 'Rejected',
			color: 'error',
		},
		active: {
			text: 'Active',
			color: 'success',
		},
		deactivate: {
			text: 'Deactivate',
			color: 'warning',
		},
		block: {
			text: 'Block',
			color: 'warning',
		},
		approved: {
			text: 'Approved',
			color: 'success',
		},
		ended: {
			text: 'Ended',
			color: 'warning',
		},
		upcoming: {
			text: 'Upcoming',
			color: 'warning',
		},
		cancelled: {
			text: 'Cancelled',
			color: 'warning',
		},
		available: {
			text: 'Available',
			color: 'success',
		},
		reserved: {
			text: 'Reserved',
			color: 'warning',
		},
		booked: {
			text: 'Booked',
			color: 'warning',
		},
		paid: {
			text: 'Paid',
			color: 'success',
		},
		reported: {
			text: 'Reported',
			color: 'warning',
		},
	};
	if ((map[invoiceStatus] && invoiceStatus !== null) || '') {
		const { text, color }: any = map[invoiceStatus];
		return <Label color={color}>{text}</Label>;
	} else if (invoiceStatus !== null || '') {
		return <Label>{invoiceStatus}</Label>;
	}
	return invoiceStatus;
};

interface LabelProps {
	className?: string;
	color?:
		| 'primary'
		| 'black'
		| 'secondary'
		| 'error'
		| 'warning'
		| 'success'
		| 'info';
	children?: ReactNode;
}

const LabelWrapper = styled('span')(
	({ theme }) => `
      background-color: ${theme.colors.alpha.black[5]};
      padding: ${theme.spacing(0.5, 1)};
      font-size: ${theme.typography.pxToRem(13)};
      border-radius: ${theme.general.borderRadius};
      display: inline-flex;
      align-items: center;
      justify-content: center;
      max-height: ${theme.spacing(3)};
      
      &.MuiLabel {
        &-primary {
          background-color: ${theme.colors.primary.lighter};
          color: ${theme.palette.primary.main}
        }

        &-black {
          background-color: ${theme.colors.alpha.black[100]};
          color: ${theme.colors.alpha.white[100]};
        }
        
        &-secondary {
          background-color: ${theme.colors.secondary.lighter};
          color: ${theme.palette.secondary.main}
        }
        
        &-success {
          background-color: ${theme.colors.success.lighter};
          color: ${theme.palette.success.main}
        }
        
        &-warning {
          background-color: ${theme.colors.warning.lighter};
          color: ${theme.palette.warning.main}
        }
              
        &-error {
          background-color: ${theme.colors.error.lighter};
          color: ${theme.palette.error.main}
        }
        
        &-info {
          background-color: ${theme.colors.info.lighter};
          color: ${theme.palette.info.main}
        }
      }
`
);

const Label: FC<LabelProps> = ({
	className,
	color = 'secondary',
	children,
	...rest
}) => {
	return (
		<LabelWrapper className={'MuiLabel-' + color} {...rest}>
			{children}
		</LabelWrapper>
	);
};

Label.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	color: PropTypes.oneOf([
		'primary',
		'black',
		'secondary',
		'error',
		'warning',
		'success',
		'info',
	]),
};

export default Label;
