import { useState } from "react";
import { Card, CardHeader, Typography, Button } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ModalsWrapper from "../../Components/Modals/ModalsWrapper";
import IdCardForm from "./IdCardForm";
import { useAuthContext } from "../../Context/AuthContext/AuthContext";
import TableLoader from "../../Components/Spinner/TableLoader";
import TableNoDataMessage from "../../Components/TableMessage/TableNoDataMessage";
import moment from "moment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Link } from "react-router-dom";
import { getStatusLabel } from "../../Utils/Comibined";
import { useAllIdCardQuery } from "./api/idCardEndPoint";

const IdCard = () => {
  const { user } = useAuthContext();
  const [open, setOpen] = useState(false);
  const { idCard, isLoading } = useAllIdCardQuery(user.user_member_id);

  return (
    <>
      <Card sx={{ mt: 5 }}>
        <CardHeader
          action={
            user?.user_member_account_status === "active" && (
              <Button variant="contained" onClick={() => setOpen(true)}>
                Request for id card
              </Button>
            )
          }
          title={
            <Typography
              sx={{ fontWeight: 700, lineHeight: 1.34, fontSize: 17 }}
            >
              ID Card
            </Typography>
          }
        />
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>ID</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Name</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Requested At</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Status</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableLoader />
              ) : (
                <>
                  {idCard?.length ? (
                    <>
                      {idCard.map((sIdCard) => {
                        return (
                          <TableRow key={sIdCard.id}>
                            <TableCell>{sIdCard.id}</TableCell>
                            <TableCell>{sIdCard.applicant_name}</TableCell>
                            <TableCell>
                              {moment(sIdCard.created_at).format("MMM Do YY")}
                            </TableCell>
                            <TableCell>
                              {getStatusLabel(sIdCard.status)}
                            </TableCell>
                            <TableCell>
                              {/* <ArrowCircleDownIcon sx={{ mr: 1.5 }} /> */}
                              <Link to={`/id-card-application/${sIdCard.id}`}>
                                {" "}
                                <VisibilityIcon sx={{ color: "black" }} />
                              </Link>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </>
                  ) : (
                    <TableNoDataMessage />
                  )}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      <ModalsWrapper
        modalData={{ title: "Request for ID card" }}
        setShowModal={setOpen}
        showModal={open}
      >
        <IdCardForm handleClose={() => setOpen(false)} />
      </ModalsWrapper>
    </>
  );
};

export default IdCard;
