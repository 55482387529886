import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Autocomplete,
  Grid,
  Button,
  TextField,
  Chip,
  Divider,
} from "@mui/material";
import { Box } from "@mui/system";
import { useForm } from "react-hook-form";
import { IMemberOrganizationProposer } from "../../../Types/UpdateCompanyInfoTypes";
import { IProposerInput } from "../../../Types/UpdateInfotypes/UpdateCompanyInfoStateTypes";
import { useAuthContext } from "../../../Context/AuthContext/AuthContext";
import { url } from "../../../Helpers/utils/Constant";
import { useCompanyProposerUpdate } from "../../../rest/useCompanyInfo";

const AddCompanyProposerInfo = () => {
  const { user } = useAuthContext();
  const { handleSubmit } = useForm();
  const { isLoading, mutate } = useCompanyProposerUpdate();
  const [memberOrganizationProposer, setMemberOrganizationProposer] = useState<
    IMemberOrganizationProposer[]
  >([]);
  const [proposerInput, setProposerInput] = useState<IProposerInput>({
    member_1st_proposer_id: 0,
    member_1st_proposer_seal_signature: "",
    member_2nd_proposer_id: 0,
    member_2nd_proposer_seal_signature: "",
  });
  const [companyName, seTCompanyName] = useState<string>("");

  useEffect(() => {
    axios
      .get(
        `${url}/api/atab/user-member/company-name/get-all/status-or-all/active?company_name=${companyName}`
      )
      .then(function (res) {
        // console.log(res.data);
        if (res.data.success) {
          setMemberOrganizationProposer(res.data.data);
        }
      });
  }, [companyName]);

  const proposerOption = memberOrganizationProposer.map((option: any) => ({
    id: option.user_member_id,
    label: option.user_member_company_name,
  }));

  const onSubmit = async (_inputData: any) => {
    // inputData = { ...inputData, ...proposerInput };

    const formData: any = new FormData();

    for (const key in proposerInput) {
      if (proposerInput.hasOwnProperty(key)) {
        const typedKey = key as keyof IProposerInput;
        const value: any = proposerInput[typedKey];

        if (value !== undefined && value !== null) {
          if (
            typedKey === "member_1st_proposer_seal_signature" ||
            key === "member_2nd_proposer_seal_signature"
          ) {
            // formData.append(typedKey as string, String(value));
            console.log(value);
            formData.append(typedKey as string, value);
          } else {
            formData.append(typedKey as string, String(value));
          }
        }
      }
    }

    // console.table(Object.fromEntries(formData));

    mutate({ input: formData });
  };

  return (
    <>
      <Box component="form" onSubmit={handleSubmit(onSubmit)} p={2}>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Box>
                <label htmlFor="">
                  Select 1st proposer name{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>

                <Box>
                  <Autocomplete
                    disablePortal
                    onChange={(e: any, newValue: any) =>
                      setProposerInput({
                        ...proposerInput,
                        member_1st_proposer_id: newValue?.id,
                      })
                    }
                    isOptionEqualToValue={(proposerOption, value) =>
                      proposerOption.id === value.id
                    }
                    loading={true}
                    id="controllable-states-demo"
                    options={proposerOption}
                    onInputChange={(_event, newInputValue) => {
                      console.log(newInputValue);
                      seTCompanyName(newInputValue);
                    }}
                    getOptionLabel={(option) => option.label}
                    renderTags={(value: readonly any[], getTagProps) =>
                      value.map((option: any, index: number) => (
                        <Chip
                          variant="filled"
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        label="Select 1st proposer name"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: <>{params.InputProps.endAdornment}</>,
                        }}
                      />
                    )}
                  />
                </Box>
              </Box>
            </Grid>{" "}
            {proposerInput?.member_1st_proposer_id ? (
              <Grid item xs={12} md={4}>
                <Box>
                  <label htmlFor="">
                    1st proposer seal and signature ( max size 5mb )
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <TextField
                    id="outlined-basic"
                    required
                    type="file"
                    fullWidth
                    InputProps={{
                      inputProps: {
                        accept: "image/jpeg,image/png,image/jpg",
                      },
                    }}
                    onChange={(e: any) => {
                      setProposerInput({
                        ...proposerInput,
                        member_1st_proposer_seal_signature: e.target.files[0],
                      });
                    }}
                  />
                </Box>
              </Grid>
            ) : (
              ""
            )}
            {/* <Grid item xs={12} md={4}>
              <Box>
                <label htmlFor="">
                  1st proposer signature ( max size 5mb )
                  <span style={{ color: "red" }}>*</span>
                </label>
                <TextField
                  onChange={(e: any) => {
                    setProposerInput({
                      ...proposerInput,
                      member_1st_proposer_signature: e.target.files[0],
                    });
                  }}
                  id="outlined-basic"
                  required
                  variant="outlined"
                  type="file"
                  fullWidth
                />
              </Box>
            </Grid> */}
          </Grid>

          <Divider style={{ margin: "20px 0px" }} />
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Box>
                <label htmlFor="">
                  Select 2nd proposer name{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>

                <Box>
                  <Autocomplete
                    disablePortal
                    onChange={(e: any, newValue: any) =>
                      setProposerInput({
                        ...proposerInput,
                        member_2nd_proposer_id: newValue?.id,
                      })
                    }
                    isOptionEqualToValue={(proposerOption, value) =>
                      proposerOption.id === value.id
                    }
                    loading={true}
                    id="controllable-states-demo"
                    options={proposerOption}
                    onInputChange={(_event, newInputValue) => {
                      console.log(newInputValue);
                      seTCompanyName(newInputValue);
                    }}
                    getOptionLabel={(option) => option.label}
                    renderTags={(value: readonly any[], getTagProps) =>
                      value.map((option: any, index: number) => (
                        <Chip
                          variant="filled"
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        label="Select 2nd proposer name"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: <>{params.InputProps.endAdornment}</>,
                        }}
                      />
                    )}
                  />
                </Box>
              </Box>
            </Grid>{" "}
            {proposerInput?.member_2nd_proposer_id ? (
              <Grid item xs={12} md={4}>
                <Box>
                  <label htmlFor="">
                    2nd proposer seal and signature ( max size 5mb )
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <TextField
                    id="outlined-basic"
                    required
                    variant="outlined"
                    type="file"
                    fullWidth
                    InputProps={{
                      inputProps: {
                        accept: "image/jpeg,image/png,image/jpg",
                      },
                    }}
                    onChange={(e: any) => {
                      setProposerInput({
                        ...proposerInput,
                        member_2nd_proposer_seal_signature: e.target.files[0],
                      });
                    }}
                  />
                </Box>
              </Grid>
            ) : (
              ""
            )}
            {/* <Grid item xs={12} md={4}>
              <Box>
                <label htmlFor="">
                  2nd proposer signature ( max size 5mb )
                  <span style={{ color: "red" }}>*</span>
                </label>
                <TextField
                  id="outlined-basic"
                  required
                  variant="outlined"
                  type="file"
                  fullWidth
                  onChange={(e: any) => {
                    setProposerInput({
                      ...proposerInput,
                      member_2nd_proposer_signature: e.target.files[0],
                    });
                  }}
                />
              </Box>
            </Grid> */}
          </Grid>
        </Box>
        {!user.is_applied ? (
          <Box sx={{ mt: 2, textAlign: "end", mb: 1 }}>
            {isLoading ? (
              <Button disabled variant="contained" disableElevation>
                Loading...
              </Button>
            ) : (
              <Button type="submit" variant="contained" disableElevation>
                Save
              </Button>
            )}
          </Box>
        ) : (
          ""
        )}
      </Box>
    </>
  );
};

export default AddCompanyProposerInfo;
