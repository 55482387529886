import { useQuery } from "react-query";
import { API_ENDPOINTS } from "../../../rest/utils/api-endpoints";
import { HttpClient } from "../../../rest/utils/http";
import {
  IPayment,
  IPaymentResponse,
} from "../../../Types/Payments/PaymentsTypes";

const fetchPayment = async (id: number) => {
  const fetchData = await HttpClient.get<IPaymentResponse>(
    `${API_ENDPOINTS.PAYMENT(id)}`
  );
  return fetchData;
};

export function usePaymentQuery(id: number) {
  const { data, isLoading, error } = useQuery<IPaymentResponse, Error>(
    [API_ENDPOINTS.PAYMENT],
    () => fetchPayment(id),
    {
      refetchOnWindowFocus: false,
    }
  );
  return {
    allPayments: data?.data ?? ([] as IPayment[]),
    isLoading,
    error,
  };
}
