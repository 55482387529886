import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  // Tooltip,
  // IconButton,
} from "@mui/material";
import { Container } from "@mui/system";
import moment from "moment";
import { useParams } from "react-router";
import PageLoader from "../../Components/Spinner/PageLoader";
import { getStatusLabel } from "../../Utils/Label/status-label";
import { useSingleAgencyInfo } from "../../rest/useAgencyInfoUpdateApplication";
import ModalsWrapper from "../../Components/Modals/ModalsWrapper";
import PdfAndImageView from "../../Components/pdfAndImageView/PdfAndImageView";
import { useState } from "react";
import { CommonTypoGraphy } from "../../Components/pdfAndImageView/CommonTypoGraphy";

const SingleAgencyUpdateInfo = () => {
  const { id } = useParams();
  // print informatuion
  // const theme = useTheme();
  // const componentRef = useRef(null);
  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  // });
  const { singleAgencyInfo: application, isLoading } = useSingleAgencyInfo(
    Number(id)
  );
  const [open, setOpen] = useState<boolean>(false);
  const [imageFile, setImageFile] = useState<string | null>("");

  return (
    <>
      {!isLoading ? (
        <Container sx={{ pt: 5 }}>
          <Card>
            <CardHeader
              title="Application information"
              // action={
              //   <Tooltip title='Print information form' sx={{ mr: 1 }}>
              //     <IconButton onClick={handlePrint}>
              //       <PrintIcon
              //         fontSize='large'
              //         sx={{
              //           cursor: "pointer",
              //           color: theme.colors.primary.main,
              //         }}
              //       />
              //     </IconButton>
              //   </Tooltip>
              // }
            ></CardHeader>
            <CardContent>
              {application ? (
                <Grid
                  container
                  pt={2}
                  spacing={{ xs: 2, md: 3 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                >
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      display="inline"
                      sx={{ pr: 1 }}
                      gutterBottom
                    >
                      Application Date:
                    </Typography>
                    {moment(application.agency_info_created_at).format(
                      "MMM Do YYYY"
                    )}
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      display="inline"
                      sx={{ pr: 1 }}
                      gutterBottom
                    >
                      Status:
                    </Typography>
                    {getStatusLabel(application.status)}
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      display="inline"
                      sx={{ pr: 1 }}
                      gutterBottom
                    >
                      Agency email:
                    </Typography>
                    {application?.agency_email}
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      display="inline"
                      sx={{ pr: 1 }}
                      gutterBottom
                    >
                      Representative name:
                    </Typography>
                    {application.user_member_representative_name}
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      display="inline"
                      sx={{ pr: 1 }}
                      gutterBottom
                    >
                      Representative designation:
                    </Typography>
                    {application.user_member_representative_designation}
                  </Grid>

                  <Grid item xs={2} sm={4} md={4}>
                    <CommonTypoGraphy
                      name="View representative photo"
                      onClick={() => {
                        setOpen(true);
                        setImageFile(
                          application?.user_member_representative_photo
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      display="inline"
                      sx={{ pr: 1 }}
                      gutterBottom
                    >
                      Mobile number:
                    </Typography>
                    {application.phone}
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      display="inline"
                      sx={{ pr: 1 }}
                      gutterBottom
                    >
                      Telephone number:
                    </Typography>
                    {application.cellphone}
                  </Grid>
                  <Grid item xs={2} sm={4} md={4}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      display="inline"
                      sx={{ pr: 1 }}
                      gutterBottom
                    >
                      Website:
                    </Typography>
                    <a
                      href={application.member_company_website}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {application.member_company_website}
                    </a>
                  </Grid>

                  {application.note && (
                    <Grid item xs={2} sm={4} md={4}>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        display="inline"
                        sx={{ pr: 1 }}
                        gutterBottom
                      >
                        Note:
                      </Typography>
                      {application.note}
                    </Grid>
                  )}
                </Grid>
              ) : (
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  display="inline"
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  No Data found
                </Typography>
              )}
            </CardContent>
          </Card>

          {/* <Box display={"none"}>
        <AgencyInfoDownloadForm componentRef={componentRef} />
      </Box> */}
        </Container>
      ) : (
        <PageLoader />
      )}
      <>
        <ModalsWrapper
          modalData={{ title: "View document" }}
          setShowModal={setOpen}
          showModal={open}
        >
          <PdfAndImageView file={imageFile} />
        </ModalsWrapper>
      </>
    </>
  );
};

export default SingleAgencyUpdateInfo;
