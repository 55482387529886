import React from "react";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/system";
import { Button, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
const style = {
  position: "absolute",
  top: {
    xl: "50%",
    xs: "40%",
  },
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};
const ProposerReceiverModal = ({
  open,
  handleClose,
  setRejectReason,
  handelReceiver,
  requestId,
}: any) => {
  return (
    <div>
      {" "}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box>
            <Typography variant="h6" gutterBottom>
              Remark
            </Typography>
            <Box>
              <TextField
                fullWidth
                sx={{ pt: 1 }}
                onChange={(e) => setRejectReason(e.target.value)}
                id="outlined-basic"
                label="Please enter reason "
                variant="outlined"
              />
            </Box>
            <Box
              sx={{
                pt: 2,
                textAlign: "center",
              }}
            >
              <Button
                variant="contained"
                color="warning"
                onClick={() => handleClose()}
              >
                Close
              </Button>
              <Button
                variant="contained"
                sx={{ ml: 2 }}
                onClick={() => handelReceiver(requestId, "rejected")}
              >
                Submit
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default ProposerReceiverModal;
