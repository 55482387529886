import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import BlockIcon from '@mui/icons-material/Block';

const PaymentReject = () => {
  return (
    <>
      {' '}
      <Box
        sx={{
          pt: { xl: 15, md: 10, xs: 20 },
          backgroundColor: '#fafafa',
          height: { xl: '87vh', md: '87vh', xs: '100vh' },
        }}
      >
        <Grid
          container
          spacing={2}
          alignItems='center'
          justifyContent='center'
          textAlign='center'
        >
          <Grid item>
            <Box>
              <BlockIcon sx={{ fontSize: '250px', color: '#e86672' }} />
              <Typography
                variant='h5'
                component='div'
                color={'#e86672'}
                fontWeight={'bold'}
                fontSize={30}
              >
                Your payment request was rejected
              </Typography>
            </Box>

            <Box sx={{ pt: 3 }}>
              <Link to='/'>
                <Button variant='text'>Go back home</Button>
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default PaymentReject;
