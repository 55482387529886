import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Box,
  Button,
  Checkbox,
  Container,
  CssBaseline,
  FormControlLabel,
  MenuItem,
  FormControl,
  Select,
  Grid,
  TextField,
  Typography,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import { Link } from "react-router-dom";
import { showToast } from "../../Toaster/Toaster";
import { muiStyles } from "../../Utils/Styles";
import axios from "axios";
import EmailVerifiModal from "./Page/EmailVerifiModal";
import { url } from "../../Helpers/utils/Constant";

const useStyles = muiStyles;
const Register = () => {
  const classes = useStyles();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inputData, setInputData] = useState<any>({});
  const [open, setOpen] = React.useState(false);

  const onSubmit = async (inputs: any) => {
    setLoading(true);
    const { user_member_confirm_password, user_member_email, ...body } = inputs;

    try {
      axios
        .post(`${url}/api/atab/common/send-email-otp`, {
          email: user_member_email,
          type: "register_member",
        })
        .then(function (res) {
          if (res.data.success) {
            setOpen(true);
            showToast(
              `We sent verification code to ${user_member_email}`,
              "success"
            );
            setInputData({
              ...body,
              user_member_email,
            });
            setLoading(false);
          }
        })
        .catch(function (err) {
          showToast(`${err.response.data.message}`, "error");
          setLoading(false);
        });
    } catch (error) {}
  };
  return (
    <>
      <div className="register-page">
        <div className="register-wrapper">
          {" "}
          <div>
            <div className="login-left-col">
              <video autoPlay muted loop height={"100%"}>
                <source
                  src={
                    "https://m360ict.s3.ap-south-1.amazonaws.com/biha-files/logo/login-video.mp4"
                  }
                  type="video/mp4"
                />
              </video>
            </div>
          </div>
          <div className="register-right-col">
            <Container component="main" maxWidth="md">
              <CssBaseline />

              <div>
                <div className="right-top-div">
                  <img
                    src="/logo.png"
                    alt=""
                    height={60}
                    style={{ objectFit: "contain" }}
                  />
                  <div className="pt-3">
                    <Typography
                      gutterBottom
                      sx={{
                        fontVariant: "small-caps",
                        textTransform: "uppercase",
                        fontWeight: "bold",
                        color: "#0c4da2",
                        pb: 2,
                        fontSize: "20px",
                      }}
                    >
                      Become a Member
                    </Typography>
                  </div>
                </div>
                <Box
                  component="form"
                  noValidate
                  autoComplete="off"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="form-grid">
                    <TextField
                      margin="normal"
                      fullWidth
                      id="outlined-basic"
                      required
                      label="Enter company name"
                      placeholder="Enter company name"
                      {...register("user_member_company_name", {
                        required: "Company name is required",
                      })}
                      error={
                        errors.user_member_company_name &&
                        Boolean(errors.user_member_company_name)
                      }
                      helperText={
                        Boolean(errors.user_member_company_name?.message)
                          ? "Company name is required"
                          : ""
                      }
                      type="text"
                      autoFocus
                      variant="standard"
                    />
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="phone"
                      label="Enter your phone"
                      placeholder="Enter your phone"
                      {...register("user_member_phone", {
                        required: "Phone number is required",
                        pattern: {
                          value: /(^(\+8801|8801|01|008801))[1|3-9]{1}(\d){8}$/,
                          message: "Invalid phone number",
                        },
                      })}
                      error={
                        errors.user_member_phone &&
                        Boolean(errors.user_member_phone)
                      }
                      helperText={
                        Boolean(errors.user_member_phone?.message)
                          ? `${errors.user_member_phone?.message}`
                          : ""
                      }
                      type="number"
                      autoFocus
                      variant="standard"
                    />
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="Owner name"
                      label="Owner name"
                      placeholder="enter owner name"
                      {...register("user_member_company_owner_name", {
                        required: "Owner name is required",
                      })}
                      error={
                        errors.user_member_company_owner_name &&
                        Boolean(errors.user_member_company_owner_name)
                      }
                      helperText={
                        Boolean(errors.user_member_company_owner_name?.message)
                          ? "Owner name is required"
                          : ""
                      }
                      type="text"
                      autoFocus
                      variant="standard"
                    />
                    <FormControl
                      fullWidth
                      variant="standard"
                      error={
                        errors.user_member_zone_id &&
                        Boolean(errors.user_member_zone_id)
                      }
                    >
                      <InputLabel id="demo-simple-select-label">
                        Select zone
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select-error"
                        label="Select zone"
                        variant="standard"
                        required
                        {...register("user_member_zone_id", {
                          required: "Zone is required",
                        })}
                      >
                        <MenuItem value={1}>Dhaka</MenuItem>
                        <MenuItem value={2}>Chittagong</MenuItem>
                        <MenuItem value={3}>Sylhet</MenuItem>
                      </Select>
                      {Boolean(errors.user_member_zone_id?.message) && (
                        <FormHelperText>"Zone is required"</FormHelperText>
                      )}
                    </FormControl>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label="Enter your email"
                      placeholder="Enter your email"
                      {...register("user_member_email", {
                        required: "Email is required",
                        pattern: {
                          value:
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          message: "Invalid email",
                        },
                      })}
                      error={
                        errors.user_member_email &&
                        Boolean(errors.user_member_email)
                      }
                      helperText={
                        Boolean(errors.user_member_email?.message)
                          ? `${errors.user_member_email?.message}`
                          : ""
                      }
                      autoComplete="email"
                      type="email"
                      autoFocus
                      variant="standard"
                    />{" "}
                    <TextField
                      required
                      variant="standard"
                      label="Civil aviation certificate english copy ( max size 5mb )"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      id="outlined-required"
                      type="file"
                      {...register("user_member_civil_aviation_certificate", {
                        required: "Civil aviation certificate is required",
                      })}
                      error={
                        errors.user_member_civil_aviation_certificate &&
                        Boolean(errors.user_member_civil_aviation_certificate)
                      }
                      helperText={
                        Boolean(
                          errors.user_member_civil_aviation_certificate?.message
                        )
                          ? `${errors.user_member_civil_aviation_certificate?.message}`
                          : ""
                      }
                    />
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      {...register("user_member_password", {
                        required: "Password is required",
                      })}
                      error={
                        errors.user_member_password &&
                        Boolean(errors.user_member_password)
                      }
                      helperText={
                        Boolean(errors.user_member_password?.message)
                          ? "Password is required"
                          : ""
                      }
                      label="Enter password"
                      placeholder="Enter password"
                      type={`${showPassword ? "text" : "password"}`}
                      id="password"
                      autoComplete="current-password"
                      variant="standard"
                    />
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      {...register("user_member_confirm_password", {
                        required: "Confirm password is required",
                        validate: (value) => {
                          if (watch("user_member_password") !== value) {
                            return "Password does not match";
                          }
                        },
                      })}
                      error={
                        errors.user_member_confirm_password &&
                        Boolean(errors.user_member_confirm_password)
                      }
                      helperText={
                        Boolean(errors.user_member_confirm_password?.message)
                          ? "Confirm password is required"
                          : ""
                      }
                      label="Enter confirm password"
                      placeholder="Enter confirm password"
                      type={`${showPassword ? "text" : "password"}`}
                      id="password2"
                      autoComplete="current-password"
                      variant="standard"
                    />
                  </div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onClick={() => setShowPassword(!showPassword)}
                        value={showPassword}
                        color="primary"
                      />
                    }
                    label="Show Password"
                  />
                  {loading ? (
                    <Button
                      disabled
                      fullWidth
                      size="small"
                      variant="contained"
                      sx={{ mt: 2, mb: 2, background: "#1113a2" }}
                    >
                      Loading...
                    </Button>
                  ) : (
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      size="small"
                      disableElevation
                      sx={{
                        mt: 2,
                        mb: 2,

                        background: "#1113a2",
                      }}
                      className={classes.signInButton}
                    >
                      Sign Up
                    </Button>
                  )}
                  <Grid container>
                    <Grid item>
                      <Typography color="GrayText">
                        Do you have an account?{" "}
                        <Link to="/login">
                          <span
                            style={{ textDecoration: "underline" }}
                            className="text-main fw-bold "
                          >
                            login
                          </span>
                        </Link>
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </div>
            </Container>
          </div>
        </div>
      </div>
      <EmailVerifiModal
        open={open}
        handleClose={() => setOpen(false)}
        inputData={inputData}
        setLoading={setLoading}
      />
    </>
  );
};

export default Register;
