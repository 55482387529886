import { useMutation, useQuery, useQueryClient } from "react-query";
import { showToast } from "../Toaster/Toaster";
import { HttpClient } from "./utils/http";
import { url } from "../Helpers/utils/Constant";
import { API_ENDPOINTS } from "./utils/api-endpoints";
import { fetchAgencyInfoUpdate } from ".";
import { IResponse } from "./types";
import { IAgencyInfoUpdateApplication } from "../Types/UpdateCompanyInfoTypes";

interface props extends IResponse {
  data?: IAgencyInfoUpdateApplication[];
}

interface ISingleAgencyInfoProps extends IResponse {
  data?: IAgencyInfoUpdateApplication;
}
const postApplication = async (data: FormData) => {
  return await HttpClient.post(`${url}/api/atab/agency-member-info`, data);
};

export function useAgencyInfoUpdateApplication() {
  const queryClient = useQueryClient();
  const { data, mutate, isLoading, isSuccess } = useMutation(postApplication, {
    onSuccess: (data: any) => {
      if (data?.success) {
        showToast(
          `Successfully created agency information update application`,
          "success"
        );
      }
    },
    onError: (error) => {
      const {
        response: { data },
      }: any = error ?? {};

      showToast(`${data.message}`, "error");
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [API_ENDPOINTS.AGENCY_INFO_UPDATE],
      });
    },
  });

  return { mutate, isLoading, isSuccess, data };
}

// get all data
export function useAgencyInfoUpdate(id: number) {
  const { data, isLoading, error } = useQuery<props, Error>(
    [API_ENDPOINTS.AGENCY_INFO_UPDATE],
    () => fetchAgencyInfoUpdate(id),
    {
      refetchOnWindowFocus: false,
    }
  );
  return {
    agencyInfo: data?.data ?? ([] as IAgencyInfoUpdateApplication[]),
    isLoading,
    error,
  };
}

// get single data
export const fetchSingleAgencyInfo = async (id: number) => {
  return await HttpClient.get<any>(
    `${API_ENDPOINTS.SINGLE_AGENCY_INFO_UPDATE}/${id}`
  );
};

export function useSingleAgencyInfo(id: number) {
  const { data, isLoading, error } = useQuery<ISingleAgencyInfoProps, Error>(
    [API_ENDPOINTS.SINGLE_AGENCY_INFO_UPDATE],
    () => fetchSingleAgencyInfo(id),
    {
      refetchOnWindowFocus: false,
    }
  );
  return {
    singleAgencyInfo: data?.data ?? ({} as IAgencyInfoUpdateApplication),
    isLoading,
    error,
  };
}
