import { useQuery } from 'react-query';
import { API_ENDPOINTS } from './utils/api-endpoints';
import {
	IAreaResponse,
	ICitiesResponse,
	IGetAllArea,
	IGetAllCities,
	IGetAllProvince,
	IGetAllSubCities,
	IProvinceResponse,
	ISubCitiesResponse,
} from './types';
import {
	IAddressAreaParams,
	IAddressDistrictParams,
	IAddressThanaParams,
	fetchAddressArea,
	fetchAddressDistrict,
	fetchAddressDivision,
	fetchAddressThana,
} from '.';

export function useDivision() {
	const { data, isLoading, error } = useQuery<IProvinceResponse, Error>(
		[API_ENDPOINTS.DIVISION],
		() => fetchAddressDivision(),
		{ keepPreviousData: true }
	);
	return {
		division: data?.data || ([] as IGetAllProvince[]),
		isLoading,
		error,
	};
}
export function useDistrict(params: IAddressDistrictParams) {
	const { data, isLoading, error } = useQuery<ICitiesResponse, Error>(
		[API_ENDPOINTS.DISTRICT, params.division_id],
		() => fetchAddressDistrict(params),
		{ enabled: !!params.division_id }
	);
	return {
		district: data?.data || ([] as IGetAllCities[]),
		isLoading,
		error,
	};
}
export function useThana(params: IAddressThanaParams) {
	const { data, isLoading, error } = useQuery<ISubCitiesResponse, Error>(
		[API_ENDPOINTS.THANA, params.district_id],
		() => fetchAddressThana(params),
		{ enabled: !!params.district_id }
	);
	return {
		thana: data?.data || ([] as IGetAllSubCities[]),
		isLoading,
		error,
	};
}
export function useArea(params: IAddressAreaParams) {
	const { data, isLoading, error } = useQuery<IAreaResponse, Error>(
		[API_ENDPOINTS.AREA, params.thana_id],
		() => fetchAddressArea(params),
		{ enabled: !!params.thana_id }
	);
	return {
		area: data?.data || ([] as IGetAllArea[]),
		isLoading,
		error,
	};
}
