import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PaymentIcon from "@mui/icons-material/Payment";
import EventIcon from "@mui/icons-material/Event";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import NotificationsIcon from "@mui/icons-material/Notifications";
import LockResetIcon from "@mui/icons-material/LockReset";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import InfoIcon from "@mui/icons-material/Info";
import { ListItem, List } from "@mui/material";
import { NavLink, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import PublishedWithChangesOutlinedIcon from "@mui/icons-material/PublishedWithChangesOutlined";
import CardMembershipTwoToneIcon from "@mui/icons-material/CardMembershipTwoTone";
import BusinessTwoToneIcon from "@mui/icons-material/BusinessTwoTone";
import DisplaySettingsSharpIcon from "@mui/icons-material/DisplaySettingsSharp";
// import ChromeReaderModeOutlinedIcon from "@mui/icons-material/ChromeReaderModeOutlined";
import Groups2Icon from "@mui/icons-material/Groups2";
const ListItems = () => {
  const location = useLocation();
  const currPath = location.pathname.split("/")[1];

  const navList = [
    {
      path: "/",
      pathname: "Dashboard",
      icon: <AdminPanelSettingsIcon fontSize="small" />,
    },
    {
      path: "/new-member-applications",
      pathname: "New member application",
      icon: <InfoIcon fontSize="small" />,
    },
    {
      path: "/renewal-applications",
      pathname: "Renewal application",
      icon: <DisplaySettingsSharpIcon fontSize="small" />,
    },
    {
      path: "/address-change-applications",
      pathname: "Address change application",
      icon: <BusinessTwoToneIcon fontSize="small" />,
    },
    {
      path: "/ownership-change-applications",
      pathname: "Ownership change application",
      icon: <PublishedWithChangesOutlinedIcon fontSize="small" />,
    },
    {
      path: "/duplicate-certificate-application",
      pathname: "Duplicate certificate application",
      icon: <CardMembershipTwoToneIcon fontSize="small" />,
    },
    {
      path: "/agency-info-update-application",
      pathname: "Agency information update application",
      icon: <Groups2Icon fontSize="small" />,
    },
    {
      path: "/id-card-application",
      pathname: "ID card application",
      icon: <InfoIcon fontSize="small" />,
    },
    {
      path: "/certificate",
      pathname: "Certificate",
      icon: <DashboardIcon fontSize="small" />,
    },

    {
      path: "/joined-meeting",
      pathname: "Joined Meeting",
      icon: <MeetingRoomIcon fontSize="small" />,
    },

    {
      path: "/joined-event",
      pathname: "Joined Event",
      icon: <EventIcon fontSize="small" />,
    },
    // {
    //   path: "/proposer-requester",
    //   pathname: "Proposer Requester",
    //   icon: <PaymentIcon fontSize="small" />,
    // },
    // {
    //   path: "/proposer-receiver",
    //   pathname: "Proposer Receiver",
    //   icon: <PaymentIcon fontSize="small" />,
    // },
    {
      path: "/payments",
      pathname: "Payments",
      icon: <PaymentIcon fontSize="small" />,
    },
    {
      path: "/notification",
      pathname: "Notification",
      icon: <NotificationsIcon fontSize="small" />,
    },
    {
      path: "/change-password",
      pathname: "Change Password",
      icon: <LockResetIcon fontSize="small" />,
    },
  ];

  return (
    <>
      <List
        sx={{
          width: "100%",
          maxWidth: 360,
          bgcolor: "background.paper",
          pr: 0,
        }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <Link to="/">
            <img
              style={{ marginLeft: "25%", paddingBottom: "8px" }}
              width={150}
              height={60}
              src={"/logo.png"}
              alt=""
            />
          </Link>
        }
      >
        {navList.map(
          (item, i) => (
            // Array.isArray(item.children) ? (
            //   <Accordion key={item.path + i + 1}>
            //     <AccordionSummary
            //       expandIcon={<ExpandMoreIcon />}
            //       aria-controls='panel1a-content'
            //       id='panel1a-header'
            //     >
            //       <ListItemIcon style={{ minWidth: "33px" }}>
            //         {item.icon}
            //       </ListItemIcon>
            //       <ListItemText primary={`${item.pathname}`} />
            //     </AccordionSummary>
            //     <AccordionDetails>
            //       {item.children.map((childItem, index) => (
            //         <NavLink
            //           key={childItem.path + index + 1}
            //           className={
            //             location.pathname === childItem.path
            //               ? "activeSidebarLink"
            //               : "unActiveLink"
            //           }
            //           to={`${childItem.path}`}
            //         >
            //           <ListItem disablePadding>
            //             <ListItemButton>
            //               <ListItemIcon style={{ minWidth: "33px" }}>
            //                 {childItem.icon}
            //               </ListItemIcon>
            //               <ListItemText primary={`${childItem.pathname}`} />
            //             </ListItemButton>
            //           </ListItem>
            //         </NavLink>
            //       ))}
            //     </AccordionDetails>
            //   </Accordion>
            // ) : (
            <NavLink
              key={item.path + i}
              className={
                `/${currPath}` === item.path
                  ? "activeSidebarLink"
                  : "unActiveLink"
              }
              to={`${item.path}`}
            >
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon style={{ minWidth: "33px" }}>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={`${item.pathname}`} />
                </ListItemButton>
              </ListItem>
            </NavLink>
          )
          // )
        )}
      </List>

      <List style={{ marginTop: `auto` }}>
        <ListItem>
          <ListItemText>
            <div
              style={{
                textAlign: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img src="/trabil.png" alt="" style={{ height: 50 }} />
              </div>
              <div style={{ textAlign: "center" }}>
                <p style={{ fontSize: "0.75rem" }}>
                  Trabill is the best software for your Travel Agency Business.
                </p>
                <a
                  href="https://trabill.app"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Click here to see the software demo
                </a>
                <br />
                <span>Call: +8809638336699</span>
              </div>
            </div>
          </ListItemText>
        </ListItem>
      </List>
    </>
  );
};

export default ListItems;
