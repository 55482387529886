import {
  Box,
  Typography,
  Card,
  Tooltip,
  Avatar,
  CardMedia,
  Button,
  IconButton,
  Grid,
  Container,
} from "@mui/material";
import CardContent from "@mui/material/CardContent";
import { styled } from "@mui/material/styles";
import LockIcon from "@mui/icons-material/Lock";
import ArrowBackTwoToneIcon from "@mui/icons-material/ArrowBackTwoTone";
import { useAuthContext } from "../../Context/AuthContext/AuthContext";
import image from "../../assets/loginImage/bg-cover.png";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { imgUrl } from "../../Helpers/utils/Constant";

const CardCover = styled(Card)(
  ({ theme }) => `
    position: relative;

    .MuiCardMedia-root {
      height: ${theme.spacing(26)};
    }
`
);
const AvatarWrapper = styled(Card)(
  ({ theme }) => `

    position: relative;
    overflow: visible;
    display: inline-block;
    margin-top: -${theme.spacing(9)};
    margin-left: ${theme.spacing(2)};

    .MuiAvatar-root {
      width: ${theme.spacing(16)};
      height: ${theme.spacing(16)};
    }
`
);
function Profile() {
  const { user } = useAuthContext();
  const navigate = useNavigate();
  // const coverImg = '../../assets/loginImage/bg-cover.png';

  return (
    <Container sx={{ mt: 3 }} maxWidth="lg">
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={3}
      >
        <Grid item xs={12} md={10}>
          <Box display="flex" alignItems="center" mb={3}>
            <Tooltip arrow placement="top" title="Go back">
              <IconButton
                onClick={() => navigate(-1)}
                color="primary"
                sx={{ p: 2, mr: 2 }}
              >
                <ArrowBackTwoToneIcon />
              </IconButton>
            </Tooltip>
            <Box>
              <Typography variant="h3" component="h3" gutterBottom>
                Profile
              </Typography>
            </Box>
          </Box>
          <CardCover>
            <CardMedia image={image} />
          </CardCover>
          <AvatarWrapper>
            <Avatar
              variant="rounded"
              src={`${imgUrl}/${user?.user_member_representative_photo}`}
              // alt={user.user_admin_name}
            />
          </AvatarWrapper>

          <Box sx={{ display: "flex", justifyContent: "space-between" }} pl={2}>
            <Box py={2} pl={2}>
              <Typography gutterBottom variant="h4">
                {user.user_member_representative_name}
              </Typography>
            </Box>
            <Box>
              <Link to="/change-password">
                <Button
                  onClick={() => {}}
                  startIcon={<LockIcon />}
                  variant="contained"
                  component="span"
                >
                  Change Password
                </Button>
              </Link>
            </Box>
          </Box>
          <Card>
            <CardContent sx={{ pt: 3 }}>
              <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
                <Typography gutterBottom sx={{ mb: 1.5, fontSize: "18px" }}>
                  Company Name:{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {user.user_member_company_name}
                  </span>
                </Typography>
                <Typography gutterBottom sx={{ mb: 1.5, fontSize: "18px" }}>
                  Phone number:{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {user.user_member_phone}
                  </span>
                </Typography>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
                <Typography gutterBottom sx={{ mb: 1.5, fontSize: "18px" }}>
                  Email:{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {user.user_member_email}
                  </span>
                </Typography>
                <Typography gutterBottom sx={{ mb: 1.5, fontSize: "18px" }}>
                  Status:{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {user.user_member_account_status}
                  </span>
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Profile;
