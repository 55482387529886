import { makeStyles, styled } from "@mui/styles";
import Paper from "@mui/material/Paper";

export const muiStyles = makeStyles({
  underline: {
    "&&&:before": {
      borderBottom: "none",
    },
    "&&:after": {
      borderBottom: "none",
    },
  },
  inputText: {
    background: "rgb(232, 241, 250)",
    borderRadius: "4px",
  },

  signInButton: {
    color: "#fff",
    "&:hover": {
      background: "#1113a2!important",
    },
  },
});

export const styledItem = () => {
  return styled(Paper)(({ theme }: any) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(2),
    boxShadow:
      "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
    borderBottom: "2px solid #1113a2",
  }));
};
