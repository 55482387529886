import {
  CardContent,
  Grid,
  Typography,
  Box,
  Tooltip,
  IconButton,
} from "@mui/material";
import PageLoader from "../../../Components/Spinner/PageLoader";
import { ICompanyProposer } from "../../../rest/types";
import { useState } from "react";
import UpdateCompanyProposer from "../../CompanyUpdateInfo/UpdateCompanyInformation/UpdateCompanyProposer";
import { useAuthContext } from "../../../Context/AuthContext/AuthContext";
import EditIcon from "@mui/icons-material/Edit";
import ModalsWrapper from "../../../Components/Modals/ModalsWrapper";
import PdfAndImageView from "../../../Components/pdfAndImageView/PdfAndImageView";
import { CommonTypoGraphy } from "../../../Components/pdfAndImageView/CommonTypoGraphy";
type IProps = {
  data: ICompanyProposer[];
  loading: boolean;
};

const CardCompanyOProposer = ({ data, loading }: IProps) => {
  const { user } = useAuthContext();
  const [toggle, setToggle] = useState(false);
  const [open, setOpen] = useState<boolean>(false);
  const [imageFile, setImageFile] = useState<string | null>("");
  return (
    <>
      {!loading ? (
        <>
          {data.map((item: ICompanyProposer, index: number) => {
            return toggle ? (
              <Box key={index}>
                <UpdateCompanyProposer
                  memberId={user.user_member_id}
                  companyProposer={item}
                  setToggle={setToggle}
                />
              </Box>
            ) : (
              <Box key={index}>
                <CardContent sx={{ position: "relative" }}>
                  <Grid
                    container
                    pt={2}
                    spacing={{ xs: 2, md: 2 }}
                    columns={{ xs: 4, sm: 8, md: 12 }}
                  >
                    <Grid item xs={2} sm={4} md={4}>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        display="inline"
                        sx={{ pr: 1 }}
                        gutterBottom
                      >
                        Proposer request company name:
                      </Typography>
                      {item.proposer_request_to_company_name}
                    </Grid>
                    <Grid item xs={2} sm={4} md={4}>
                      <CommonTypoGraphy
                        name="View proposer seal and signature"
                        onClick={() => {
                          setOpen(true);
                          setImageFile(item?.proposer_seal_signature);
                        }}
                      />
                    </Grid>
                    {/* <Grid item xs={2} sm={4} md={4}>
                      <CommonTypoGraphy
                        name="View proposer signature"
                        onClick={() => {
                          setOpen(true);
                          setImageFile(item?.proposer_signature);
                        }}
                      />
                    </Grid> */}

                    {/* <Grid item xs={2} sm={4} md={4}>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        display="inline"
                        sx={{ pr: 1 }}
                        gutterBottom
                      >
                        Proposer request date:
                      </Typography>
                      {moment(item.proposer_request_date).format("MMM Do YY")}
                    </Grid> */}
                    {/* <Grid item xs={2} sm={4} md={4}>
									<Typography
										variant="body1"
										fontWeight="bold"
										color="text.primary"
										display="inline"
										sx={{ pr: 1 }}
										gutterBottom
									>
										Proposer request update date:
									</Typography>
									{moment(item.proposer_request_update_date).format(
										'MMM Do YY'
									)}
								</Grid> */}
                  </Grid>
                  {!user?.is_applied ? (
                    <Box sx={{ position: "absolute", top: 0, right: 20 }}>
                      <Tooltip title="Update" sx={{ mr: 2 }}>
                        <IconButton onClick={() => setToggle(!toggle)}>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  ) : (
                    ""
                  )}
                </CardContent>
              </Box>
            );
          })}
        </>
      ) : (
        <PageLoader />
      )}

      <>
        <ModalsWrapper
          modalData={{ title: "View document" }}
          setShowModal={setOpen}
          showModal={open}
        >
          <PdfAndImageView file={imageFile} />
        </ModalsWrapper>
      </>
    </>
  );
};

export default CardCompanyOProposer;
