import { useAuthContext } from "../../Context/AuthContext/AuthContext";
import { imgUrl } from "../../Helpers/utils/Constant";
import {
  Card,
  CardHeader,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  Avatar,
  Typography,
} from "@mui/material";

import TableNoDataMessage from "../../Components/TableMessage/TableNoDataMessage";
import TableLoader from "../../Components/Spinner/TableLoader";
import { getStatusLabel } from "../../Utils/Comibined";
import { useJoinedMeetingQuery } from "./api/JoinedEndPoint";
import { useState } from "react";
import ModalsWrapper from "../../Components/Modals/ModalsWrapper";
import PdfAndImageView from "../../Components/pdfAndImageView/PdfAndImageView";

const JoinedMeeting = () => {
  const { user } = useAuthContext();
  const { allJoinedMeeting, isLoading } = useJoinedMeetingQuery(
    user.user_member_id
  );
  const [open, setOpen] = useState<boolean>(false);
  const [imageFile, setImageFile] = useState<string | null>("");

  return (
    <>
      {" "}
      <Card sx={{ mt: 5 }}>
        <CardHeader
          title={
            <Typography
              sx={{ fontWeight: 700, lineHeight: 1.34, fontSize: 17 }}
            >
              All joined meeting
            </Typography>
          }
        />
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>ID</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Photo</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Tittle</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Status</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>QR Code</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableLoader />
              ) : (
                <>
                  {allJoinedMeeting.length ? (
                    <>
                      {allJoinedMeeting.map((sMeeting) => {
                        return (
                          <TableRow key={sMeeting.member_meeting_id}>
                            <TableCell size="small">
                              {sMeeting.member_meeting_id}
                            </TableCell>
                            <TableCell align="center" size="small">
                              <Avatar
                                alt=""
                                src={`${imgUrl}/${sMeeting.member_meeting_img}`}
                                sx={{ width: 35, height: 35 }}
                              />
                            </TableCell>
                            <TableCell size="small">
                              {sMeeting.member_meeting_title}
                            </TableCell>
                            <TableCell size="small">
                              {getStatusLabel(sMeeting.member_meeting_status)}
                            </TableCell>{" "}
                            <TableCell
                              size="small"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setOpen(true);
                                setImageFile(
                                  `member_meeting_qr/${sMeeting?.member_meeting_qr_code_file}`
                                );
                              }}
                            >
                              View
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </>
                  ) : (
                    <TableNoDataMessage />
                  )}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      <>
        <ModalsWrapper
          modalData={{ title: "View" }}
          setShowModal={setOpen}
          showModal={open}
        >
          <PdfAndImageView file={imageFile} />
        </ModalsWrapper>
      </>
    </>
  );
};

export default JoinedMeeting;
