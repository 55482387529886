import { useQuery } from "react-query";
import { API_ENDPOINTS } from "../../../rest/utils/api-endpoints";
import { HttpClient } from "../../../rest/utils/http";
import {
  IIdCard,
  IIdCardResponse,
  ISingleIdCard,
  ISingleIdCardResponse,
} from "../../../Types/IdCard";

const fetchAllIdCard = async (id: number) => {
  const fetchData = await HttpClient.get<IIdCardResponse>(
    `${API_ENDPOINTS.ALL_ID_CARD}=${id}`
  );
  return fetchData;
};
const fetchSingleIdCard = async (id: number) => {
  const fetchData = await HttpClient.get<ISingleIdCardResponse>(
    `${API_ENDPOINTS.SINGLE_ID_CARD}/${id}`
  );
  return fetchData;
};
// query
export function useAllIdCardQuery(id: number) {
  const { data, isLoading, error } = useQuery<IIdCardResponse, Error>(
    [API_ENDPOINTS.ALL_ID_CARD, { id }],
    () => fetchAllIdCard(id),
    {
      refetchOnWindowFocus: false,
    }
  );
  return {
    idCard: data?.data ?? ([] as IIdCard[]),
    isLoading,
    error,
  };
}
export function useSingleIDCardQuery(id: number) {
  const { data, isLoading, error } = useQuery<ISingleIdCardResponse, Error>(
    [API_ENDPOINTS.SINGLE_ID_CARD, { id }],
    () => fetchSingleIdCard(id),
    {
      refetchOnWindowFocus: false,
    }
  );
  return {
    singleIdCard: data?.data ?? ({} as ISingleIdCard),
    isLoading,
    error,
  };
}
