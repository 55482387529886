import React, { useState } from "react";
import {
  Typography,
  Divider,
  Card,
  Box,
  Tooltip,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useAuthContext } from "../../../Context/AuthContext/AuthContext";
import CardCompanyDocument from "../card-components/CardCompanyDocument";
import AddCompanyDocument from "../components/AddCompanyDocument";
import { useCompanyDocument } from "../../../rest/useCompanyInfo";
import UpdateCompanyDocument from "../../CompanyUpdateInfo/UpdateCompanyInformation/UpdateCompanyDocument";
const CompanyDocumentIndex = () => {
  const { user } = useAuthContext();
  const { companyDocument, isLoading: companyDocumentLoading } =
    useCompanyDocument();
  const [toggle, setToggle] = useState(false);

  return (
    <div>
      <Card>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h3" sx={{ p: 2 }}>
            Company document
          </Typography>

          <>
            {!user?.is_applied && companyDocument.company_ownership_status ? (
              <Tooltip title="Update" sx={{ mr: 2 }}>
                <IconButton onClick={() => setToggle(!toggle)}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            ) : (
              ""
            )}
          </>
        </Box>
        <Divider light />

        {toggle ? (
          <UpdateCompanyDocument
            companyDocument={companyDocument}
            memberId={user.user_member_id}
          />
        ) : (
          <>
            {Object.keys(companyDocument).length > 0 ? (
              <CardCompanyDocument
                data={companyDocument}
                loading={companyDocumentLoading}
              />
            ) : (
              <AddCompanyDocument />
            )}
          </>
        )}
      </Card>
    </div>
  );
};

export default CompanyDocumentIndex;
