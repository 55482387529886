import { useState } from "react";
import logo from "../../assets/loginImage/logo.png";
import {
  FaFacebookF,
  FaLinkedin,
  FaRegBell,
  FaRegEnvelope,
  FaRegStar,
  FaRegUser,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import { HiOutlineDocumentArrowDown } from "react-icons/hi2";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const style = `
  @keyframes colorShift {
    0% { color: #ff0000; } /* Solid Red */
    50% { color: #00ff00; } /* Solid Green */
    100% { color: #ff0000; } /* Solid Red */
  }
  .color-animation {
    animation: colorShift 3s linear infinite;
  }
`;

  return (
    <header className="bg-[#0d59b5] xl:py-1 ">
      <style>{style}</style>
      {/* top nav  */}
      <div className=" hidden container mx-auto lg:px-10 2xl:px-48 xl:py-7 xl:flex xl:items-center xl:justify-start ">
        {/* social icon  */}
        <ul className="flex items-center gap-10 text-white relative -top-4 text-xl">
          <li>
            <a
              href="https://www.facebook.com/ATABangladesh"
              target="_blank"
              className="hover:text-amber-400"
              rel="noreferrer"
            >
              <FaFacebookF />
            </a>
          </li>
          <li>
            <a
              href="https://x.com/ATAB_5611219"
              target="_blank"
              className="hover:text-amber-400"
              rel="noreferrer"
            >
              <FaTwitter />
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/in/ataborgbd"
              target="_blank"
              className="hover:text-amber-400"
              rel="noreferrer"
            >
              <FaLinkedin />
            </a>
          </li>
          <li>
            <a
              href="https://www.youtube.com/channel/UC9DrptDdtV6nsT4wPMnmlOw"
              target="_blank"
              className="hover:text-amber-400"
              rel="noreferrer"
            >
              <FaYoutube />
            </a>
          </li>
        </ul>

        <ul className="relative -top-4 ml-24 flex items-center gap-6 font-medium text-sm">
          <li>
            <a
              href="https://atab.org.bd/forms"
              target="_blank"
              className="flex gap-2 items-center text-white"
              rel="noreferrer"
            >
              <span className="text-amber-500 text-2xl">
                <HiOutlineDocumentArrowDown />
              </span>
              Download Forms
            </a>
          </li>
          <li>
            <a
              href="https://atab.org.bd/notice"
              target="_blank"
              className="flex gap-2 items-center text-white"
              rel="noreferrer"
            >
              <span className="text-amber-500 text-2xl">
                <FaRegBell />
              </span>
              Notice
            </a>
          </li>
          <li>
            <a
              href="https://atab.org.bd/message"
              target="_blank"
              className="flex gap-2 items-center text-white"
              rel="noreferrer"
            >
              <span className="text-amber-500 text-2xl">
                <FaRegEnvelope />
              </span>
              Message for Members
            </a>
          </li>
          <li>
            <a
              href="https://www.member.atab.services/login"
              target="_blank"
              className="flex gap-2 items-center text-white"
              rel="noreferrer"
            >
              <span className="text-amber-500 text-2xl">
                <FaRegUser />
              </span>
              <span className="color-animation">Member Login</span>
            </a>
          </li>
          <li>
            <a
              href="https://www.member.atab.services/registration"
              target="_blank"
              className="flex gap-2 items-center text-white"
              rel="noreferrer"
            >
              <span className="text-amber-500 text-2xl">
                <FaRegStar />
              </span>
              <span className="color-animation">Become a Member</span>
            </a>
          </li>
        </ul>
      </div>

      {/* bottom nav */}
      <nav className="relative bg-white shadow lg:ml-10 2xl:ml-80 ">
        <div className="px-6 xl:px-0 py-4 xl:py-2 mx-auto xl:flex xl:justify-start xl:items-center">
          <div className="flex items-center justify-between">
            <a href="https://www.atab.org.bd" className="size-fit">
              <img
                className="w-auto h-6 sm:h-7 xl:h-auto xl:relative xl:-mt-10 xl:bg-white xl:rounded-t-lg xl:p-4"
                src={logo}
                alt="Logo"
              />
            </a>
            {/* Mobile menu button */}
            <div className="flex xl:hidden">
              <button
                type="button"
                onClick={handleToggleMenu}
                className="text-gray-500 hover:text-gray-600 focus:outline-none focus:text-gray-600"
                aria-label="toggle menu"
              >
                {!isOpen ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4 8h16M4 16h16"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
          {/* Mobile Menu open: "block", Menu closed: "hidden" */}
          <div
            className={`absolute inset-x-0 z-20 w-full px-6 py-4 transition-all duration-300 ease-in-out bg-white xl:mt-0 xl:p-0 xl:top-0 xl:relative xl:w-auto xl:opacity-100 xl:translate-x-0 xl:flex xl:items-center ${
              isOpen
                ? "translate-x-0 opacity-100"
                : "opacity-0 -translate-x-full"
            }`}
          >
            <div className="flex flex-col xl:flex-row xl:mx-6">
              <a
                className="my-2 text-gray-700 transition-colors duration-300 transform hover:text-amber-400 font-semibold xl:mx-4 xl:my-0 xl:hidden flex items-center gap-3"
                href="https://atab.org.bd/forms"
                target="_blank"
                rel="noreferrer"
              >
                <span className="text-amber-500 text-2xl">
                  <HiOutlineDocumentArrowDown />
                </span>
                Download Forms
              </a>
              <a
                className="my-2 text-gray-700 transition-colors duration-300 transform hover:text-amber-400 font-semibold xl:mx-4 xl:my-0 xl:hidden flex items-center gap-3"
                href="https://atab.org.bd/notice"
                target="_blank"
                rel="noreferrer"
              >
                <span className="text-amber-500 text-2xl">
                  <FaRegBell />
                </span>
                Notice
              </a>
              <a
                className="my-2 text-gray-700 transition-colors duration-300 transform hover:text-amber-400 font-semibold xl:mx-4 xl:my-0 xl:hidden flex items-center gap-3"
                href="https://atab.org.bd/message"
                target="_blank"
                rel="noreferrer"
              >
                <span className="text-amber-500 text-2xl">
                  <FaRegEnvelope />
                </span>
                Message for Members
              </a>
              <a
                className="my-2 text-gray-700 transition-colors duration-300 transform hover:text-amber-400 font-semibold xl:mx-4 xl:my-0 xl:hidden flex items-center gap-3"
                href="https://www.member.atab.services/login"
                target="_blank"
                rel="noreferrer"
              >
                <span className="text-amber-500 text-2xl">
                  <FaRegUser />
                </span>
                <span className="color-animation">Member Login</span>
              </a>
              <a
                className="my-2 text-gray-700 transition-colors duration-300 transform hover:text-amber-400 font-semibold xl:mx-4 xl:my-0 xl:hidden flex items-center gap-3"
                href="https://www.member.atab.services/registration"
                target="_blank"
                rel="noreferrer"
              >
                <span className="text-amber-500 text-2xl">
                  <FaRegStar />
                </span>
                <span className="color-animation">Become a Member</span>
              </a>

              {/* bottom navbar  */}
              <a
                className="my-2 text-gray-700 transition-colors duration-300 transform hover:text-amber-400 font-semibold xl:mx-4 xl:my-0"
                href="/member-directory"
              >
                ATAB
              </a>
              <a
                className="my-2 text-gray-700 transition-colors duration-300 transform hover:text-amber-400 font-semibold xl:mx-4 xl:my-0"
                href="/member-directory"
              >
                Membership
              </a>
              <a
                className="my-2 text-gray-700 transition-colors duration-300 transform hover:text-amber-400 font-semibold xl:mx-4 xl:my-0"
                href="/member-directory"
              >
                Directory
              </a>
              <a
                className="my-2 text-gray-700 transition-colors duration-300 transform hover:text-amber-400 font-semibold xl:mx-4 xl:my-0"
                href="/member-directory"
              >
                Executive Panel
              </a>
              <a
                className="my-2 text-gray-700 transition-colors duration-300 transform hover:text-amber-400 font-semibold xl:mx-4 xl:my-0"
                href="/member-directory"
              >
                Election Board
              </a>
              <a
                className="my-2 text-gray-700 transition-colors duration-300 transform hover:text-amber-400 font-semibold xl:mx-4 xl:my-0"
                href="/member-directory"
              >
                ATTI
              </a>
              <a
                className="my-2 text-gray-700 transition-colors duration-300 transform hover:text-amber-400 font-semibold xl:mx-4 xl:my-0"
                href="/member-directory"
              >
                BITTE
              </a>

              <a
                className="my-2 text-gray-700 transition-colors duration-300 transform hover:text-amber-400 font-semibold xl:mx-4 xl:my-0"
                href="/member-directory"
              >
                More
              </a>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
