import { CardContent, Grid, Typography } from "@mui/material";
import PageLoader from "../../../Components/Spinner/PageLoader";
import moment from "moment";
import { ICompanyCivilAviation } from "../../../rest/types";
import { useState } from "react";
import ModalsWrapper from "../../../Components/Modals/ModalsWrapper";
import PdfAndImageView from "../../../Components/pdfAndImageView/PdfAndImageView";
import { CommonTypoGraphy } from "../../../Components/pdfAndImageView/CommonTypoGraphy";

type IProps = {
  data: ICompanyCivilAviation;
  loading: boolean;
};

const CardCivilAviation = ({ data, loading }: IProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [imageFile, setImageFile] = useState<string | null>("");
  return (
    <>
      {!loading ? (
        <CardContent>
          <Grid
            container
            pt={2}
            spacing={{ xs: 2, md: 2 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            <Grid item xs={2} sm={4} md={4}>
              <Typography
                variant="body1"
                fontWeight="bold"
                color="text.primary"
                display="inline"
                sx={{ pr: 1 }}
                gutterBottom
              >
                Certificate number:
              </Typography>
              {data.certificate_number
                ? data.certificate_number
                : "Not provided"}
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <CommonTypoGraphy
                name="View certificate"
                onClick={() => {
                  setOpen(true);
                  setImageFile(data?.certificate_file);
                }}
              />
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <Typography
                variant="body1"
                fontWeight="bold"
                color="text.primary"
                display="inline"
                sx={{ pr: 1 }}
                gutterBottom
              >
                Certificate issue date:
              </Typography>
              {data.certificate_issue_date
                ? moment(data.certificate_issue_date).format("MMM Do YYYY")
                : "Not provided"}
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <Typography
                variant="body1"
                fontWeight="bold"
                color="text.primary"
                display="inline"
                sx={{ pr: 1 }}
                gutterBottom
              >
                Certificate expiry date:
              </Typography>
              {data.certificate_expiry_date
                ? moment(data.certificate_expiry_date).format("MMM Do YYYY")
                : "Not provided"}
            </Grid>
          </Grid>
        </CardContent>
      ) : (
        <PageLoader />
      )}
      <>
        <ModalsWrapper
          modalData={{ title: "View document" }}
          setShowModal={setOpen}
          showModal={open}
        >
          <PdfAndImageView file={imageFile} />
        </ModalsWrapper>
      </>
    </>
  );
};

export default CardCivilAviation;
