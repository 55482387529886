import { Dispatch, SetStateAction, useEffect } from "react";
import { Box, Grid, TextField, Button, Divider } from "@mui/material";
import { useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useAuthContext } from "../../Context/AuthContext/AuthContext";
import { useChangeOwnershipMutation } from "../../rest/useChangeOwnership ";
import { useCompanyOwnerQuery } from "../../rest/useCompanyInfo";
import { ICompanyOwnerList } from "../../rest/types";

type IProps = {
  handelClose: Dispatch<SetStateAction<boolean>>;
};

const CreateOwnershipApplication = ({ handelClose }: IProps) => {
  const { user } = useAuthContext();
  const { register, handleSubmit } = useForm();
  const { isLoading, isSuccess, mutate } = useChangeOwnershipMutation();
  const { companyOwnerData } = useCompanyOwnerQuery();

  useEffect(() => {
    if (isSuccess) {
      handelClose(false);
    }
  }, [isSuccess, handelClose]);
  const onSubmit = async (data: any) => {
    const formData = new FormData();
    Object.keys(data).forEach((item) => {
      if (typeof data[item] === "object") {
        formData.append(item, data[item][0]);
      } else {
        formData.append(item, data[item]);
      }
    });
    formData.append(
      "ownership_change_application_member_id",
      user.user_member_id?.toString()
    );
    // console.table(Object.fromEntries(formData));
    mutate(formData);
  };

  return (
    <>
      <Box component="form" onSubmit={handleSubmit(onSubmit)} p={2}>
        <Grid container spacing={2} mb={2}>
          <Grid xs={12} md={4} lg={4} item>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-required-label">
                Select a previous company owner{" "}
                <span style={{ color: "red" }}>*</span>
              </InputLabel>
              <Select
                labelId="demo-simple-select-required-label"
                id="demo-simple-select-required"
                required
                label="Select a previous company owner *"
                {...register("ownership_change_application_prev_owner")}
              >
                {companyOwnerData?.length === 0 && (
                  <MenuItem disabled>Not found</MenuItem>
                )}
                {companyOwnerData?.map((company: ICompanyOwnerList) => (
                  <MenuItem value={company?.company_owner_id}>
                    {company?.company_owner_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Divider sx={{ m: 2, width: "98%" }}>
            PRESENT OWNER INFORMATION
          </Divider>
          <Grid xs={12} md={4} lg={4} item>
            <TextField
              fullWidth
              label="New company owner name"
              required
              id="outlined-required"
              type="text"
              {...register("company_owner_name")}
            />
          </Grid>
          <Grid xs={12} md={4} lg={4} item>
            <TextField
              fullWidth
              label="Company owner NID"
              id="outlined-required"
              type="text"
              {...register("company_owner_nid")}
            />
          </Grid>
          <Grid xs={12} md={4} lg={4} item>
            <TextField
              fullWidth
              label="Company owner phone"
              id="outlined-required"
              type="text"
              {...register("company_owner_phone")}
            />
          </Grid>
          <Grid xs={12} md={4} lg={4} item>
            <TextField
              fullWidth
              label="Company owner email"
              id="outlined-required"
              type="email"
              {...register("company_owner_email")}
            />
          </Grid>
          <Grid xs={12} md={4} lg={4} item>
            <TextField
              fullWidth
              label="Company owner designation"
              id="outlined-required"
              type="text"
              {...register("company_owner_designation")}
            />
          </Grid>
          <Grid xs={12} md={4} lg={4} item>
            <TextField
              fullWidth
              label="Company owner address"
              id="outlined-required"
              type="text"
              {...register("company_owner_address")}
            />
          </Grid>
          <Grid xs={12} md={4} lg={4} item>
            <TextField
              fullWidth
              label="Company owner photo ( max size 5mb )"
              required
              id="outlined-required"
              type="file"
              InputLabelProps={{
                shrink: true,
              }}
              {...register("company_owner_photo")}
            />
          </Grid>

          <Grid xs={12} md={4} lg={4} item>
            <TextField
              fullWidth
              label="Company owner NID file ( max size 5mb )"
              required
              id="outlined-required"
              type="file"
              InputLabelProps={{
                shrink: true,
              }}
              {...register("company_owner_nid_file")}
            />
          </Grid>
          <Grid xs={12} md={4} lg={4} item>
            <TextField
              fullWidth
              label="Company owner bio-data ( max size 5mb )"
              id="outlined-required"
              type="file"
              InputLabelProps={{
                shrink: true,
              }}
              {...register("company_owner_biodata")}
            />
          </Grid>
          <Grid xs={12} md={4} lg={4} item>
            <TextField
              fullWidth
              label="Company owner passport ( max size 5mb )"
              id="outlined-required"
              type="file"
              InputLabelProps={{
                shrink: true,
              }}
              {...register("company_owner_passport")}
            />
          </Grid>
          <Grid xs={12} md={4} lg={4} item>
            <TextField
              fullWidth
              label="Company owner signature ( max size 5mb )"
              required
              id="outlined-required"
              type="file"
              InputLabelProps={{
                shrink: true,
              }}
              {...register("company_owner_signature")}
            />
          </Grid>
          <Divider sx={{ m: 2, width: "98%" }}>Company owner document</Divider>
          <Grid xs={12} md={4} lg={4} item>
            <TextField
              fullWidth
              label="Mocat Approval Letter ( max size 5mb )"
              title="Mocat certificate file"
              required
              id="outlined-required"
              type="file"
              InputLabelProps={{
                shrink: true,
              }}
              {...register(
                "ownership_change_application_mocat_approval_copy_file"
              )}
            />
          </Grid>
          <Grid xs={12} md={4} lg={4} item>
            <TextField
              fullWidth
              label="Mocat certificate english file ( max size 5mb )"
              title="Mocat certificate english file"
              required
              id="outlined-required"
              type="file"
              InputLabelProps={{
                shrink: true,
              }}
              {...register(
                "ownership_change_application_mocat_certificate_english_copy"
              )}
            />
          </Grid>
          <Grid xs={12} md={4} lg={4} item>
            <TextField
              fullWidth
              label="Trade license ( max size 5mb )"
              title="Trade license"
              required
              id="outlined-required"
              type="file"
              InputLabelProps={{
                shrink: true,
              }}
              {...register("ownership_change_application_trade_license")}
            />
          </Grid>
          <Grid xs={12} md={4} lg={4} item>
            <TextField
              fullWidth
              label=" Tax certificate ( max size 5mb )"
              title="Tax certificate"
              required
              id="outlined-required"
              type="file"
              InputLabelProps={{
                shrink: true,
              }}
              {...register("ownership_change_application_tax_certificate")}
            />
          </Grid>
          <Grid xs={12} md={4} lg={4} item>
            <TextField
              fullWidth
              label="Change deed ( max size 5mb )"
              title="Change deed"
              required
              id="outlined-required"
              type="file"
              InputLabelProps={{
                shrink: true,
              }}
              {...register(
                "ownership_change_application_ownership_change_deed"
              )}
            />
          </Grid>
          <Grid xs={12} md={4} lg={4} item>
            <TextField
              fullWidth
              label="Forwarding letter ( max size 5mb )"
              title="Forwarding letter"
              required
              id="outlined-required"
              type="file"
              InputLabelProps={{
                shrink: true,
              }}
              {...register("ownership_change_application_forwarding_letter")}
            />
          </Grid>
        </Grid>
        <Box textAlign={"end"} mt={5}>
          {!isLoading ? (
            <Button
              type="submit"
              // fullWidth
              variant="contained"
              disableElevation
            >
              Submit
            </Button>
          ) : (
            <LoadingButton
              loading
              loadingIndicator="Loading..."
              variant="contained"
              // fullWidth
              disabled
            >
              <span>Loading...</span>
            </LoadingButton>
          )}
        </Box>
      </Box>
    </>
  );
};

export default CreateOwnershipApplication;
