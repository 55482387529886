import React from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { Box } from "@mui/system";
import { CircularProgress } from "@mui/material";

const TableLoader = () => {
  return (
    <>
      <TableRow>
        <TableCell colSpan={100}>
          <Box sx={{ textAlign: "center", mt: 3 }}>
            <CircularProgress />
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
};

export default TableLoader;
