import { useState, useCallback } from "react";
import { IChangeAddress, IResponse, ISingleAddressChange } from "./types";
import { showToast } from "../Toaster/Toaster";
import { fetchAddressChange, fetchSingleAddress, postAddressChange } from ".";

export type IChangeParams = {
  address_change_application_member_id: number;
  address_change_application_status?: string;
  address_change_application_from_date?: string;
  address_change_application_to_date?: string;
};

export const useAddressChangeQuery = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<any>({});
  const [data, setData] = useState<IChangeAddress[]>([] as IChangeAddress[]);

  const fetchData = useCallback(async (params: IChangeParams) => {
    try {
      setLoading(true);
      const response = await fetchAddressChange(params);
      if (response.success) {
        setData(response.data!);
      }
    } catch (error: any) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    loading,
    data,
    error,
    fetchData,
  };
};
export const useSingleAddressQuery = (id: string) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>({});
  const [data, setData] = useState<ISingleAddressChange>(
    {} as ISingleAddressChange
  );

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await fetchSingleAddress(id);
      if (response.success) {
        setData(response.data!);
      }
    } catch (error: any) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [id]);

  return {
    loading,
    data,
    error,
    fetchData,
  };
};
export const useAddressChangeMutation = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>({});
  const [data, setData] = useState<IResponse>({} as IResponse);

  const createData = useCallback(async (formData: FormData) => {
    try {
      setLoading(true);
      const response = await postAddressChange(formData);
      if (response.success) {
        setData(response);
        showToast(`Successfully changed address`, "success");
      }
    } catch (error: any) {
      setError(error);
      showToast(`${error?.response?.data?.message}`, "error");
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    loading,
    data,
    error,
    createData,
  };
};
