import {
  Button,
  Checkbox,
  CssBaseline,
  TextField,
  Typography,
} from "@mui/material";
import { Container } from "@mui/system";
import { FormControlLabel } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { muiStyles } from "../../Utils/Styles";
import axios from "axios";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  AUTH_USER_SUCCESS,
  AUTH_USER_FAILED,
  url,
} from "../../Helpers/utils/Constant";
import { showToast } from "../../Toaster/Toaster";
import { useAuthContext } from "../../Context/AuthContext/AuthContext";
import { IFormInput } from "../../Types/commonTypes";
import { setCookie } from "nookies";

const useStyles = muiStyles;
const Login = () => {
  const classes = useStyles();
  const { dispatch, isLoading, setIsLoading } = useAuthContext();
  const { register, handleSubmit } = useForm<IFormInput>();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<IFormInput> = async (body) => {
    try {
      setIsLoading?.(true);
      const { data } = await axios.post(`${url}/api/auth/member/login`, body);

      if (data.success) {
        showToast(`Successfully login`, "success");
        dispatch?.({
          type: AUTH_USER_SUCCESS,
          payload: data.data,
        });
        setIsLoading?.(false);
        setCookie(null, "atab_ua", data.token);
        navigate("/");
      }
    } catch (err: any) {
      showToast(
        `Log in failed! \n ${err.response?.data?.message as string}`,
        "error"
      );
      dispatch?.({ type: AUTH_USER_FAILED });
    } finally {
      setIsLoading?.(false);
    }
  };

  return (
    <>
      <div className="login-page">
        <div className="login-wrapper">
          <div>
            <div className="login-left-col">
              <video autoPlay muted loop height={"100%"}>
                <source
                  src={
                    "https://m360ict.s3.ap-south-1.amazonaws.com/biha-files/logo/login-video.mp4"
                  }
                  type="video/mp4"
                />
              </video>
            </div>
          </div>

          <div className="login-right-col">
            <Container maxWidth="sm" component="main">
              <CssBaseline />
              <div className="right-top-div">
                <img src="/logo.png" alt="" />

                <div className="pt-3">
                  <Typography
                    gutterBottom
                    sx={{
                      fontVariant: "small-caps",
                      textTransform: "uppercase",
                      fontWeight: "bold",
                      color: "#0c4da2",
                      fontSize: "30px",
                    }}
                  >
                    Member login
                  </Typography>
                </div>
              </div>
              <Box>
                <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    label="Enter your email address"
                    placeholder="Enter your email address"
                    {...register("email")}
                    type="text"
                    autoFocus
                    variant="standard"
                  />
                  <TextField
                    margin="normal"
                    required
                    sx={{ pt: 1 }}
                    fullWidth
                    id="password"
                    label="Enter your password"
                    placeholder="Enter your password"
                    {...register("password")}
                    type={`${showPassword ? "text" : "password"}`}
                    autoFocus
                    variant="standard"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        onClick={() => setShowPassword(!showPassword)}
                        value={showPassword}
                        color="primary"
                      />
                    }
                    label="Show Password"
                  />
                  {isLoading ? (
                    <Button
                      disabled
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2, background: "#1113a2" }}
                    >
                      Loading...
                    </Button>
                  ) : (
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      disableElevation
                      sx={{
                        mt: 3,
                        mb: 2,
                        py: 1,
                        background: "#1113a2",
                      }}
                      className={classes.signInButton}
                    >
                      Sign In
                    </Button>
                  )}

                  <Grid container>
                    <Grid item xs={12} sm={6} md={6}>
                      <Link to="/forget-password">
                        <Typography className="text-main">
                          Forgot password?
                        </Typography>
                      </Link>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <Link to="/registration">
                        <Typography className="text-main">
                          {"Don't have an account? Sign Up"}
                        </Typography>
                      </Link>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
