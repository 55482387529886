import { useState } from "react";
import {
  IAdmissionDATA,
  IAdmissionResponse,
  ICompanyAssociation,
  ICompanyAssociationResponse,
  ICompanyBusinessLicense,
  ICompanyBusinessLicenseResponse,
  ICompanyCivilAviation,
  ICompanyCivilAviationResponse,
  ICompanyDetails,
  ICompanyDocument,
  ICompanyDocumentResponse,
  ICompanyLicense,
  ICompanyLicenseResponse,
  ICompanyOwnerList,
  ICompanyProposer,
  ICompanyProposerResponse,
  ICompanyRepresentativeDetails,
  ICompanyRepresentativeDetailsResponse,
} from "./types";
import {
  fetchAdmissionData,
  fetchCompanyAssociation,
  fetchCompanyBusinessLicense,
  fetchCompanyCivilAviation,
  fetchCompanyDetails,
  fetchCompanyDocument,
  fetchCompanyLicense,
  fetchCompanyOwner,
  fetchCompanyProposer,
  fetchOwnerDetails,
  postCompanyAssociation,
  postCompanyBusinessLicense,
  postCompanyCivilAviation,
  postCompanyDocument,
  postCompanyLicense,
  postCompanyProposer,
} from ".";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { API_ENDPOINTS } from "./utils/api-endpoints";
import { showToast } from "../Toaster/Toaster";
import { addOwner } from "../ReactQuery/Mutations/CompanyOwnerAddMutation";

export function useCompanyDetailsQuery() {
  const { data, isLoading, error } = useQuery(
    [API_ENDPOINTS.COMPANY_DETAILS],
    () => fetchCompanyDetails(),
    {
      refetchOnWindowFocus: false,
    }
  );

  return {
    companyData: data?.data ?? ({} as ICompanyDetails),
    isLoading,
    error,
  };
}

//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState<any>({});
//   // const [data, setData] = useState<ICompanyDetails>({} as ICompanyDetails);

// const { data, isLoading };

//   const fetchData = useCallback(async () => {
//     try {
//       setLoading(true);
//       const response = await fetchCompanyDetails(id);
//       if (response.success) {
//         setData(response.data!);
//       }
//     } catch (error: any) {
//       setError(error);
//     } finally {
//       setLoading(false);
//     }
//   }, [id]);

//   return {
//     loading,
//     data,
//     error,
//     fetchData,
//   };
// };

export function useOwnerDetailsQuery(id: string) {
  const { data, isLoading, error } = useQuery<
    ICompanyRepresentativeDetailsResponse,
    Error
  >(
    [API_ENDPOINTS.COMPANY_REPRESENTATIVE, { id }],
    () => fetchOwnerDetails(id as string),
    {
      refetchOnWindowFocus: false,
    }
  );
  return {
    companyRepresentativeInfo:
      data?.data ?? ({} as ICompanyRepresentativeDetails),
    isLoading,
    error,
  };
}

//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState<any>({});
//   const [data, setData] = useState<ICompanyOwnerList[]>(
//     [] as ICompanyOwnerList[]
//   );

//   const fetchData = useCallback(async () => {
//     try {
//       setLoading(true);
//       const response = await fetchCompanyOwner(id);
//       if (response.success) {
//         setData(response.data!);
//       }
//     } catch (error: any) {
//       setError(error);
//     } finally {
//       setLoading(false);
//     }
//   }, [id]);

//   return {
//     loading,
//     data,
//     error,
//     fetchData,
//   };
// };
export function useCompanyOwnerQuery() {
  const { data, isLoading, error } = useQuery<any, Error>(
    [API_ENDPOINTS.OWNER_DETAILS],
    () => fetchCompanyOwner(),
    {
      refetchOnWindowFocus: false,
    }
  );
  return {
    companyOwnerData: data?.data ?? ([] as ICompanyOwnerList[]),
    isLoading,
    error,
  };
}

export function useCompanyLicense() {
  const { data, isLoading, error } = useQuery<ICompanyLicenseResponse, Error>(
    [API_ENDPOINTS.OWNER_LICENSE],
    () => fetchCompanyLicense(),
    {
      refetchOnWindowFocus: false,
    }
  );
  return {
    companyLicense: data?.data ?? ({} as ICompanyLicense),
    isLoading,
    error,
  };
}
export function useCompanyLicenseUpdate() {
  const [formError, setFormError] = useState(null);
  const queryClient = useQueryClient();
  const { mutate, isLoading, isSuccess } = useMutation(postCompanyLicense, {
    // onSuccess: (data) => {
    //   if (data?.success) {
    //     showToast(`${data.message}`, "success");
    //   }
    // },
    onError: (error) => {
      const {
        response: { data },
      }: any = error ?? {};
      showToast(`${data.message}`, "error");
      setFormError(data?.message);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [API_ENDPOINTS.OWNER_LICENSE],
      });
    },
  });

  return { mutate, isLoading, formError, setFormError, isSuccess };
}

export function useCompanyBusinessLicense() {
  const { data, isLoading, error } = useQuery<
    ICompanyBusinessLicenseResponse,
    Error
  >(
    [API_ENDPOINTS.OWNER_BUSINESS_LICENSE],
    () => fetchCompanyBusinessLicense(),
    {
      refetchOnWindowFocus: false,
    }
  );
  return {
    companyBusinessLicense: data?.data ?? ({} as ICompanyBusinessLicense),
    isLoading,
    error,
  };
}

export function useCompanyBusinessLicenseUpdate() {
  const [formError, setFormError] = useState(null);
  const queryClient = useQueryClient();
  const { mutate, isLoading, isSuccess } = useMutation(
    postCompanyBusinessLicense,
    {
      // onSuccess: (data) => {
      //   if (data?.success) {
      //     showToast(`${data.message}`, "success");
      //   }
      // },
      onError: (error) => {
        const {
          response: { data },
        }: any = error ?? {};
        showToast(`${data.message}`, "error");
        setFormError(data?.message);
      },
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [API_ENDPOINTS.OWNER_BUSINESS_LICENSE],
        });
      },
    }
  );

  return { mutate, isLoading, formError, setFormError, isSuccess };
}

export function useCompanyAssociation() {
  const { data, isLoading, error } = useQuery<
    ICompanyAssociationResponse,
    Error
  >([API_ENDPOINTS.COMPANY_ASSOCIATION], () => fetchCompanyAssociation(), {
    refetchOnWindowFocus: false,
  });
  return {
    companyAssociation: data?.data ?? ({} as ICompanyAssociation),
    isLoading,
    error,
  };
}

export function useCompanyAssociationUpdate() {
  const [formError, setFormError] = useState(null);
  const queryClient = useQueryClient();
  const { mutate, isLoading, isSuccess } = useMutation(postCompanyAssociation, {
    // onSuccess: (data) => {
    //   if (data?.success) {
    //     showToast(`${data.message}`, "success");
    //   }
    // },
    onError: (error) => {
      const {
        response: { data },
      }: any = error ?? {};
      showToast(`${data.message}`, "error");
      setFormError(data?.message);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [API_ENDPOINTS.COMPANY_ASSOCIATION],
      });
    },
  });

  return { mutate, isLoading, formError, setFormError, isSuccess };
}

export function useCompanyCivilAviation() {
  const { data, isLoading, error } = useQuery<
    ICompanyCivilAviationResponse,
    Error
  >([API_ENDPOINTS.COMPANY_CIVIL_AVIATION], () => fetchCompanyCivilAviation(), {
    refetchOnWindowFocus: false,
  });
  return {
    companyCivilAviation: data?.data ?? ({} as ICompanyCivilAviation),
    isLoading,
    error,
  };
}

export function useCompanyCivilAviationUpdate() {
  const [formError, setFormError] = useState(null);
  const queryClient = useQueryClient();
  const { mutate, isLoading, isSuccess } = useMutation(
    postCompanyCivilAviation,
    {
      onError: (error) => {
        const {
          response: { data },
        }: any = error ?? {};
        showToast(`${data.message}`, "error");
        setFormError(data?.message);
      },
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [API_ENDPOINTS.COMPANY_CIVIL_AVIATION],
        });
      },
    }
  );

  return { mutate, isLoading, formError, setFormError, isSuccess };
}

export function useCompanyProposer(id: string) {
  const { data, isLoading, error } = useQuery<ICompanyProposerResponse, Error>(
    [API_ENDPOINTS.COMPANY_PROPOSER_UPDATE],
    () => fetchCompanyProposer(id as string),
    {
      refetchOnWindowFocus: false,
    }
  );
  return {
    companyProposer: data?.data ?? ([] as ICompanyProposer[]),
    isLoading,
    error,
  };
}
export function useCompanyProposerUpdate() {
  const [formError, setFormError] = useState(null);
  const queryClient = useQueryClient();
  const { mutate, isLoading, isSuccess } = useMutation(postCompanyProposer, {
    // onSuccess: (data) => {
    //   if (data?.success) {
    //     showToast(`${data.message}`, "success");
    //   }
    // },
    onError: (error) => {
      const {
        response: { data },
      }: any = error ?? {};
      showToast(`${data.message}`, "error");
      setFormError(data?.message);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [API_ENDPOINTS.COMPANY_PROPOSER_UPDATE],
      });
    },
  });

  return { mutate, isLoading, formError, setFormError, isSuccess };
}

export function useCompanyDocument() {
  const { data, isLoading, error } = useQuery<ICompanyDocumentResponse, Error>(
    [API_ENDPOINTS.COMPANY_COMPANY_DOCUMENT],
    () => fetchCompanyDocument(),
    {
      refetchOnWindowFocus: false,
    }
  );
  return {
    companyDocument: data?.data ?? ({} as ICompanyDocument),
    isLoading,
    error,
  };
}

export function useCompanyDocumentUpdate() {
  const [formError, setFormError] = useState(null);
  const queryClient = useQueryClient();
  const { mutate, isLoading, isSuccess } = useMutation(postCompanyDocument, {
    // onSuccess: (data) => {
    //   if (data?.success) {
    //     showToast(`Successfully updated company document`, "success");
    //   }
    // },
    onError: (error) => {
      const {
        response: { data },
      }: any = error ?? {};
      showToast(`${data.message}`, "error");
      setFormError(data?.message);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [API_ENDPOINTS.COMPANY_COMPANY_DOCUMENT],
      });
    },
  });

  return { mutate, isLoading, formError, setFormError, isSuccess };
}
/**
 * Fetches admission data for a given ID.
 *
 * @param id The ID of the admission record.
 * @returns An object containing the admission data, loading state, and error state.
 */
export function useAdmission(id: string) {
  const { data, isLoading, error } = useQuery<IAdmissionResponse, Error>(
    [API_ENDPOINTS.ADMISSION, addOwner, { id }],
    () => fetchAdmissionData(id as string)
  );
  return {
    admissionData: data?.data ?? ({} as IAdmissionDATA),
    isLoading,
    error,
  };
}
