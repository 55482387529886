import { useState } from "react";
import axios from "axios";
import Footer from "../Directory/Footer";
import Header from "../Directory/Header";
import {
  Box,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import { url } from "../../Helpers/utils/Constant";

const VerifyMember = () => {
  const [memberName, setMemberName] = useState("");
  const [certificateType, setCertificateType] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchResult, setSearchResult] = useState("");
  const [error, setError] = useState("");
  const [initialMessage, setInitialMessage] = useState(true);

  const handleSearch = async () => {
    if (!memberName.trim() && certificateType) {
      setError("Please enter the member name.");
      setSearchResult("");
      return;
    }
    if (!certificateType && memberName.trim()) {
      setError("Please select a certificate type.");
      setSearchResult("");
      return;
    }

    if (!memberName || !certificateType) {
      setError("Please enter the member name and certificate type for Search");
      setSearchResult("");
      return;
    }

    setLoading(true);
    setError("");
    setSearchResult("");
    setInitialMessage(false);

    try {
      const response = await axios.get(
        `${url}/api/member/duplicate-certificate-application/check`,
        {
          params: {
            name: memberName.trim(),
            type: certificateType,
          },
        }
      );

      if (response.data.code === 200) {
        setSearchResult(`Certificate exist for ${memberName}`);
        setError("");
      } else if (response.data.code === 404) {
        setError(`No certificate found for ${memberName}`);
        setSearchResult("");
      }
    } catch (error) {
      setError("An error occurred while searching. Please try again.");
      setSearchResult("");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Header />
      <section className="bg-[#131B88] mt-[-3rem]">
        <div className="container mx-auto px-4 md:px-8 text-white py-32 text-center space-y-5">
          <h1 className="font-bold text-3xl md:text-4xl lg:text-5xl">
            Verify-Member
          </h1>
          <p className="text-xl lg:text-2xl font-medium">
            Home | Verify-Member
          </p>
        </div>
      </section>
      <Box p={2} my={3} textAlign="right">
        <Grid container spacing={2} justifyContent="flex-end" mb={15}>
          <Grid item xs={12} md={1.5}>
            <TextField
              label="Enter member name"
              variant="outlined"
              fullWidth
              value={memberName}
              onChange={(e) => setMemberName(e.target.value)}
              size="small"
            />
          </Grid>
          <Grid item xs={12} md={1.5}>
            <FormControl fullWidth size="small">
              <InputLabel id="certificate-type-label">
                Certificate type
              </InputLabel>
              <Select
                required
                labelId="certificate-type-label"
                id="certificate-type-select"
                value={certificateType}
                label="Certificate type"
                onChange={(e) => setCertificateType(e.target.value)}
                style={{ textAlign: "left" }}
              >
                <MenuItem value="Application Form">New Membership</MenuItem>
                <MenuItem value="Renewal Certificate">
                  Renewal Certificate
                </MenuItem>
                <MenuItem value="Duplicate Certificate">
                  Duplicate Certificate
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={1}>
            <Button
              variant="contained"
              onClick={handleSearch}
              disabled={loading}
              fullWidth
              sx={{ height: "92%" }}
            >
              {loading ? <CircularProgress size={24} /> : "Search"}
            </Button>
          </Grid>
        </Grid>
        {initialMessage && !error && (
          <Typography
            variant="h6"
            align="center"
            mb={4}
            mt={10}
            color="text.secondary"
          >
            Please enter the member name and certificate type for Search
          </Typography>
        )}
        {searchResult && (
          <Typography
            variant="h2"
            align="center"
            mb={4}
            mt={10}
            color="success.main"
          >
            {searchResult}
          </Typography>
        )}
        {error && (
          <Typography variant="h2" align="center" mb={4} mt={10} color="error">
            {error}
          </Typography>
        )}
      </Box>
      <Footer />
    </>
  );
};

export default VerifyMember;
