import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { showToast } from "../../../Toaster/Toaster";
import {
  Grid,
  Button,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box,
} from "@mui/material";
import AddressSelect from "../../address-change-application/AddressSelect";
import { ICompanyDetails } from "../../../rest/types";
import { useMutation, useQueryClient } from "react-query";
import { UpdateCompanyMutation } from "../../new-member-applications/IndexFile/UpdateMutation/CompanyUpdateMutation";
import { API_ENDPOINTS } from "../../../rest/utils/api-endpoints";
// import ModalsWrapper from "../../../Components/Modals/ModalsWrapper";
// import PdfAndImageView from "../../../Components/pdfAndImageView/PdfAndImageView";
// import { CommonTypoGraphy } from "../../../Components/pdfAndImageView/CommonTypoGraphy";

const UpdateCompanyInformation = ({
  companyData,
}: {
  companyData: ICompanyDetails;
}) => {
  // const [open, setOpen] = useState<boolean>(false);
  // const [imageFile, setImageFile] = useState<string | null>("");
  const queryClient = useQueryClient();
  const { register, handleSubmit } = useForm();
  const [date, setDate] = useState(
    companyData?.member_company_stablished_date?.split("T")[0]
  );
  const [zone, setZone] = useState<number>(companyData.zone_id);
  const [areaID, setAreaID] = useState<string>("");
  const [companyLogo, setCOmpanyLogo] = useState("");
  const mutation = useMutation({
    mutationFn: (data: any) => {
      return UpdateCompanyMutation(data);
    },
    onSuccess: () => {
      showToast("successfully updated", "success");
    },
    onError: (error: any) => {
      showToast(`${error.response.data.message}`, "error");
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [API_ENDPOINTS.COMPANY_DETAILS],
      });
    },
  });

  const onSubmit = async (inputsData: any) => {
    const formData = new FormData();
    if (date) inputsData.member_company_stablished_date = date;
    if (zone) inputsData.member_company_zone = zone;
    if (areaID) formData.append("area_id", areaID);
    if (companyLogo) formData.append("company_logo", companyLogo);

    Object.keys(inputsData).forEach((key) => {
      formData.append(key, inputsData[key]);
    });
    // console.table(Object.fromEntries(formData));
    mutation.mutate(formData);
  };
  return (
    <>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{ p: 2 }}
        noValidate
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={4}>
            <Box>
              <Box>
                <FormControl fullWidth variant="outlined">
                  <InputLabel
                    sx={{ overflow: "hidden" }}
                    id="demo-simple-select-label"
                  >
                    Select zone
                  </InputLabel>
                  {/* <label
										style={{ marginTop: -20 }}
										htmlFor="demo-simple-select-label"
									>
										Select zone
									</label> */}
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    defaultValue={zone}
                    label="Select zone"
                    placeholder="Select zone"
                    onChange={(e: any) => setZone(e.target.value)}
                  >
                    <MenuItem value={1}>Dhaka</MenuItem>
                    <MenuItem value={2}>Chittagong</MenuItem>
                    <MenuItem value={3}>Sylhet</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box mt={2}>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  label="Organization type"
                  inputProps={{
                    readOnly: true,
                  }}
                  defaultValue={companyData.member_company_type}
                  placeholder="Enter organization type"
                  type="text"
                  fullWidth
                />
              </Box>
              <Box mt={2}>
                <TextField
                  id="date"
                  type="date"
                  label="Organization established date"
                  defaultValue={date}
                  onChange={(e) => setDate(e.target.value)}
                  sx={{ width: "100%" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
              <Box mt={2}>
                <TextField
                  id="outlined-basic"
                  label=" Which police station does the organization belong to?"
                  variant="outlined"
                  defaultValue={companyData.police_station}
                  placeholder="Enter organization police station"
                  type="text"
                  fullWidth
                  {...register("police_station")}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Box>
              <Box>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  label="Organization telephone number "
                  type="text"
                  defaultValue={companyData.cellphone}
                  placeholder="Enter organization telephone number"
                  fullWidth
                  {...register("cellphone")}
                />
              </Box>
              <Box mt={2}>
                <TextField
                  id="outlined-basic"
                  label="Organization mobile number"
                  variant="outlined"
                  defaultValue={companyData.phone}
                  {...register("phone")}
                  placeholder="Enter organization mobile number"
                  type="number"
                  fullWidth
                />
              </Box>
              <Box sx={{ mt: 2 }}>
                <label htmlFor=""></label>
                <TextField
                  id="outlined-basic"
                  label="Organization full address"
                  variant="outlined"
                  defaultValue={companyData.addressline1}
                  placeholder="Enter organization full address"
                  type="text"
                  fullWidth
                  {...register("addressline1")}
                />
              </Box>
              <Box sx={{ mt: 2 }}>
                <TextField
                  id="outlined-basic"
                  label="Organization postal code"
                  variant="outlined"
                  defaultValue={companyData.postalcode}
                  placeholder="Enter organization postal code"
                  type="text"
                  fullWidth
                  {...register("postalcode")}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Box>
              <Box>
                <TextField
                  label="Upload organization logo ( max size 5mb )"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  id="outlined-basic"
                  variant="outlined"
                  type="file"
                  fullWidth
                  onChange={(e: any) => setCOmpanyLogo(e.target.files[0])}
                />
              </Box>

              <Box mt={2}>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  defaultValue={companyData.email}
                  label="Organization email address"
                  placeholder="Enter organization email address"
                  type="email"
                  fullWidth
                  {...register("email")}
                />
              </Box>
              <Box mt={2}>
                <TextField
                  label="Organization website"
                  id="outlined-basic"
                  defaultValue={companyData.member_company_website}
                  variant="outlined"
                  placeholder="Enter organization website"
                  type="url"
                  fullWidth
                  {...register("member_company_website")}
                />
              </Box>
            </Box>
          </Grid>
          <AddressSelect setAreaID={setAreaID} companyData={companyData} />
        </Grid>

        {/* <Box mt={2}>
          {companyData.member_company_logo && (
            <CommonTypoGraphy
              name="View company logo"
              onClick={() => {
                setOpen(true);
                setImageFile(companyData.member_company_logo);
              }}
            />
          )}
        </Box> */}
        <Box sx={{ pt: 2, textAlign: "end", mb: 2 }}>
          {mutation.isLoading ? (
            <Button disabled variant="contained" disableElevation>
              Loading..
            </Button>
          ) : (
            <Button type="submit" variant="contained" disableElevation>
              Save
            </Button>
          )}
        </Box>
      </Box>{" "}
      {/* <>
        <ModalsWrapper
          modalData={{ title: "View document" }}
          setShowModal={setOpen}
          showModal={open}
        >
          <PdfAndImageView file={imageFile} />
        </ModalsWrapper>
      </> */}
    </>
  );
};

export default UpdateCompanyInformation;
