import React from "react";
import { useAuthContext } from "../../Context/AuthContext/AuthContext";
import { Navigate } from "react-router-dom";
import Spinner from "../Spinner/Spinner";

interface IProps {
  component: JSX.Element;
}

const PrivateRoute = ({ component }: IProps) => {
  const { user, isLoading } = useAuthContext();

  if (isLoading) {
    return <Spinner />;
  }

  if (!user.user_member_id) {
    return <Navigate to="/login" replace />;
  } else {
    return component;
  }
};

export default PrivateRoute;
