import React, { useState } from "react";
import {
  Typography,
  Divider,
  Card,
  Box,
  Tooltip,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CardCivilAviation from "../card-components/CardCompanyCivilAviation";
import AddCivilAviationInfo from "../components/AddCivilAviationInfo";
import { useCompanyCivilAviation } from "../../../rest/useCompanyInfo";
import UpdateCivilAviationInfo from "../../CompanyUpdateInfo/UpdateCompanyInformation/UpdateCivilAviationInfo";
const CivilAviationIndex = ({ isApplied }: { isApplied: number }) => {
  const { companyCivilAviation, isLoading: civilLoading } =
    useCompanyCivilAviation();
  const [toggle, setToggle] = useState(false);

  // const allValuesAvailable = (obj) => {
  //   return Object.values(obj).every(
  //     (value) => value !== null && value !== undefined
  //   );
  // };
  return (
    <>
      <Card>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h3" sx={{ p: 2 }}>
            Civil aviation information
          </Typography>

          {!isApplied && companyCivilAviation.certificate_file ? (
            <>
              <Tooltip title="Update" sx={{ mr: 2 }}>
                <IconButton onClick={() => setToggle(!toggle)}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </>
          ) : (
            ""
          )}
        </Box>
        <Divider light />

        {toggle ? (
          <UpdateCivilAviationInfo
            companyCivilAviation={companyCivilAviation}
          />
        ) : (
          <>
            {Object.keys(companyCivilAviation).length > 0 ? (
              <CardCivilAviation
                data={companyCivilAviation}
                loading={civilLoading}
              />
            ) : (
              <AddCivilAviationInfo />
            )}
          </>
        )}
      </Card>
    </>
  );
};

export default CivilAviationIndex;
