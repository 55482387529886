import { Box, Grid, TextField, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { useAuthContext } from "../../Context/AuthContext/AuthContext";
import { useEffect } from "react";
import { useAgencyInfoUpdateApplication } from "../../rest/useAgencyInfoUpdateApplication";

type IProps = {
  handleClose: () => void;
};

const AddAgencyIfoModal = ({ handleClose }: IProps) => {
  const { user } = useAuthContext();
  const { register, handleSubmit } = useForm();
  const { isLoading, mutate, isSuccess } = useAgencyInfoUpdateApplication();

  useEffect(() => {
    if (isSuccess) {
      handleClose();
    }
  }, [isSuccess, handleClose]);

  const onSubmit = async (data: any) => {
    data.agency_member_id = user.user_member_id;

    const formData = new FormData();
    for (const key in data) {
      if (data[key]) {
        if (key === "user_member_representative_photo") {
          formData.append(key, data[key][0]);
        } else {
          formData.append(key, data[key]);
        }
      }
    }
    console.table(Object.fromEntries(formData));
    mutate(formData);
  };

  return (
    <>
      <Box component="form" onSubmit={handleSubmit(onSubmit)} p={2}>
        <Grid container spacing={2} mb={2} maxWidth={"sm"}>
          <Grid xs={12} md={6} lg={6} item>
            <TextField
              fullWidth
              label="Representative name"
              id="outlined-required"
              type="text"
              {...register("user_member_representative_name")}
            />
          </Grid>
          <Grid xs={12} md={6} lg={6} item>
            <TextField
              fullWidth
              label="Company email "
              id="outlined-required"
              type="email"
              {...register("agency_email")}
            />
          </Grid>
          <Grid xs={12} md={6} lg={6} item>
            <TextField
              label="Representative photo ( max size 5mb )"
              InputLabelProps={{
                shrink: true,
              }}
              id="outlined-required"
              type="file"
              {...register("user_member_representative_photo")}
            />
          </Grid>
          <Grid xs={12} md={6} lg={6} item>
            <TextField
              fullWidth
              label="Representative designation"
              id="outlined-required"
              type="text"
              {...register("user_member_representative_designation")}
            />
          </Grid>

          <Grid xs={12} md={6} lg={6} item>
            <TextField
              fullWidth
              label="Company mobile number"
              id="outlined-required"
              type="number"
              {...register("phone")}
            />
          </Grid>
          <Grid xs={12} md={6} lg={6} item>
            <TextField
              fullWidth
              label="Company telephone number"
              id="outlined-required"
              type="number"
              {...register("cellphone")}
            />
          </Grid>

          <Grid xs={12} md={6} lg={6} item>
            <TextField
              fullWidth
              label="Company website"
              id="outlined-required"
              type="url"
              {...register("member_company_website")}
            />
          </Grid>
        </Grid>
        <Box mt={5}>
          {!isLoading ? (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disableElevation
            >
              Submit
            </Button>
          ) : (
            <LoadingButton
              loading
              loadingIndicator="Loading..."
              variant="contained"
              fullWidth
              disabled
            >
              <span>Loading...</span>
            </LoadingButton>
          )}
        </Box>
      </Box>
    </>
  );
};

export default AddAgencyIfoModal;
