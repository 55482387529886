import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { visuallyHidden } from "@mui/utils";
import { useRenewalQuery } from "../../rest/useFetchRenewalApplication";
import moment from "moment";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { getStatusLabel } from "../../Utils/Label/status-label";
import ModalsWrapper from "../../Components/Modals/ModalsWrapper";
import CreateRenewalApplication from "./CreateRenewalApplication";
import { Link } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useAuthContext } from "../../Context/AuthContext/AuthContext";
import TableNoDataMessage from "../../Components/TableMessage/TableNoDataMessage";

interface Data {
  renewal_application_id: number;
  user_member_company_name: string;
  renewal_application_status: string;
  renewal_application_created_at: string;
  user_member_representative_photo: string;
  action?: string;
}

function createData(
  renewal_application_id: number,
  user_member_company_name: string,
  renewal_application_status: string,
  renewal_application_created_at: string,
  user_member_representative_photo: string
): Data {
  return {
    renewal_application_id,
    user_member_company_name,
    renewal_application_status,
    renewal_application_created_at,
    user_member_representative_photo,
  };
}

// function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }

type Order = "asc" | "desc";

// function getComparator<Key extends keyof any>(
// 	order: Order,
// 	orderBy: Key
// ): (
// 	a: { [key in Key]: number | string },
// 	b: { [key in Key]: number | string }
// ) => number {
// 	return order === 'desc'
// 		? (a, b) => descendingComparator(a, b, orderBy)
// 		: (a, b) => -descendingComparator(a, b, orderBy);
// }

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
// function stableSort<T>(
// 	array: readonly T[],
// 	comparator: (a: T, b: T) => number
// ) {
// 	const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
// 	stabilizedThis.sort((a, b) => {
// 		const order = comparator(a[0], b[0]);
// 		if (order !== 0) {
// 			return order;
// 		}
// 		return a[1] - b[1];
// 	});
// 	return stabilizedThis.map((el) => el[0]);
// }

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "renewal_application_id",
    numeric: false,
    disablePadding: false,
    label: "Application ID",
  },
  {
    id: "renewal_application_status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "renewal_application_created_at",
    numeric: false,
    disablePadding: false,
    label: "Created at",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Action",
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function EnhancedTableToolbar({ setOpen }: { setOpen: any }) {
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Typography
        sx={{ flex: "1 1 100%", p: 2 }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Renewal applications
      </Typography>

      <Tooltip sx={{ mr: 2 }} title="Create renewal application">
        <IconButton onClick={() => setOpen(true)}>
          <AddCircleOutlineIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
}

export default function RenewalApplication() {
  const { user } = useAuthContext();
  const { data, fetchData, loading } = useRenewalQuery();
  const [order, setOrder] = React.useState<Order>("asc");
  const [open, setOpen] = React.useState<boolean>(false);
  const [reload, setReload] = React.useState<boolean>(false);
  const [orderBy, setOrderBy] = React.useState<keyof Data>(
    "renewal_application_id"
  );
  const [selected, setSelected] = React.useState<readonly number[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (
    _event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.renewal_application_id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (_event: React.MouseEvent<unknown>, name: number) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  useEffect(() => {
    fetchData({ renewal_application_member_id: user.user_member_id });
  }, [reload, user.user_member_id, fetchData]);

  const rows = data.map((row) =>
    createData(
      row.renewal_application_id,
      row.user_member_company_name,
      row.renewal_application_status,
      row.renewal_application_created_at,
      row.user_member_representative_photo
        ? row.user_member_representative_photo
        : "public/logo.png"
    )
  );

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <>
      <Box sx={{ width: "100%", mt: 5 }}>
        <Paper sx={{ width: "100%", mb: 2, mt: 5 }}>
          {user?.user_member_account_status === "active" && (
            <EnhancedTableToolbar setOpen={setOpen} />
          )}

          <TableContainer>
            <Table aria-labelledby="tableTitle" size={"small"}>
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {rows.length > 0 &&
                  rows.map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) =>
                          handleClick(event, row.renewal_application_id)
                        }
                        tabIndex={-1}
                        key={row.renewal_application_id}
                        sx={{ cursor: "pointer" }}
                      >
                        <TableCell
                          // component="th"
                          id={labelId}
                          // scope="row"
                          align="left"
                        >
                          {row.renewal_application_id}
                        </TableCell>
                        {/* <TableCell
												sx={{
													display: 'flex',
													alignItems: 'center',
													columnGap: '5px',
												}}
												align="left"
											>
												<Avatar
													alt={`${row.user_member_company_name}`}
													src={`${imgUrl}/client/${row.user_member_representative_photo}`}
													sx={{ width: 32, height: 32 }}
												/>
												{row.user_member_company_name}
											</TableCell> */}
                        <TableCell align="left">
                          {getStatusLabel(row.renewal_application_status)}
                        </TableCell>
                        <TableCell align="left">
                          {moment(
                            `${row.renewal_application_created_at}`
                          ).format("DD-MMM-YYYY")}
                        </TableCell>
                        <TableCell align="left">
                          <Tooltip title="View">
                            <Link
                              to={`/renewal-applications/${row.renewal_application_id}`}
                            >
                              <IconButton>
                                <VisibilityIcon fontSize="small" />
                              </IconButton>
                            </Link>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {!loading && rows.length === 0 && <TableNoDataMessage />}
                {loading && (
                  <TableRow
                    style={{
                      height: 53 * emptyRows,
                    }}
                  >
                    <TableCell align="center" colSpan={6}>
                      Loading...
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
      <ModalsWrapper
        modalData={{ title: "Create renewal application" }}
        setShowModal={setOpen}
        showModal={open}
      >
        <CreateRenewalApplication
          setReload={() => setReload((prev) => !prev)}
          handelClose={setOpen}
        />
      </ModalsWrapper>
    </>
  );
}
