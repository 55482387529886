import { useParams } from "react-router-dom";
import { imgUrl } from "../../Helpers/utils/Constant";
import moment from "moment";
import {
  Container,
  Grid,
  Card,
  CardContent,
  Box,
  Typography,
  Divider,
} from "@mui/material";
import PageLoader from "../../Components/Spinner/PageLoader";
import { getStatusLabel } from "../../Utils/Comibined";
import { useGetSingleEvent } from "../JoinedMeeting/api/JoinedEndPoint";

const SingleEvent = () => {
  const { eventId } = useParams();
  const { singleEvent, isLoading } = useGetSingleEvent(Number(eventId));

  return (
    <>
      <Container sx={{ mt: 8 }} maxWidth="xl">
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
        >
          <Container>
            <Card>
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "cneter",
                    mb: 2,
                  }}
                >
                  <Box>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      display="inline"
                      gutterBottom
                      fontSize={17}
                    >
                      Event
                    </Typography>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      display="inline"
                      sx={{ pl: 1, fontSize: 17 }}
                      gutterBottom
                    >
                      # {singleEvent?.member_event_id}
                    </Typography>
                  </Box>
                </Box>
                <Divider />

                {isLoading ? (
                  <PageLoader />
                ) : (
                  <Grid
                    container
                    pt={2}
                    spacing={{ xs: 2, md: 3 }}
                    columns={{ xs: 4, sm: 8, md: 12 }}
                  >
                    <Grid item xs={2} sm={4} md={4}>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        display="inline"
                        sx={{ pr: 1 }}
                        gutterBottom
                      >
                        Event Title:
                      </Typography>
                      {singleEvent?.member_event_name}
                    </Grid>

                    <Grid item xs={2} sm={4} md={4}>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        display="inline"
                        sx={{ pr: 1 }}
                        gutterBottom
                      >
                        Event Place:
                      </Typography>
                      {singleEvent?.member_event_location}
                    </Grid>

                    <Grid item xs={2} sm={4} md={4}>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        display="inline"
                        sx={{ pr: 1 }}
                        gutterBottom
                      >
                        Event Status:
                      </Typography>
                      {getStatusLabel(singleEvent?.member_event_status)}
                    </Grid>
                    <Grid item xs={2} sm={4} md={4}>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        display="inline"
                        sx={{ pr: 1 }}
                        gutterBottom
                      >
                        Event Start Date:
                      </Typography>
                      {moment(singleEvent?.member_event_start_date).format(
                        "LL"
                      )}
                    </Grid>
                    <Grid item xs={2} sm={4} md={4}>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        display="inline"
                        sx={{ pr: 1 }}
                        gutterBottom
                      >
                        Event End Date:
                      </Typography>
                      {moment(singleEvent?.member_event_end_date).format("LL")}
                    </Grid>

                    <Grid item xs={2} sm={4} md={5}>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        sx={{ pr: 1 }}
                        gutterBottom
                      >
                        Event Image:
                      </Typography>
                      <>
                        <img
                          width={250}
                          height={150}
                          style={{
                            borderRadius: "5px",
                          }}
                          src={
                            singleEvent.member_event_cover_photo
                              ? `${imgUrl}/${singleEvent.member_event_cover_photo}`
                              : "/static/images/avatars/avatar2.png"
                          }
                          alt=""
                        />
                      </>
                    </Grid>
                    <Grid item xs={12} sm={12} md={7}>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        display="inline"
                        sx={{ pr: 1 }}
                        gutterBottom
                      >
                        Event details:
                      </Typography>
                      {singleEvent?.member_event_details}
                    </Grid>
                  </Grid>
                )}
              </CardContent>
            </Card>
          </Container>
        </Grid>
      </Container>
    </>
  );
};

export default SingleEvent;
