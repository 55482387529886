import { useState, useCallback } from "react";
import { IDuplicateCertificate, IDuplicateSingle, IResponse } from "./types";
import { showToast } from "../Toaster/Toaster";
import {
  fetchDuplicateCertificate,
  fetchDuplicateSingleCertificate,
  postDuplicateCertificate,
} from ".";

export type IDuplicateCertificateParams = {
  duplicate_certificate_application_member_id: number;
  duplicate_certificate_application_status?: string;
  duplicate_certificate_from_date?: string;
  duplicate_certificate_to_date?: string;
};

export const useDuplicateCertificateQuery = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>({});
  const [data, setData] = useState<IDuplicateCertificate[]>(
    [] as IDuplicateCertificate[]
  );

  const fetchData = useCallback(async (params: IDuplicateCertificateParams) => {
    try {
      setLoading(true);
      const response = await fetchDuplicateCertificate(params);
      if (response.success) {
        setData(response.data!);
      }
    } catch (error: any) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    loading,
    data,
    error,
    fetchData,
  };
};
export const useDuplicateSingleQuery = (id: string) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<any>({});
  const [data, setData] = useState<IDuplicateSingle>({} as IDuplicateSingle);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await fetchDuplicateSingleCertificate(id);
      if (response.success) {
        setData(response.data!);
      }
    } catch (error: any) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [id]);

  return {
    loading,
    data,
    error,
    fetchData,
  };
};
export const useDuplicateCertificateMutation = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>({});
  const [data, setData] = useState<IResponse>({} as IResponse);

  const createData = useCallback(async (formData: FormData) => {
    try {
      setLoading(true);
      const response = await postDuplicateCertificate(formData);
      if (response.success) {
        setData(response);
        showToast(
          `Successfully created Duplicate certificate application`,
          "success"
        );
      }
    } catch (error: any) {
      setError(error);
      showToast(`${error?.response?.data?.message}`, "error");
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    loading,
    data,
    error,
    createData,
  };
};
