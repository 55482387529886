import {
  IAdmissionResponse,
  IAreaResponse,
  IChangeAddressResponse,
  ICitiesResponse,
  ICompanyAssociationResponse,
  ICompanyBusinessLicenseResponse,
  ICompanyCivilAviationResponse,
  ICompanyDetailsResponse,
  ICompanyDocumentResponse,
  ICompanyLicenseResponse,
  ICompanyOwnerResponse,
  ICompanyProposerReceiverResponse,
  ICompanyProposerResponse,
  ICompanyRepresentativeDetailsResponse,
  ICreateIDCardResponse,
  IDuplicateCertificateResponse,
  IDuplicateSingleResponse,
  IOwnershipChangeResponse,
  IProvinceResponse,
  IRenewalApplicationResponse,
  IRenewalSingleResponse,
  IResponse,
  ISingleAddressResponse,
  ISingleOwnershipChangeResponse,
  ISubCitiesResponse,
  IUpdateProposerInput,
} from "./types";
import { IChangeParams } from "./useAddressChange";
import { IChangeOwnerParams } from "./useChangeOwnership ";
import { IDuplicateCertificateParams } from "./useDuplicateCertificate";
import { IRenewalParams } from "./useFetchRenewalApplication";
import { API_ENDPOINTS } from "./utils/api-endpoints";
import { HttpClient } from "./utils/http";

// **************** Renewal application module API ****************

export const fetchRenewalApplication = async (params: IRenewalParams) => {
  return await HttpClient.get<IRenewalApplicationResponse>(
    API_ENDPOINTS.RENEWAL_APPLICATION,
    params
  );
};
export const fetchRenewalSingleApplication = async (id: string) => {
  return await HttpClient.get<IRenewalSingleResponse>(
    `${API_ENDPOINTS.RENEWAL_APPLICATION}/${id}`
  );
};
export const postRenewalApplication = async (formData: FormData) => {
  return await HttpClient.post<IResponse>(
    API_ENDPOINTS.RENEWAL_APPLICATION,
    formData
  );
};

// **************** Duplicate Certificate module API ****************

export const fetchDuplicateCertificate = async (
  params: IDuplicateCertificateParams
) => {
  return await HttpClient.get<IDuplicateCertificateResponse>(
    API_ENDPOINTS.DUPLICATE_CERTIFICATE,
    params
  );
};
export const fetchDuplicateSingleCertificate = async (id: string) => {
  return await HttpClient.get<IDuplicateSingleResponse>(
    `${API_ENDPOINTS.DUPLICATE_CERTIFICATE}/${id}`
  );
};
export const postDuplicateCertificate = async (formData: FormData) => {
  return await HttpClient.post<IResponse>(
    API_ENDPOINTS.DUPLICATE_CERTIFICATE,
    formData
  );
};

// **************** Ownership module API ****************

export const fetchSingleOwnershipChange = async (id: string) => {
  return await HttpClient.get<ISingleOwnershipChangeResponse>(
    `${API_ENDPOINTS.OWNERSHIP_APPLICATION}/${id}`
  );
};
export const fetchOwnershipChange = async (params: IChangeOwnerParams) => {
  return await HttpClient.get<IOwnershipChangeResponse>(
    API_ENDPOINTS.OWNERSHIP_APPLICATION,
    params
  );
};
export const postOwnershipChange = async (formData: FormData) => {
  return await HttpClient.post<IResponse>(
    API_ENDPOINTS.OWNERSHIP_APPLICATION,
    formData
  );
};

// **************** Address module API ****************

export const fetchAddressChange = async (params: IChangeParams) => {
  return await HttpClient.get<IChangeAddressResponse>(
    API_ENDPOINTS.ADDRESS_CHANGE,
    params
  );
};
export const fetchSingleAddress = async (id: string) => {
  return await HttpClient.get<ISingleAddressResponse>(
    `${API_ENDPOINTS.ADDRESS_CHANGE}/${id}`
  );
};
export const postAddressChange = async (formData: FormData) => {
  return await HttpClient.post<IResponse>(
    API_ENDPOINTS.ADDRESS_CHANGE,
    formData
  );
};

// **************** Company information  ****************

export const fetchCompanyOwner = async () => {
  return await HttpClient.get<ICompanyOwnerResponse>(
    `${API_ENDPOINTS.OWNER_DETAILS}`
  );
};
export const fetchCompanyDetails = async () => {
  return await HttpClient.get<ICompanyDetailsResponse>(
    `${API_ENDPOINTS.COMPANY_DETAILS}`
  );
};
export const fetchOwnerDetails = async (id: string) => {
  return await HttpClient.get<ICompanyRepresentativeDetailsResponse>(
    `${API_ENDPOINTS.COMPANY_REPRESENTATIVE}/${id}`
  );
};
export const fetchCompanyLicense = async () => {
  return await HttpClient.get<ICompanyLicenseResponse>(
    `${API_ENDPOINTS.OWNER_LICENSE}`
  );
};
export const postCompanyLicense = async ({ input }: { input: FormData }) => {
  return await HttpClient.post<IResponse>(
    `${API_ENDPOINTS.OWNER_LICENSE}`,
    input
  );
};
export const fetchCompanyBusinessLicense = async () => {
  return await HttpClient.get<ICompanyBusinessLicenseResponse>(
    `${API_ENDPOINTS.OWNER_BUSINESS_LICENSE}`
  );
};

export const postCompanyBusinessLicense = async ({
  input,
}: {
  input: FormData;
}) => {
  return await HttpClient.post<IResponse>(
    `${API_ENDPOINTS.OWNER_BUSINESS_LICENSE}`,
    input
  );
};

export const fetchCompanyAssociation = async () => {
  return await HttpClient.get<ICompanyAssociationResponse>(
    `${API_ENDPOINTS.COMPANY_ASSOCIATION}`
  );
};
export const postCompanyAssociation = async ({
  input,
}: {
  input: FormData;
}) => {
  return await HttpClient.post<IResponse>(
    `${API_ENDPOINTS.COMPANY_ASSOCIATION}`,
    input
  );
};

export const fetchCompanyCivilAviation = async () => {
  return await HttpClient.get<ICompanyCivilAviationResponse>(
    `${API_ENDPOINTS.COMPANY_CIVIL_AVIATION}`
  );
};
export const postCompanyCivilAviation = async ({
  input,
}: {
  input: FormData;
}) => {
  return await HttpClient.post<IResponse>(
    `${API_ENDPOINTS.COMPANY_CIVIL_AVIATION}`,
    input
  );
};
export const fetchCompanyDocument = async () => {
  return await HttpClient.get<ICompanyDocumentResponse>(
    `${API_ENDPOINTS.COMPANY_COMPANY_DOCUMENT}`
  );
};
export const postCompanyDocument = async ({ input }: { input: FormData }) => {
  return await HttpClient.post<IResponse>(
    `${API_ENDPOINTS.COMPANY_COMPANY_DOCUMENT}`,
    input
  );
};

// **************** Proposer request  ****************

type ICompanyProposer = {
  member_1st_proposer_id: string;
  member_1st_proposer_seal: string;
  member_1st_proposer_signature: string;
  member_2nd_proposer_id: string;
  member_2nd_proposer_seal: string;
  member_2nd_proposer_signature: string;
};

export const fetchCompanyProposer = async (id: string) => {
  return await HttpClient.get<ICompanyProposerResponse>(
    `${API_ENDPOINTS.COMPANY_PROPOSER(id)}`
  );
};
export const postCompanyProposer = async ({
  input,
}: {
  input: ICompanyProposer;
}) => {
  return await HttpClient.post<IResponse>(
    `${API_ENDPOINTS.COMPANY_PROPOSER_UPDATE}`,
    input
  );
};
export const fetchProposerReceiver = async (id: string) => {
  return await HttpClient.get<ICompanyProposerReceiverResponse>(
    `${API_ENDPOINTS.COMPANY_PROPOSER_RECEIVER(id)}`
  );
};
export const postUpdateProposerRequest = async (
  input: IUpdateProposerInput
) => {
  return await HttpClient.post<IResponse>(
    `${API_ENDPOINTS.PROPOSER_REQUEST_UPDATE}`,
    input
  );
};

// **************** ID Cards  ****************

export const postIDCard = async (input: FormData) => {
  return await HttpClient.post<ICreateIDCardResponse>(
    `${API_ENDPOINTS.ID_CARDS}`,
    input
  );
};

// **************** Address list  ****************

export type IAddressDistrictParams = {
  division_id: number;
};
export type IAddressThanaParams = {
  division_id: number;
  district_id: number;
};
export type IAddressAreaParams = {
  division_id: number;
  district_id: number;
  thana_id: number;
};

export const fetchAddressDivision = async () => {
  return await HttpClient.get<IProvinceResponse>(API_ENDPOINTS.DIVISION);
};
export const fetchAddressDistrict = async (params: IAddressDistrictParams) => {
  return await HttpClient.get<ICitiesResponse>(API_ENDPOINTS.DISTRICT, params);
};
export const fetchAddressThana = async (params: IAddressThanaParams) => {
  return await HttpClient.get<ISubCitiesResponse>(API_ENDPOINTS.THANA, params);
};
export const fetchAddressArea = async (params: IAddressAreaParams) => {
  return await HttpClient.get<IAreaResponse>(API_ENDPOINTS.AREA, params);
};

// **************** ATAB Admission form  ****************

export const fetchAdmissionData = async (id: string) => {
  return await HttpClient.get<IAdmissionResponse>(
    `${API_ENDPOINTS.ADMISSION}/${id}`
  );
};
// **************** ATAB Agency info update  ****************

export const fetchAgencyInfoUpdate = async (id: number) => {
  return await HttpClient.get<any>(`${API_ENDPOINTS.AGENCY_INFO_UPDATE}/${id}`);
};
