import React, { useState } from "react";
import {
  Typography,
  Divider,
  Card,
  Box,
  Tooltip,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CardCompanyAssociation from "../card-components/CardCompanyAssociation";
import AddOtherBusinessLicense from "../components/AddOtherBusinessLicense";
import { useCompanyAssociation } from "../../../rest/useCompanyInfo";
import UpdateCompanyOtherBusinessLicense from "../../CompanyUpdateInfo/UpdateCompanyInformation/UpdateCompanyOtherBusinessLicense";
const OtherBusinessInfoIndex = ({ isApplied }: { isApplied: number }) => {
  const { companyAssociation } = useCompanyAssociation();
  const [toggle, setToggle] = useState(false);
  return (
    <>
      <Card>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h3" sx={{ p: 2 }}>
            Other business information
          </Typography>
          {!isApplied && Object.keys(companyAssociation).length > 0 ? (
            <Tooltip title="Update" sx={{ mr: 2 }}>
              <IconButton onClick={() => setToggle(!toggle)}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          ) : (
            ""
          )}
        </Box>
        <Divider light />
        {toggle ? (
          <UpdateCompanyOtherBusinessLicense
            companyAssociation={companyAssociation}
          />
        ) : (
          <>
            {Object.keys(companyAssociation).length > 0 ? (
              <CardCompanyAssociation companyAssociation={companyAssociation} />
            ) : (
              <AddOtherBusinessLicense />
            )}
          </>
        )}
      </Card>
    </>
  );
};

export default OtherBusinessInfoIndex;
