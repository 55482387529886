import { CiClock2, CiLocationOn } from "react-icons/ci";
import { FaFacebookF, FaLinkedin, FaTwitter, FaYoutube } from "react-icons/fa";
import { BsEnvelope } from "react-icons/bs";
import logo from "../../assets/loginImage/logo.png";
const Footer = () => {
  return (
    <footer className="bg-[#180c52]">
      <div className="container mx-auto px-4 md:px-8 text-white py-24 space-y-5">
        <img
          src={logo}
          alt="atab"
          className="object-cover w-full max-w-[275px] h-auto mx-auto"
        />
        <div className="flex justify-center items-center gap-4 text-xl pt-2">
          <span className="hover:bg-[#FCAE01] transition-all duration-700">
            <FaFacebookF />
          </span>
          <span className="hover:bg-[#FCAE01] transition-all duration-700">
            <FaTwitter />
          </span>
          <span className="hover:bg-[#FCAE01] transition-all duration-700">
            <FaLinkedin />
          </span>
          <span className="hover:bg-[#FCAE01] transition-all duration-700">
            <FaYoutube />
          </span>
        </div>
        <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 justify-items-center gap-5 lg:gap-2 text-xs pt-16">
          <li className="flex items-center gap-3">
            <span className="text-[#FCAE01] text-5xl">
              <CiLocationOn />
            </span>
            <p className="font-medium space-y-2 flex flex-col">
              <span>Sattara Center (15th Floor), 30/A Naya</span>
              <span>Paltan, VIP Road, Dhaka-1000.</span>
            </p>
          </li>
          <li className="flex items-center gap-3">
            <span className="text-[#FCAE01] text-5xl">
              <CiClock2 />
            </span>
            <p className="font-medium space-y-2 flex flex-col">
              <span>+880248320728, +880258315595,</span>
              <span>+88028332712, 0258314284 (ATTI)</span>
            </p>
          </li>
          <li className="flex items-center gap-3">
            <span className="text-[#FCAE01] text-5xl">
              <CiClock2 />
            </span>
            <p className="font-medium space-y-2 flex flex-col">
              <span>Sattara Center (15th Floor), 30/A Naya</span>
              <span>Paltan, VIP Road, Dhaka-1000.</span>
            </p>
          </li>
          <li className="flex items-center gap-3">
            <span className="text-[#FCAE01] text-5xl">
              <BsEnvelope />
            </span>
            <p className="font-medium space-y-2 flex flex-col">
              <span>Sattara Center (15th Floor), 30/A Naya</span>
              <span>Paltan, VIP Road, Dhaka-1000.</span>
            </p>
          </li>
        </ul>
      </div>
      <p className="bg-[#0F54B9] py-6 text-center text-white text-lg px-4 md:px-8">
        {new Date().getFullYear()} Copyright ATAB. Technology Partner{" "}
        <a
          className="font-bold hover:underline"
          href="https://m360ict.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          M360 ICT
        </a>{" "}
        All Rights Reserved
      </p>
    </footer>
  );
};

export default Footer;
