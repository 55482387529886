import React, { useState } from "react";
import { Box, Grid, Button, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { showToast } from "../../../Toaster/Toaster";
import { ICompanyLicense } from "../../../rest/types";
import { useMutation, useQueryClient } from "react-query";
import { API_ENDPOINTS } from "../../../rest/utils/api-endpoints";
import { UpdateCompanyTradeLicenseMutation } from "../../new-member-applications/IndexFile/UpdateMutation/CompanyUpdateMutation";

interface IProps {
  companyLicense: ICompanyLicense;
  memberId: number;
}

const UpdateCompanyTradeLicense = ({ companyLicense }: IProps) => {
  const { register, handleSubmit } = useForm();
  const queryClient = useQueryClient();
  const [date, setDate] = useState(
    companyLicense.company_trade_license_expiry_date.split("T")[0]
  );

  const mutation = useMutation({
    mutationFn: (data: any) => {
      return UpdateCompanyTradeLicenseMutation(data);
    },
    onSuccess: () => {
      showToast("successfully updated", "success");
    },
    onError: (error: any) => {
      showToast(`${error.response.data.message}`, "error");
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [API_ENDPOINTS.OWNER_LICENSE],
      });
    },
  });

  const onSubmit = async (inputsData: any) => {
    if (date) {
      inputsData.company_trade_license_expiry_date = date;
    }
    const formData = new FormData();
    Object.keys(inputsData).forEach((key) => {
      if (typeof inputsData[key] === "object") {
        if (inputsData[key][0]) {
          formData.append(key, inputsData[key][0]);
        }
      } else {
        formData.append(key, inputsData[key]);
      }
    });

    mutation.mutate(formData);
  };
  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} p={2}>
      <Box pt={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Box>
              <TextField
                id="outlined-basic"
                variant="outlined"
                label="Enter trade license number "
                type="text"
                defaultValue={companyLicense.company_trade_license}
                fullWidth
                sx={{ pb: 2 }}
                {...register("company_trade_license")}
              />
            </Box>
            <Box>
              <TextField
                id="outlined-basic"
                variant="outlined"
                defaultValue={companyLicense.company_tin_number}
                label="TIN number"
                type="number"
                fullWidth
                sx={{ pb: 2 }}
                {...register("company_tin_number")}
              />
            </Box>
            <Box>
              <TextField
                id="outlined-basic"
                variant="outlined"
                label="Upload BIN File / document  ( max size 5mb )"
                InputLabelProps={{
                  shrink: true,
                }}
                type="file"
                fullWidth
                sx={{ pb: 2 }}
                {...register("company_bin_file")}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box>
              <TextField
                id="date"
                type="date"
                defaultValue={date}
                label="Trade license expiry date "
                onChange={(e) => setDate(e.target.value)}
                sx={{ width: "100%", pb: 2 }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>
            <Box>
              <TextField
                id="outlined-basic"
                variant="outlined"
                label="Upload TIN file / document ( max size 5mb )"
                type="file"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{ pb: 2 }}
                {...register("company_tin_file")}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box>
              {/* <a
                href={`${imgUrl}/${companyLicense.company_trade_license_file}`}
                target='_blank'
                rel='noopener noreferrer'
              >
                Company trade license file
              </a> */}
              <TextField
                id="outlined-basic"
                variant="outlined"
                label="Upload trade license File / document ( max size 5mb )"
                type="file"
                fullWidth
                sx={{ pb: 2, mt: 1 }}
                InputLabelProps={{
                  shrink: true,
                }}
                {...register("company_trade_license_file")}
              />
            </Box>
            <Box>
              <TextField
                id="outlined-basic"
                variant="outlined"
                label="BIN number"
                type="text"
                defaultValue={companyLicense.company_bin_number}
                fullWidth
                sx={{ pb: 2 }}
                {...register("company_bin_number")}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* <Box>
        <Typography
          variant="body1"
          fontWeight="bold"
          color="text.primary"
          display="inline"
          sx={{ pr: 1 }}
          gutterBottom
        >
          <a
            href={`${imgUrl}/${companyLicense.company_trade_license_file}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Download company trade license file
          </a>
        </Typography>
        <Typography
          variant="body1"
          fontWeight="bold"
          color="text.primary"
          display="inline"
          sx={{ pr: 1 }}
          gutterBottom
        >
          <a
            href={`${imgUrl}/${companyLicense.company_tin_file}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Download company TIN file
          </a>
        </Typography>

        {companyLicense.company_bin_file && (
          <Typography
            variant="body1"
            fontWeight="bold"
            color="text.primary"
            display="inline"
            sx={{ pr: 1 }}
            gutterBottom
          >
            <a
              href={`${imgUrl}/${companyLicense.company_bin_file}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Download company BIN file
            </a>
          </Typography>
        )}
      </Box> */}

      <Box sx={{ pt: 5, textAlign: "end", mb: 2 }}>
        {mutation.isLoading ? (
          <Button
            disabled
            variant="contained"
            disableElevation
            className="add-remove-button"
          >
            Loading..
          </Button>
        ) : (
          <Button
            type="submit"
            variant="contained"
            disableElevation
            className="add-remove-button"
          >
            Save
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default UpdateCompanyTradeLicense;
