import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Button, TextField, Typography } from "@mui/material";
import axios from "axios";
import { AUTH_USER_SUCCESS, url } from "../../../Helpers/utils/Constant";
import { showToast } from "../../../Toaster/Toaster";
import { useNavigate } from "react-router-dom";
import { muiStyles } from "../../../Utils/Styles";
import { setCookie } from "nookies";
import { useAuthContext } from "../../../Context/AuthContext/AuthContext";
import { customizedSnackbars } from "../../../Toaster/Snackbar";

const useStyles = muiStyles;
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
const EmailVerifiModal = ({
  open,
  handleClose,
  inputData,
  setLoading,
}: any) => {
  const { dispatch } = useAuthContext();
  const navigate = useNavigate();
  const classes = useStyles();
  const [otp, setOTp] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData();
    if (inputData) {
      for (const key in inputData) {
        if (inputData[key]) {
          if (key === "user_member_civil_aviation_certificate") {
            formData.append(key, inputData[key][0]);
          } else {
            formData.append(key, inputData[key]);
          }
        }
      }
    }
    if (otp) {
      axios
        .post(`${url}/api/atab/common/match-email-otp`, {
          email: inputData.user_member_email,
          otp,
          type: "register_member",
        })
        .then(function (res) {
          if (res.data.success) {
            try {
              axios
                .post(`${url}/api/auth/member/register`, formData)
                .then(function (res) {
                  if (res.data.success) {
                    showToast(`Successfully Registered`, "success");
                    dispatch?.({
                      type: AUTH_USER_SUCCESS,
                      payload: res.data.data,
                    });

                    setCookie(null, "atab_ua", res.data.token);
                    navigate("/");
                  } else {
                    handleClose();
                    setLoading(false);
                  }
                })
                .catch(function (err: any) {
                  showToast(`${err.response?.data?.message}`, "error");
                  setLoading(false);
                  handleClose();
                });
            } catch (err: any) {
              customizedSnackbars({ snackbarOpen: true });
              showToast(`${err.response?.data?.message}`, "error");

              handleClose();
              setIsLoading(false);
            }

            setIsLoading(false);
            handleClose();
          } else {
            handleClose();
            setIsLoading(false);
          }
        })
        .catch(function (err: any) {
          showToast(`${err?.response?.data.message}`, "error");
          handleClose();
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style }}>
          <div className="pt-3">
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                textAlign: "center",
                fontWeight: "600",
                color: "#0c4da2",
                pb: 3,
              }}
            >
              Enter OTP
            </Typography>
          </div>
          <Box component="form" onSubmit={handleSubmit}>
            <TextField
              margin="normal"
              required
              fullWidth
              type="number"
              label="Enter OTP"
              onChange={(e: any) => setOTp(e.target.value)}
              variant="filled"
              className={classes.inputText}
              InputProps={{ classes }}
            />

            <Box>
              {isLoading ? (
                <Button
                  disabled
                  fullWidth
                  variant="contained"
                  disableElevation
                  sx={{
                    mt: 3,
                    mb: 2,
                    py: 1,
                    background: "#1113a2",
                  }}
                  className={classes.signInButton}
                >
                  Loading...
                </Button>
              ) : (
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  disableElevation
                  sx={{
                    mt: 3,
                    mb: 2,
                    py: 1,
                    background: "#1113a2",
                  }}
                  className={classes.signInButton}
                >
                  Submit
                </Button>
              )}
            </Box>

            <Box textAlign={"end"}>
              <Button
                variant="outlined"
                onClick={handleClose}
                disableElevation
                sx={{
                  mt: 3,
                  mb: 2,
                  py: 1,
                }}
                className={classes.signInButton}
              >
                Close
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default EmailVerifiModal;
