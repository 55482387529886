import { Box } from "@mui/material";
import SupportConvo from "../../../Components/SupportConvo/SupportConvo";
import DashboardContent from "../../../DashboardUtils/DashboardContent";

const Dashboard = () => {
  return (
    <Box>
      <DashboardContent />
      <SupportConvo />
    </Box>
  );
};

export default Dashboard;
