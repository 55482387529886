export const API_ENDPOINTS = {
  RENEWAL_APPLICATION: "/api/member/renewal-application",
  DUPLICATE_CERTIFICATE: "/api/member/duplicate-certificate-application",
  OWNERSHIP_APPLICATION: "/api/member/owner-change-application",
  ADDRESS_CHANGE: "/api/member/address-change-application",
  COMPANY_REPRESENTATIVE: "/api/atab/member-company/representative",
  // COMPANY_OWNER: '/api/atab/member-company/member/owner',
  COMPANY_DETAILS: "/api/atab/member-company/member/company",
  OWNER_DETAILS: "/api/atab/member-company/member/owner",
  OWNER_LICENSE: "/api/atab/member-company/member/license",
  OWNER_BUSINESS_LICENSE: "/api/atab/member-company/member/business-license",
  COMPANY_ASSOCIATION: "/api/atab/member-company/member/association",
  COMPANY_CIVIL_AVIATION: "/api/atab/member-company/civil/aviation",
  COMPANY_COMPANY_DOCUMENT: "/api/atab/member-company/member/documents",
  COMPANY_PROPOSER_UPDATE: "/api/atab/member-company/proposer",
  COMPANY_PROPOSER: (id: string) =>
    `/api/atab/member-company/get/proposer/for/requester/status/${id}/all`,
  COMPANY_PROPOSER_RECEIVER: (id: string) =>
    `/api/atab/member-company/get/proposer/for/receiver/status/${id}/all`,
  ID_CARDS: "/api/member/id-card/application",
  PROPOSER_REQUEST_UPDATE:
    "/api/atab/member-company/member/accept/reject/proposer-request",
  DIVISION: "/api/atab/address/division",
  DISTRICT: "/api/atab/address/district",
  THANA: "/api/atab/address/thana",
  AREA: "/api/atab/address/area",
  ADMISSION: "/api/member/print-member",
  AGENCY_INFO_UPDATE: "/api/atab/agency-member-info/member",
  SINGLE_AGENCY_INFO_UPDATE: "/api/atab/agency-member-info",
  DASHBOARD_DATA: "/api/atab/user-member/get/dashboard/data",
  DASHBOARD_SERVICE: "/api/atab/payment/get/all/paymentable/item?status=1",
  DASHBOARD_MEETING:
    "/api/member/meeting/get/all/meeting/or/by-status/upcoming/all",
  JOINED_TRAINING: "/api/member/meeting/member/join/meeting",
  DASHBOARD_EVENTS: "/api/member/event?status=upcoming",
  JOINED_EVENT: "/api/member/event/join/event",
  DASHBOARD_NOTICE: "/api/member/notice",
  PAYMENT: (id: number) =>
    `/api/atab/invoice/get/all/by/user-member/status/${id}/all`,
  APPLICATION_APPLIED: "/api/atab/admin/update/member-applied",
  ALL_ID_CARD: "/api/member/id-card/application?user_member_id",
  SINGLE_ID_CARD: "/api/member/id-card/application",
  CERTIFICATE: "/api/atab/user-member/certificate",
  USER_DATA: "/api/atab/user-member/get/a/member",
  PROPOSER_REQUEST:
    "/api/atab/member-company/get/proposer/for/requester/status",
  PROPOSER_RECEIVER:
    "/api/atab/member-company/get/proposer/for/receiver/status",
  REJECT_PROPOSER:
    "/api/atab/member-company/member/accept/reject/proposer-request",
  JOINED_MEETING_GET: "/api/member/meeting/get/all/joined/of-member",
  JOINED_EVENT_GET: "/api/member/event/joined/events",
  SINGLE_EVENT: "/api/member/event",
  CHANGE_PASSWORD: "/api/auth/member/change/password",
};
