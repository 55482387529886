import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Button, DialogActions, Divider } from "@mui/material";
import { Dispatch, SetStateAction } from "react";

type IProps = {
  children: React.ReactNode;
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  modalData: any;
};
const ModalsWrapper = ({
  children,
  showModal,
  setShowModal,
  modalData,
}: IProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="md"
        open={showModal}
        onClose={() => {
          return setShowModal(!showModal);
        }}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle>
          {modalData.title}

          <Divider />
        </DialogTitle>
        {children}
        <DialogActions>
          <Button
            autoFocus
            size="small"
            variant="outlined"
            color="error"
            onClick={() => setShowModal(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ModalsWrapper;
