import { Box } from '@mui/material';
import '../../Styles/Common.css';
import { ScaleLoader } from 'react-spinners';

const Spinner: () => JSX.Element = () => {
  return (
    <Box className='spinner-wrapper' sx={{ textAlign: 'center' }}>
      <ScaleLoader color='#5E60FF' />
    </Box>
  );
};

export default Spinner;
