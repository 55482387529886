import { useState, useEffect } from "react";
import { Box } from "@mui/system";
import ConvoBox from "./ConvoBox";
import axios from "axios";
import { url } from "../../Helpers/utils/Constant";
import { IConversation } from "../../Types/ConversationTypes/ConversationTypes";
import { useAuthContext } from "../../Context/AuthContext/AuthContext";
import Tooltip from "@mui/material/Tooltip";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
const SupportConvo = () => {
  const { user } = useAuthContext();
  const [supportShow, setSupportShow] = useState<boolean>(false);
  const [conversation, setConversation] = useState<IConversation>(
    {} as IConversation
  );

  useEffect(() => {
    if (supportShow && !conversation?.support_conversation_id) {
      (async () => {
        const convo = await axios.post(
          `${url}/api/atab/support-conversation/create-or-update-conversation`,
          {
            support_conversation_member_id: user.user_member_id,
          }
        );
        if (convo.data.success) {
          setConversation(convo.data.data);
        }
      })();
    }
  }, [supportShow, conversation?.support_conversation_id, user.user_member_id]);

  return (
    <Box
      sx={{
        position: "fixed",
        right: "10px",
        bottom: "10px",
      }}
    >
      {supportShow ? (
        <ConvoBox
          userId={user.user_member_id}
          conversation={conversation}
          setSupportShow={setSupportShow}
        />
      ) : (
        <Tooltip title="Message us">
          <WhatsAppIcon
            onClick={() => setSupportShow(true)}
            sx={{
              fontSize: 50,
              cursor: "pointer",
              color: "#0c4da2",
            }}
          />
        </Tooltip>
      )}
    </Box>
  );
};

export default SupportConvo;
