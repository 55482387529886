import React from "react";
import { useAuthContext } from "../../Context/AuthContext/AuthContext";
import {
  Card,
  CardHeader,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Divider,
} from "@mui/material";

import moment from "moment";
import TableNoDataMessage from "../../Components/TableMessage/TableNoDataMessage";

const Notifications = () => {
  const { notification } = useAuthContext();
  return (
    <Box mt={5}>
      <Card>
        <CardHeader
          title={
            <Typography sx={{ fontWeight: 700, lineHeight: 1.34 }}>
              Notification list
            </Typography>
          }
        />
        <Divider />
        <TableContainer>
          <Table
            sx={{ minWidth: { sm: 0, md: 650 } }}
            aria-label="simple table"
          >
            <TableHead></TableHead>
            <TableBody>
              {notification.notification.length ? (
                <>
                  {notification?.notification.map((sNoti) => {
                    return (
                      <TableRow key={sNoti.notification_id}>
                        <TableCell>{sNoti.message}</TableCell>
                        <TableCell>
                          {moment(sNoti.created_at).format("L")}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </>
              ) : (
                <TableNoDataMessage />
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </Box>
  );
};

export default Notifications;
