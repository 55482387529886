import React from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { Typography } from "@mui/material";

const TableNoDataMessage = () => {
  return (
    <>
      <TableRow>
        <TableCell colSpan={100}>
          <Typography
            gutterBottom
            sx={{ fontWeight: "bold", textAlign: "center", fontSize: 19 }}
          >
            No data found
          </Typography>
        </TableCell>
      </TableRow>
    </>
  );
};

export default TableNoDataMessage;
