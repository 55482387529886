import React from 'react';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <Box textAlign={'center'} sx={{ mt: { xs: '70%', md: '10%' } }}>
      <img src='/404.png' alt='' />

      <Box>
        <Link
          to='/'
          style={{
            color: 'blue',
          }}
        >
          Go back
        </Link>
      </Box>
    </Box>
  );
};

export default NotFound;
