import React, { useState } from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from "@mui/material";
import TableLoader from "../../Spinner/TableLoader";
import TableNoDataMessage from "../../TableMessage/TableNoDataMessage";
import ModalsWrapper from "../../Modals/ModalsWrapper";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import { useAuthContext } from "../../../Context/AuthContext/AuthContext";
import { makeStyles } from "@mui/styles";
import { showToast } from "../../../Toaster/Toaster";
import { useDashboardEventQuery } from "../api/dashboardEndPoint";
import { useMutation, useQueryClient } from "react-query";
import { API_ENDPOINTS } from "../../../rest/utils/api-endpoints";
import { PostJoinedEvent } from "../api/dashboardApiFunction";
import moment from "moment";

const useStyles = makeStyles({
  table: {
    borderCollapse: "collapse",
    "& td, & th": {
      padding: "3px",
      fontFamily: "Poppins, sans-serif",
    },
  },
  container: {
    maxHeight: 400,
    "&::-webkit-scrollbar": {
      width: 4,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0, 0, 0, 0.2)",
    },
  },
});

const MemberEvents = () => {
  const { user } = useAuthContext();
  const { allEvent, isLoading } = useDashboardEventQuery();
  const [eventId, setEventId] = useState<number>(0);
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (data: any) => {
      return PostJoinedEvent(data);
    },
    onSuccess: () => {
      showToast(`Successfully added trainee`, "success");
      setOpen(false);
    },
    onError: (error: any) => {
      showToast(`${error.response.data.message}`, "error");
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [API_ENDPOINTS.JOINED_EVENT],
      });
    },
  });

  const handelJoin = () => {
    mutation.mutate({
      event_id: eventId,
      member_id: user.user_member_id,
    });
  };

  return (
    <>
      <TableContainer sx={{ height: "250px" }} className={classes.container}>
        <Table aria-label="simple table" className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Tittle</TableCell>
              <TableCell>Start Date</TableCell>

              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableLoader />
            ) : (
              <>
                {allEvent?.length ? (
                  <>
                    {allEvent.map((sEvent) => (
                      <TableRow key={sEvent.member_event_id}>
                        <TableCell
                          sx={{
                            width: { xs: "150px", md: "250px", xl: "450px" },
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {sEvent.member_event_name}
                        </TableCell>
                        <TableCell>
                          {moment(sEvent.member_event_start_date).format("LL")}
                        </TableCell>

                        <TableCell>
                          {user.user_member_account_status === "active" ? (
                            <Button
                              size="small"
                              variant="contained"
                              onClick={() => {
                                setOpen(true);
                                setEventId(sEvent.member_event_id);
                              }}
                            >
                              Join
                            </Button>
                          ) : (
                            <Button size="small" variant="contained" disabled>
                              Join
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                ) : (
                  <TableNoDataMessage />
                )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <ModalsWrapper
        modalData={{ title: "Join Event" }}
        setShowModal={setOpen}
        showModal={open}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to join this Event?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {mutation.isLoading ? (
            <Button
              color="primary"
              autoFocus
              size="small"
              disableElevation
              variant="outlined"
            >
              Loading..
            </Button>
          ) : (
            <Button
              color="primary"
              autoFocus
              onClick={handelJoin}
              size="small"
              variant="outlined"
            >
              Yes
            </Button>
          )}

          <Button
            color="error"
            size="small"
            onClick={() => setOpen(false)}
            variant="outlined"
          >
            No
          </Button>
        </DialogActions>
      </ModalsWrapper>
    </>
  );
};

export default MemberEvents;
