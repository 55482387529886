import { useState, useCallback } from "react";
import { IRenewalApplication, IRenewalSingle, IResponse } from "./types";
import {
  fetchRenewalApplication,
  fetchRenewalSingleApplication,
  postRenewalApplication,
} from ".";
import { showToast } from "../Toaster/Toaster";

export type IRenewalParams = {
  renewal_application_member_id: number;
  renewal_application_status?: string;
  renewal_application_from_date?: string;
  renewal_application_to_date?: string;
};

export const useRenewalQuery = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>({});
  const [data, setData] = useState<IRenewalApplication[]>(
    [] as IRenewalApplication[]
  );

  const fetchData = useCallback(async (params: IRenewalParams) => {
    try {
      setLoading(true);
      const response = await fetchRenewalApplication(params);
      if (response.success) {
        setData(response.data!);
      }
    } catch (error: any) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    loading,
    data,
    error,
    fetchData,
  };
};

export const useRenewalSingleQuery = (id: string) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>({});
  const [data, setData] = useState<IRenewalSingle>({} as IRenewalSingle);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await fetchRenewalSingleApplication(id);
      if (response.success) {
        setData(response.data!);
      }
    } catch (error: any) {
      setError(error);
      showToast(`${error?.response?.data?.message}`, "error");
    } finally {
      setLoading(false);
    }
  }, [id]);

  return {
    loading,
    data,
    error,
    fetchData,
  };
};

export const useRenewalMutation = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>({});
  const [data, setData] = useState<IResponse>({} as IResponse);

  const createData = useCallback(async (formData: FormData) => {
    try {
      setLoading(true);
      const response = await postRenewalApplication(formData);
      if (response.success) {
        setData(response);
        showToast(`Created renewal application successfully`, "success");
      }
    } catch (error: any) {
      setError(error);

      showToast(`${error?.response?.data?.message}`, "error");
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    loading,
    data,
    error,
    createData,
  };
};
