import { useQuery } from "react-query";
import { API_ENDPOINTS } from "../../../rest/utils/api-endpoints";
import { HttpClient } from "../../../rest/utils/http";
import {
  IJoinedEvent,
  IJoinedEventResponse,
  IJoinedMeeting,
  IJoinedMeetingResponse,
  ISingleEvent,
  ISingleEventResponse,
} from "../../../Types/DashvoardHomeType";

const fetchJoinedMeeting = async (id: number) => {
  const fetchData = await HttpClient.get<IJoinedMeetingResponse>(
    `${API_ENDPOINTS.JOINED_MEETING_GET}/${id}`
  );
  return fetchData;
};
const fetchJoinedEvent = async (id: number) => {
  const fetchData = await HttpClient.get<IJoinedEventResponse>(
    `${API_ENDPOINTS.JOINED_EVENT_GET}/${id}`
  );
  return fetchData;
};
const fetchSingleEvent = async (id: number) => {
  const fetchData = await HttpClient.get<ISingleEventResponse>(
    `${API_ENDPOINTS.SINGLE_EVENT}/${id}`
  );
  return fetchData;
};

// QUERY
export function useJoinedMeetingQuery(id: number) {
  const { data, isLoading, error } = useQuery<IJoinedMeetingResponse, Error>(
    [API_ENDPOINTS.JOINED_MEETING_GET, { id }],
    () => fetchJoinedMeeting(id),
    {
      refetchOnWindowFocus: false,
    }
  );
  return {
    allJoinedMeeting: data?.data ?? ([] as IJoinedMeeting[]),
    isLoading,
    error,
  };
}
export function useJoinedEventQuery(id: number) {
  const { data, isLoading, error } = useQuery<IJoinedEventResponse, Error>(
    [API_ENDPOINTS.JOINED_EVENT_GET, { id }],
    () => fetchJoinedEvent(id),
    {
      refetchOnWindowFocus: false,
    }
  );
  return {
    allJoinedEvent: data?.data ?? ([] as IJoinedEvent[]),
    isLoading,
    error,
  };
}

export function useGetSingleEvent(id: number) {
  const { data, isLoading, error } = useQuery<ISingleEventResponse, Error>(
    [API_ENDPOINTS.SINGLE_EVENT, { id }],
    () => fetchSingleEvent(id),
    {
      refetchOnWindowFocus: false,
    }
  );
  return {
    singleEvent: data?.data ?? ({} as ISingleEvent),
    isLoading,
    error,
  };
}
