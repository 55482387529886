import { useState } from "react";
import {
  Box,
  Typography,
  Grid,
  FormControlLabel,
  Button,
  TextField,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { useForm } from "react-hook-form";
import { IBusinessLicense } from "../../../Types/UpdateInfotypes/UpdateCompanyInfoStateTypes";
import { useAuthContext } from "../../../Context/AuthContext/AuthContext";
import { useCompanyBusinessLicenseUpdate } from "../../../rest/useCompanyInfo";

const AddBusinessLicenseInfo = () => {
  const { user } = useAuthContext();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { isLoading, mutate } = useCompanyBusinessLicenseUpdate();
  const [businessLicense, setBusinessLicense] = useState<IBusinessLicense>({
    hajj_license: 0,
    umrah_license: 0,
    recruting_license: 0,
    outbound: 0,
    inbound: 0,
  });
  const onSubmit = async (inputsData: any) => {
    inputsData = { ...inputsData, ...businessLicense };
    const formData = new FormData();
    Object.keys(inputsData).forEach((key) => {
      if (typeof inputsData[key] === "object") {
        formData.append(key, inputsData[key][0]);
      } else {
        formData.append(key, inputsData[key]);
      }
    });
    mutate({ input: formData });
  };
  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} p={2}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>
            Other business license ( please tick mark in box )
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
              textAlign: "center",
            }}
          >
            <Box>
              <FormControlLabel
                control={<Checkbox />}
                label="Hajj"
                onClick={(e: any) => {
                  if (e.target.checked) {
                    setBusinessLicense({
                      ...businessLicense,
                      hajj_license: 1,
                    });
                  } else {
                    setBusinessLicense({
                      ...businessLicense,
                      hajj_license: 0,
                    });
                  }
                }}
              />
            </Box>
            <Box>
              <FormControlLabel
                control={<Checkbox />}
                label="Umrah"
                onClick={(e: any) => {
                  if (e.target.checked) {
                    setBusinessLicense({
                      ...businessLicense,
                      umrah_license: 1,
                    });
                  } else {
                    setBusinessLicense({
                      ...businessLicense,
                      umrah_license: 0,
                    });
                  }
                }}
              />
            </Box>
            <Box>
              <FormControlLabel
                control={<Checkbox />}
                label="Recruiting"
                onClick={(e: any) => {
                  if (e.target.checked) {
                    setBusinessLicense({
                      ...businessLicense,
                      recruting_license: 1,
                    });
                  } else {
                    setBusinessLicense({
                      ...businessLicense,
                      recruting_license: 0,
                    });
                  }
                }}
              />
            </Box>
          </Box>

          <Box>
            {businessLicense.hajj_license ? (
              <Box>
                <label htmlFor="">
                  Member organization Hajj license ( max size 5mb )
                  <span style={{ color: "red" }}>*</span>
                </label>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  type="file"
                  required
                  fullWidth
                  sx={{ pb: 2 }}
                  {...register("hajj_license_file", {
                    required: "Hajj license file is required",
                  })}
                  error={
                    errors.hajj_license_file &&
                    Boolean(errors.hajj_license_file)
                  }
                  helperText={
                    Boolean(errors?.hajj_license_file?.message)
                      ? `${errors?.hajj_license_file?.message}`
                      : ""
                  }
                />
              </Box>
            ) : (
              ""
            )}
            {businessLicense.umrah_license ? (
              <Box>
                <label htmlFor="">
                  Member organization Umrah license ( max size 5mb )
                  <span style={{ color: "red" }}>*</span>
                </label>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  type="file"
                  required
                  fullWidth
                  sx={{ pb: 2 }}
                  {...register("umrah_license_file", {
                    required: "Umrah license file is required",
                  })}
                  error={
                    errors.umrah_license_file &&
                    Boolean(errors.umrah_license_file)
                  }
                  helperText={
                    Boolean(errors?.umrah_license_file?.message)
                      ? `${errors?.umrah_license_file?.message}`
                      : ""
                  }
                />
              </Box>
            ) : (
              ""
            )}
            {businessLicense.recruting_license ? (
              <Box>
                <label htmlFor="">
                  Member organization Recruiting license ( max size 5mb )
                  <span style={{ color: "red" }}>*</span>
                </label>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  type="file"
                  required
                  fullWidth
                  sx={{ pb: 2 }}
                  {...register("recruting_license_file", {
                    required: "Recruiting license is required",
                  })}
                  error={
                    errors.recruting_license_file &&
                    Boolean(errors.recruting_license_file)
                  }
                  helperText={
                    Boolean(errors?.recruting_license_file?.message)
                      ? `${errors?.recruting_license_file?.message}`
                      : ""
                  }
                />
              </Box>
            ) : (
              ""
            )}
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>
            Involved in tourism activities ( please tick mark in box )
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: 15,
              pl: { xs: 7, md: 0 },
              // justifyContent: 'space-evenly',
              textAlign: "center",
              alignItems: "center",
            }}
          >
            <Box>
              <FormControlLabel
                control={<Checkbox />}
                label=" Inbound"
                onClick={(e: any) => {
                  if (e.target.checked) {
                    setBusinessLicense({
                      ...businessLicense,
                      inbound: 1,
                    });
                  } else {
                    setBusinessLicense({
                      ...businessLicense,
                      inbound: 0,
                    });
                  }
                }}
              />
            </Box>
            <Box>
              <FormControlLabel
                control={<Checkbox />}
                label="Outbound"
                onClick={(e: any) => {
                  if (e.target.checked) {
                    setBusinessLicense({
                      ...businessLicense,
                      outbound: 1,
                    });
                  } else {
                    setBusinessLicense({
                      ...businessLicense,
                      outbound: 0,
                    });
                  }
                }}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
      {!user.is_applied ? (
        <Box sx={{ mt: 2, textAlign: "end", mb: 1 }}>
          {isLoading ? (
            <Button disabled variant="contained" disableElevation>
              Loading...
            </Button>
          ) : (
            <Button type="submit" variant="contained" disableElevation>
              Save
            </Button>
          )}
        </Box>
      ) : (
        ""
      )}
    </Box>
  );
};

export default AddBusinessLicenseInfo;
