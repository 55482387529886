import React, { useState } from "react";
import { Grid, Button, TextField, Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { showToast } from "../../../Toaster/Toaster";
import { ICompanyCivilAviation } from "../../../rest/types";
import { useMutation, useQueryClient } from "react-query";
import { API_ENDPOINTS } from "../../../rest/utils/api-endpoints";
import { UpdateCompanyCivilAviationMutation } from "../../new-member-applications/IndexFile/UpdateMutation/CompanyUpdateMutation";

interface IDate {
  certificate_expiry_date: string;
  certificate_issue_date: string;
}

interface IProps {
  companyCivilAviation: ICompanyCivilAviation;
}
const UpdateCivilAviationInfo = ({ companyCivilAviation }: IProps) => {
  const { register, handleSubmit } = useForm();
  const queryClient = useQueryClient();
  const [date, setDate] = useState<IDate>({
    certificate_expiry_date:
      companyCivilAviation?.certificate_expiry_date?.split("T")[0],
    certificate_issue_date:
      companyCivilAviation?.certificate_issue_date?.split("T")[0],
  });
  const [certificateFile, setCertificateFile] = useState("");
  const mutation = useMutation({
    mutationFn: (data: any) => {
      return UpdateCompanyCivilAviationMutation(data);
    },
    onSuccess: (data: any) => {
      showToast("successfully updated", "success");
      setCertificateFile("");
    },
    onError: (error: any) => {
      showToast(`${error.response.data.message}`, "error");
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [API_ENDPOINTS.COMPANY_CIVIL_AVIATION],
      });
    },
  });

  const onSubmit = async (inputsData: any) => {
    const formData = new FormData();
    if (date.certificate_issue_date) {
      formData.append("certificate_issue_date", date.certificate_issue_date);
    }
    if (date.certificate_expiry_date) {
      formData.append("certificate_expiry_date", date.certificate_expiry_date);
    }

    if (certificateFile) {
      formData.append("certificate_file", certificateFile);
    }

    // else {
    //   formData.append(
    //     "certificate_file",
    //     companyCivilAviation?.certificate_file
    //   );
    // }

    Object.keys(inputsData).forEach((key) => {
      formData.append(key, inputsData[key]);
    });

    // console.table(Object.fromEntries(formData));
    mutation.mutate(formData);
  };
  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} p={2}>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Box sx={{ mt: 2 }}>
              <TextField
                id="outlined-basic"
                variant="outlined"
                label="Civil aviation tourism certificate number"
                placeholder="Civil aviation tourism certificate number"
                fullWidth
                defaultValue={companyCivilAviation.certificate_number}
                {...register("certificate_number")}
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <TextField
                id="outlined-basic"
                label="Civil aviation tourism certificate ( max size 5mb )"
                placeholder="Civil aviation tourism certificate"
                variant="outlined"
                type="file"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e: any) => setCertificateFile(e.target.files[0])}
              />
            </Box>

            {/* <a
              href={`${imgUrl}/${companyCivilAviation.certificate_file}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Download certificate file
            </a> */}
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={{ mt: 2 }}>
              <TextField
                id="date"
                type="date"
                label="Civil aviation tourism issue date"
                defaultValue={date.certificate_issue_date}
                placeholder="Civil aviation tourism issue date"
                onChange={(e) =>
                  setDate({
                    ...date,
                    certificate_issue_date: e.target.value,
                  })
                }
                sx={{ width: "100%" }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            {" "}
            <Box sx={{ mt: 2 }}>
              <TextField
                id="date"
                placeholder="Civil aviation tourism certificate expiry date"
                label="Civil aviation tourism certificate expiry date"
                type="date"
                defaultValue={date.certificate_expiry_date}
                onChange={(e) =>
                  setDate({
                    ...date,
                    certificate_expiry_date: e.target.value,
                  })
                }
                sx={{ width: "100%" }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ pt: 5, textAlign: "end", mb: 2 }}>
        {mutation.isLoading ? (
          <Button
            disabled
            variant="contained"
            disableElevation
            className="add-remove-button"
          >
            Loading..
          </Button>
        ) : (
          <Button
            type="submit"
            variant="contained"
            disableElevation
            className="add-remove-button"
          >
            Save
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default UpdateCivilAviationInfo;
