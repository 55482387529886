import { useQuery } from "react-query";
import { HttpClient } from "../../../../rest/utils/http";
import { API_ENDPOINTS } from "../../../../rest/utils/api-endpoints";
import {
  ICertificate,
  ICertificateResponse,
} from "../../../../Types/UpdateInfotypes/CompanyInformation";

const fetchAllCertificate = async (id: number) => {
  const fetchData = await HttpClient.get<ICertificateResponse>(
    `${API_ENDPOINTS.CERTIFICATE}/${id}`
  );
  return fetchData;
};

// query
export function useAllCertificateQuery(id: number, refresh: boolean) {
  const { data, isLoading, error } = useQuery<ICertificateResponse, Error>(
    [API_ENDPOINTS.CERTIFICATE, { id }, refresh],
    () => fetchAllCertificate(id),
    {
      refetchOnWindowFocus: false,
    }
  );
  return {
    certificate: data?.data ?? ([] as ICertificate[]),
    isLoading,
    error,
  };
}
