// import * as React from 'react';

// import MuiAlert, { AlertProps } from '@mui/material/Alert';

// const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
//   props,
//   ref
// ) {
//   return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
// });

// export const SnackbarDialogue = ({
//   open,
//   handleClose,
// }: {
//   open: boolean;
//   handleClose: () => void;
// }) => {
//   <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
//     <Alert onClose={handleClose} severity='success' sx={{ width: '100%' }}>
//       This is a success message!
//     </Alert>
//   </Snackbar>;
// };

export const customizedSnackbars = ({
  snackbarOpen,
}: {
  snackbarOpen: boolean;
}) => {
  //   const [open, setOpen] = React.useState(snackbarOpen);

  //   const handleClick = () => {
  //     setOpen(true);
  //   };

  //   const handleClose = (
  //     event?: React.SyntheticEvent | Event,
  //     reason?: string
  //   ) => {
  //     if (reason === 'clickaway') {
  //       return;
  //     }

  //     setOpen(false);
  //   };

  return (
    // <Stack spacing={2} sx={{ width: '100%' }}>
    //   <Snackbar
    //     anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    //     open={open}
    //     autoHideDuration={6000}
    //     onClose={handleClose}>
    //     <Alert onClose={handleClose} severity='success' sx={{ width: '100%' }}>
    //       This is a success message!
    //     </Alert>
    //   </Snackbar>
    //   <Alert severity='error'>This is an error message!</Alert>
    // </Stack>
    <>
      <div
        style={{
          backgroundColor: 'red',
          zIndex: '999999',
          right: 0,
          bottom: 0,
        }}
      >
        <h1 style={{ color: 'red' }}>Hello</h1>
      </div>
    </>
  );
};
