import { useParams } from "react-router-dom";
import moment from "moment";
import {
  Grid,
  Card,
  CardContent,
  Box,
  Typography,
  Divider,
} from "@mui/material";
import PageLoader from "../../Components/Spinner/PageLoader";
import { getStatusLabel } from "../../Utils/Comibined";
import { useSingleIDCardQuery } from "./api/idCardEndPoint";
import { useState } from "react";
import ModalsWrapper from "../../Components/Modals/ModalsWrapper";
import PdfAndImageView from "../../Components/pdfAndImageView/PdfAndImageView";
import { CommonTypoGraphy } from "../../Components/pdfAndImageView/CommonTypoGraphy";

const SingleIdCard = () => {
  const { cardID } = useParams();
  const { singleIdCard, isLoading } = useSingleIDCardQuery(Number(cardID));
  const [open, setOpen] = useState<boolean>(false);
  const [imageFile, setImageFile] = useState<string | null>("");

  return (
    <>
      {" "}
      <Card sx={{ mt: 5 }}>
        <CardContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Box>
              <Typography
                variant="body1"
                fontWeight="bold"
                color="text.primary"
                display="inline"
                gutterBottom
                fontSize={17}
              >
                ID Card
              </Typography>
              <Typography
                variant="body1"
                fontWeight="bold"
                color="text.primary"
                display="inline"
                fontSize={17}
                sx={{ pl: 1 }}
                gutterBottom
              >
                # {singleIdCard?.id}
              </Typography>
            </Box>
          </Box>
          <Divider />

          {isLoading ? (
            <PageLoader />
          ) : (
            <Grid
              container
              pt={2}
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  display="inline"
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Applicant Name:
                </Typography>
                {singleIdCard.applicant_name}
              </Grid>

              {singleIdCard.designation && (
                <Grid item xs={2} sm={4} md={4}>
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    color="text.primary"
                    display="inline"
                    sx={{ pr: 1 }}
                    gutterBottom
                  >
                    Designation:
                  </Typography>
                  {singleIdCard.designation}
                </Grid>
              )}

              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  display="inline"
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Status:
                </Typography>

                {getStatusLabel(singleIdCard.status)}
              </Grid>
              {/* <Grid item xs={2} sm={4} md={4}>
              <a
                href={`${imgUrl}/${singleIdCard.photo}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Download image
              </a>
            </Grid> */}
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  display="inline"
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Date of birth:
                </Typography>
                {moment(singleIdCard.date_of_birth).format("MMM Do YY")}
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  display="inline"
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  telephone:
                </Typography>
                {singleIdCard.telephone}
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  display="inline"
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Cellphone:
                </Typography>
                {singleIdCard.cellphone}
              </Grid>

              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  display="inline"
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Email:
                </Typography>
                {singleIdCard.email}
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color="text.primary"
                  display="inline"
                  sx={{ pr: 1 }}
                  gutterBottom
                >
                  Blood Group:
                </Typography>
                {singleIdCard.blood_group}
              </Grid>

              {/* <Grid item xs={2} sm={4} md={4}>
              <Typography
                variant="body1"
                fontWeight="bold"
                color="text.primary"
                display="inline"
                sx={{ pr: 1 }}
                gutterBottom
              >
                NID Number:
              </Typography>
              {singleIdCard.member_nid_number}
            </Grid> */}

              {/* <Grid item xs={2} sm={4} md={4}>
              <a
                href={`${imgUrl}/${singleIdCard.trade_license_file}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Download trade license file
              </a>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <a
                href={`${imgUrl}/${singleIdCard.civil_aviation_renewed_certificate_file}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Download civil aviation renewal certificate
              </a>
            </Grid> */}

              <Grid item xs={2} sm={4} md={4}>
                {" "}
                <CommonTypoGraphy
                  name="View NID file"
                  onClick={() => {
                    setOpen(true);
                    setImageFile(singleIdCard?.member_nid_file);
                  }}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <CommonTypoGraphy
                  name="View signature file"
                  onClick={() => {
                    setOpen(true);
                    setImageFile(singleIdCard?.signature_file);
                  }}
                />
              </Grid>
              {/* <Grid item xs={2} sm={4} md={4}>
              <a
                href={`${imgUrl}/${singleIdCard.specimen_signature_file}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Download specimen signature
              </a>
            </Grid> */}

              {/* <Grid item xs={2} sm={4} md={4}>
                      <Typography
                        variant='body1'
                        fontWeight='bold'
                        color='text.primary'
                        display='inline'
                        sx={{ pr: 1, textAlign: 'justify' }}
                        gutterBottom
                      >
                        Eamail:
                      </Typography>
                      <a
                        href={`${imgUrl}/${singleIdCard?.member_event_cover_photo}`}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        {singleIdCard?.member_event_cover_photo}
                      </a>
                    </Grid> */}
            </Grid>
          )}
        </CardContent>
      </Card>{" "}
      <>
        <ModalsWrapper
          modalData={{ title: "View document" }}
          setShowModal={setOpen}
          showModal={open}
        >
          <PdfAndImageView file={imageFile} />
        </ModalsWrapper>
      </>
    </>
  );
};

export default SingleIdCard;
