import { imgUrl } from "../../../Helpers/utils/Constant";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableLoader from "../../Spinner/TableLoader";
import TableNoDataMessage from "../../TableMessage/TableNoDataMessage";
import { makeStyles } from "@mui/styles";
import { useDashboardNoticeQuery } from "../api/dashboardEndPoint";
const useStyles = makeStyles({
  table: {
    borderCollapse: "collapse",
    "& td, & th": {
      padding: "8px",
      fontFamily: "Poppins, sans-serif",
    },
  },
});
const MemberNotice = () => {
  const { allNotice, isLoading } = useDashboardNoticeQuery();
  const classes = useStyles();

  return (
    <>
      <TableContainer
        sx={{
          height: "250px",
          "& .MuiTableContainer-root": {
            boxSizing: "border-box",
            "&::-webkit-scrollbar": {
              width: 4,
            },
            "&::-webkit-scrollbar-track": {
              boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.3)`,
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#F36167",
            },
          },
        }}
      >
        <Table aria-label="simple table" className={classes.table}>
          <TableHead>
            <TableRow>
              {/* <TableCell sx={{ fontSize: 20 }}>Title</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableLoader />
            ) : (
              <>
                {allNotice?.length ? (
                  <>
                    {allNotice.map((sNotice) => (
                      <TableRow key={sNotice.member_notice_id}>
                        <TableCell>
                          <a
                            href={`${imgUrl}/member_notice_files/${sNotice.member_notice_file}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {sNotice.member_notice_title}
                          </a>
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                ) : (
                  <TableNoDataMessage />
                )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default MemberNotice;
